import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import Input from "../../shared/components/FormElements/Input";
import Button from '../../shared/components/FormElements/Button';

import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { VALIDATOR_EMAIL } from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";

import { Row, Col, message } from "antd";
import "./AuthInvite.css";

const ForgotPassword = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const baseUrl = process.env.REACT_APP_API_URL;
  const [ successMessage, setSuccessMessage ] = useState('');
  

  const [ formState, inputHandler ] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const authSubmitHandler = async (event) => {
    event.preventDefault();

    let url = `${baseUrl}/users/forgot_password`;
    let formData = {
      email: formState.inputs.email.value,
    };
    try {
      const responseData = await sendRequest(
        url,
        "POST",
        JSON.stringify(formData),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.message) {
        setSuccessMessage(responseData.message);
      }
    } catch (err) {}
  };

  const success = () => {
    message.success(successMessage);
  };

  useEffect(() => {
      if (successMessage) {
        success();  
      }
  });

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <div className="auth-wrapper">
        <Row>
          <Col span={12} className="left-side">
            <div className="header">
              <div className="logo-wrapper">
                <Link to="/">
                  <img src="/assets/imgs/logo.png" width="100%" />
                  <span>Data Literacy Game</span>
                </Link>
              </div>
            </div>
            <h1 className="invite-headline">
                Forgot Your Password?
            </h1>
            <div className="invite-form-wrapper">
              {isLoading && <LoadingSpinner asOverlay />}
              <hr />
              <form onSubmit={authSubmitHandler}>
                <Input
                  element="input"
                  id="email"
                  type="email"
                  label="E-Mail"
                  validators={[VALIDATOR_EMAIL()]}
                  errorText="Please enter a valid email address."
                  onInput={inputHandler}
                />

                <Button type="submit" disabled={!formState.isValid}>
                  Continue
                </Button>
              </form>
            </div>
          </Col>
          <Col span={12}>
            <img src="/assets/imgs/auth.jpg" width="100%" />
          </Col>
        </Row>
      </div>
      <div className="ecampus-logo">
        <img src="/assets/imgs/logo-ecampus.png" />
      </div>
    </React.Fragment>
  );
};

export default ForgotPassword;
