import React, { useState } from "react";
import { Link } from "react-router-dom";

import Card from "react-bootstrap/Card";
import { Button } from "antd";
// import Card from '../../shared/components/UIElements/Card';
import "./ClassItemInstructor.css";
import { staticContent } from '../../shared/data/dictionary'

const language = localStorage.getItem("locale");
const ClassItemInstructor = (props) => {

  const [selected, setSelected] = useState(false)

  const onSelectHandler = event => {
    event.preventDefault();
    props.onSelect(props.id)
    // document.getElementsByClassName('class-card-instructor')?.classList.remove("selected")
  }


  return (
    <div className="class-item">
      <Card className={`horizonal-card class-card-instructor ${
        props.selected && "selected"
      }`}>
        {/* <Card.Img variant="top" src={`http://localhost:5000/${props.image}`} /> */}
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
          <div className="stats">
            {/* each stats */}
            <div className="each-stats">
              <span className="number">{props.playersCount}</span>
              <span className="label">{staticContent[language]?.players}</span>
            </div>
          </div>
          <div className="btns">
            {props.invitebtn && (
                <Button type="default"><Link to={`/classes/${props.id}/invite`}>{staticContent[language]?.invite}</Link></Button>
            )}
            {props.settingbtn && (
                <Button type="default"><Link to={`/classes/${props.id}/edit`}>{staticContent[language]?.config}</Link></Button>
            )}
            {props.selectbtn && (
              <Button type="default" onClick={onSelectHandler}>{staticContent[language]?.select}</Button>
            )}
            {props.playersbtn && (
              <Button type="primary"><Link to={`/classes/${props.id}`}>{staticContent[language]?.studentsList}</Link></Button>
            )}
            
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ClassItemInstructor;
