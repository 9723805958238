import React, { useState } from "react";
import { Link } from "react-router-dom";

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Avatar from "../../shared/components/UIElements/Avatar";
import "./SlideItem.css";
import { staticContent } from '../../shared/data/dictionary'

const language = localStorage.getItem("locale");
const SlideItem = (props) => {

  const [selected, setSelected] = useState(false)

  const handleSelect = (e) => {
    props.onSelect(props.id)
    setSelected((prevState) => {
      return !prevState
    })
  }
  const baseUrl = process.env.REACT_APP_API_URL;
  const uploadUrl = process.env.REACT_APP_UPLOADS_URL;

  return (
    <div className={`slide-item ${selected && "selected"}`}>
      <Card className="slide-card">
        <Card.Header>{staticContent[language][props.type]}</Card.Header>
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
          <Card.Text>{props.text}</Card.Text>
          {props.image && (
            <Card.Img
              variant="top"
              src={uploadUrl+props.image}
            />
          )}
          {props.score && (
            <div className="score-wrapper">
              <span className="score">+{props.score}</span>
            </div>
          )
          }
        </Card.Body>

        { props.footer &&
        <Card.Footer>
          {props.slideEditBtn && (
            <Link to={`/slides/${props.id}/edit`}>
              <Button variant="dark" className="btn-sm">
                Edit Slide
              </Button>
            </Link>
          )}
          {props.slideSelectBtn && (
            <Button variant="dark" className="btn-sm" onClick={handleSelect}>
              { selected ? "Unselect" : "Select" } 
            </Button>
          )}
        </Card.Footer>
        }
      </Card>
    </div>
  );
};

export default SlideItem;
