import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Button } from "antd";
import { Card } from "antd";
import "./ScenarioItemPlayer.css";
import { staticContent } from "../../shared/data/dictionary";
import { Space, Row, Col } from "antd";
import PlaysList from "../components/PlaysList";

const language = localStorage.getItem("locale");
const ScenarioItemPlayer = (props) => {
  const [selected, setSelected] = useState(props.selected);

  const scenarioSelectHandler = (event) => {
    event.preventDefault();
    props.onScenarioSelected(props.id);
    setSelected((prevState) => !prevState);
    return true;
  };
  const uploadUrl = process.env.REACT_APP_UPLOADS_URL;

  const dateOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const lastAttemptDate =
    props.lastAttemptDate &&
    new Date(props.lastAttemptDate).toLocaleDateString(undefined, dateOptions);

  return (
    <div className="player-scenario-wrapper">
      <Row gutter={14}>
        <Col span={17}>
          <h4 className="headline">{props.title}</h4>
          <p className="sub-headline">{props.classTitle}</p>
          <div className="scenario-item__wrapper scenario-item-player">
            <div className="scenario-item__media">
              <div className="img-wrapper">
                <img src={uploadUrl + props.image} />
              </div>
              <div className="body">
                <p className="description">{props.description}</p>
              </div>
            </div>
          </div>
          <div className="btns d-flex justify-content-end">
            {props.lastAttemptDate && (
              <>
                <Button type="default" className="unset-cursor">
                  {staticContent[language]?.last_attempt}: {lastAttemptDate}
                </Button>
                <Button type="default" className="unset-cursor">
                  {staticContent[language]?.latest_score}: {props.score}
                </Button>
                <Button type="default">
                  <Link to={`/scenarios/${props.id}/${props.classId}/feedback`}>
                    {staticContent[language]?.view_last_attempt_feedback}
                  </Link>
                </Button>
              </>
            )}
            <Button type="default" className="unset-cursor">
              {staticContent[language]?.attempts_remaining}:{" "}
              {props.remainingAttempts}
            </Button>
            <Button type="primary">
              <Link to={`/scenarios/${props.id}/${props.classId}/play`}>
                {staticContent[language]?.playScenario}
              </Link>
            </Button>
          </div>
        </Col>
        <Col span={7}>
          <div className="top-plays-wrapper">
            <p className="sub-headline">
              {staticContent[language].top_scoring_players}
            </p>
            <PlaysList
              items={props.topPlays}
              pagination={false}
              showPlayerName={true}
              showScore={true}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ScenarioItemPlayer;
