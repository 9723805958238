import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { DownCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

import "./FeedbackDetailed.css";

import FeedbackListPlayer from "../components/FeedbackListPlayer";
import { staticContent } from "../../shared/data/dictionary";
import { Translate } from "../../shared/util/translator";
import { Link } from "react-router-dom";
import { useAuth } from "../../shared/hooks/auth-hook";

const language = localStorage.getItem("locale");
const FeedbackDetailed = () => {
  const feedbackHttp = useHttpClient();
  const [loadedFeedbacks, setLoadedFeedbacks] = useState();
  const [score, setScore] = useState();
  const auth = useContext(AuthContext);
  const scenarioId = useParams().scenarioid;
  const classId = useParams().classid;
  const playerId = useParams().playerid;
  const playedScenarioId = useParams().playedscenarioid;
  const baseUrl = process.env.REACT_APP_API_URL;
  const { userRole } = useAuth();

  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        let url = `${baseUrl}/scenarios/${scenarioId}/${classId}/feedback`;
        if (playerId) {
          url = `${baseUrl}/scenarios/${scenarioId}/${classId}/${playerId}/feedback`;
        }

        if (playedScenarioId) {
          url = `${baseUrl}/scenarios/plays/${playedScenarioId}/feedback`;
        }
        const responseData = await feedbackHttp.sendRequest(url, "GET", null, {
          Authorization: "Bearer " + auth.token,
        });
        setLoadedFeedbacks(responseData);
      } catch (err) {
        console.log("fetchFeedbacks err", err);
      }
    };
    fetchFeedbacks();
  }, [feedbackHttp.sendRequest]);

  return (
    <React.Fragment>
      <div className="detail-feedback">
        <div className="container">
          <ErrorModal
            error={feedbackHttp.error}
            onClear={feedbackHttp.clearError}
          />
          {feedbackHttp.isLoading && (
            <div className="center">
              <LoadingSpinner />
            </div>
          )}

          <div
            className="detail-feedback__overview"
            style={{ position: "relative" }}
          >
            <div className="avatar">
              {/* <img src="http://localhost:5000/uploads/images/ali-snow-sq.jpg" /> */}
            </div>
            <div className="score">
              <span className="number">
                {staticContent[language].yourScoreIs}
                <br />
              </span>
              <span className="number">
                {!feedbackHttp.isLoading &&
                  loadedFeedbacks &&
                  loadedFeedbacks.score}
              </span>
              {userRole === "player" && (
                <Link
                  to={`/scenarios/${scenarioId}/${classId}/play`}
                  style={{
                    position: "absolute",
                    right: "20px",
                    bottom: "20px",
                    padding: "7px 15px",
                    background: "#12141d",
                    fontSize: "20px",
                    textDecoration: "none",
                    color: "white",
                    borderRadius: "5px",
                  }}
                >
                  Play Again
                </Link>
              )}
              {/* <p className="desc">{staticContent[language]?.youAreInTheTop} <Tooltip title="Not implemented yet"><span style={{color: 'red'}} >10%</span></Tooltip></p> */}
            </div>
            <div className="scroll-down-guide">
              <span className="text">
                {staticContent[language]?.reviewPoints}
              </span>
              <DownCircleOutlined />
            </div>
          </div>
          <div className="section-header">
            <h1>
              {staticContent[language]?.feedbackDetailedHeader}
              <p className="sub-headline">
                {staticContent[language]?.feedbackDetailedParagraph}
              </p>
            </h1>
            <div className="btns"></div>
          </div>
          {!feedbackHttp.isLoading && loadedFeedbacks && (
            <FeedbackListPlayer
              feedbackItems={loadedFeedbacks.deckSlides}
              feedbackType={"individual"}
            />
          )}

          <div className="section-header">
            <h1>
              {staticContent[language]?.comboFeedbackDetailedHeader}
              <p className="sub-headline">
                {staticContent[language]?.comboFeedbackDetailedParagraph}
              </p>
            </h1>
          </div>
          {!feedbackHttp.isLoading && loadedFeedbacks && (
            <FeedbackListPlayer
              feedbackItems={loadedFeedbacks.deckCombos}
              feedbackType={"combo"}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FeedbackDetailed;
