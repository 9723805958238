import React, { useState, useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import Input from "../../shared/components/FormElements/Input";
// import { Button } from "antd";
import Button from '../../shared/components/FormElements/Button';

import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { staticContent, language } from '../../shared/data/dictionary';
import Logo from '../../shared/components/UIElements/Logo';

import { Row, Col } from "antd";
import "./AuthInvite.css";

const Auth = () => {
  const auth = useContext(AuthContext);
  const [isLoginMode, setIsLoginMode] = useState(true);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();
  const baseUrl = process.env.REACT_APP_API_URL;
  const authType = useParams().authtype == 'instructor' ? 'instructor' : 'player';
  

  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const switchModeHandler = () => {
    if (!isLoginMode) {
      setFormData(
        {
          ...formState.inputs,
          name: undefined,
          // image: undefined
        },
        formState.inputs.email.isValid && formState.inputs.password.isValid
      );
    } else {
      setFormData(
        {
          ...formState.inputs,
          name: {
            value: "",
            isValid: false,
          },
          // image: {
          //   value: null,
          //   isValid: false
          // }
        },
        false
      );
    }
    setIsLoginMode((prevMode) => !prevMode);
  };

  const authSubmitHandler = async (event) => {
    event.preventDefault();

    let url = `${baseUrl}/users/login`;
    let formData = {
      email: formState.inputs.email.value,
      password: formState.inputs.password.value
    };
    if (!isLoginMode) {
      url = `${baseUrl}/users/signup`;
      formData = { ...formData, name: formState.inputs.name.value, authType: authType };
    }
    try {
      const responseData = await sendRequest(
        url,
        "POST",
        JSON.stringify(formData),
        {
          "Content-Type": "application/json",
        }
      );
      // console.log(responseData.userId, responseData.token, responseData.role);
      auth.login(responseData.userId, responseData.token, responseData.role, responseData.image);
      localStorage.setItem("locale", 'en');
      history.push('/scenarios');
    } catch (err) {}
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <div className="auth-wrapper">
        <Row>
          <Col span={12} className="left-side">
            <div className="header">
              <div className="logo-wrapper">
                <Link to="/">
                  <Logo />
                </Link>
              </div>
            </div>
            <h1 className="invite-headline">
            { !isLoginMode ? staticContent[language].signup : 'Login' }<br/>
            Data Literacy Game
            </h1>
            <div className="invite-form-wrapper">
              {isLoading && <LoadingSpinner asOverlay />}
              {/* <h2>Login Required</h2> */}
              <hr />
              <form onSubmit={authSubmitHandler}>
                {!isLoginMode && (
                  <Input
                    element="input"
                    id="name"
                    type="text"
                    label="Your Name"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a name."
                    onInput={inputHandler}
                  />
                )}
                <Input
                  element="input"
                  id="email"
                  type="email"
                  label="E-Mail"
                  validators={[VALIDATOR_EMAIL()]}
                  errorText="Please enter a valid email address."
                  onInput={inputHandler}
                />

                <Input
                  element="input"
                  id="password"
                  type="password"
                  label="Password"
                  validators={[VALIDATOR_MINLENGTH(6)]}
                  errorText="Please enter a valid password, at least 6 characters."
                  onInput={inputHandler}
                />
                <br/>
                <Button type="submit" disabled={!formState.isValid}>
                  {isLoginMode ? "Login" : "Signup"}
                </Button>
              </form>
              <Button inverse onClick={switchModeHandler}>
                Switch to {isLoginMode ? "signup" : "login"}
              </Button>
              <Link to="/forgot-password" className="black">Forgot your password?</Link>
              <br/>
            </div>
          </Col>
          <Col span={12}>
            <img src="/assets/imgs/auth.jpg" width="100%" />
          </Col>
        </Row>
      </div>
      <div className="ecampus-logo">
        <img src="/assets/imgs/logo-ecampus.png" />
      </div>
    </React.Fragment>
  );
};

export default Auth;
