import { createStore } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import expanderReducer from './expander'
import slidesReducer from './scenario'


const store = configureStore({
    reducer: { expander: expanderReducer, scenario: slidesReducer }
});

export default store;


