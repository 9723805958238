import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../../shared/components/FormElements/Button';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Input from '../../shared/components/FormElements/Input';
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH
} from '../../shared/util/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import '../../shared/components/FormElements/Form.css';

import ScenariosListInstructor from '../../scenario/components/ScenariosListInstructor';
import { staticContent } from '../../shared/data/dictionary'

const language = localStorage.getItem("locale");
const NewClass = () => {
  const auth = useContext(AuthContext);
  const [loadedScenarios, setLoadedScenarios] = useState();
  const [selectedScenarios, setSelectedScenarios] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler] = useForm(
    {
      title: {
        value: '',
        isValid: false
      },
      description: {
        value: '',
        isValid: true
      }
    },
    false
  );
  const baseUrl = process.env.REACT_APP_API_URL
  const history = useHistory();

  useEffect(() => {

    const fetchScenarios = async () => {
      try {
        const responseData = await sendRequest(`${baseUrl}/scenarios`, 'GET', null, {
          Authorization: 'Bearer ' + auth.token
        });
        setLoadedScenarios(responseData);
      } catch (err) {
        // console.log("fetchScenarios err", err)
      }
    };
    fetchScenarios();

  }, [sendRequest]);

  const handleScenarioSelect = (scenario_id) => {
    if(selectedScenarios.includes(scenario_id)){
      const index = selectedScenarios.indexOf(scenario_id);
      selectedScenarios.splice(index, 1);
    }else{
      selectedScenarios.push(scenario_id)
    }
    setSelectedScenarios(selectedScenarios);
  }
  const classSubmitHandler = async event => {
    event.preventDefault();
    try {

      const body = JSON.stringify({
        title: formState.inputs.title.value,
        description: formState.inputs.description.value,
        title_fr: formState.inputs.title_fr.value,
        description_fr: formState.inputs.description_fr.value,
        scenarios: selectedScenarios
      })

      await sendRequest(`${baseUrl}/classes`, 'POST', body, {
        'Authorization': 'Bearer ' + auth.token,
        'Content-Type': 'application/json',
      });
      toast(staticContent[language].updatupdat)
    } catch (err) {
      // console.log(err)
    }
  };
  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <form className="form" onSubmit={classSubmitHandler}>
        <h4 className="form-title">
            {staticContent[language]?.createNewClass}
        </h4>
        {isLoading && <LoadingSpinner asOverlay />}
        <Input
          id="title"
          element="input"
          type="text"
          label="Title"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="valid_title"
          onInput={inputHandler}
        />
        <Input
          id="title_fr"
          element="input"
          type="text"
          label="Title (FR)"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="required_field"
          onInput={inputHandler}
        />
        <Input
          id="description"
          element="textarea"
          label="Description"
          validators={[VALIDATOR_MINLENGTH(5)]}
          errorText="Please enter a valid description (at least 5 characters)."
          onInput={inputHandler}
        />
        <Input
          id="description_fr"
          element="textarea"
          label="Description (FR)"
          validators={[VALIDATOR_MINLENGTH(5)]}
          errorText="Please enter a valid description (at least 5 characters)."
          onInput={inputHandler}
        />
        <br/>
        <h5>{staticContent[language]?.selectScenarios}</h5>
        {!isLoading && loadedScenarios && <ScenariosListInstructor onScenarioSelected={handleScenarioSelect} items={loadedScenarios} stats={false} selectbtn={true}/>}
        <Button type="submit" disabled={!formState.isValid} fullWidth={true} marginClass='mt-30'>
          {staticContent[language]?.addClass}
        </Button>
      </form>
    </React.Fragment>
  );
};

export default NewClass;
