import { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";

import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { staticContent, language } from "../../shared/data/dictionary";

import "./AuthForm.css";

const AuthForm = (props) => {
  const auth = useContext(AuthContext);
  const [isLoginMode, setIsLoginMode] = useState( 'isLoginMode' in props ? props.isLoginMode : true);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();
  const baseUrl = process.env.REACT_APP_API_URL;
  const authType = props.authType == 'instructor' ? 'instructor' : 'player';
  const [formSuccessMessage, setFormSuccessMessage] = useState(null);

  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const switchModeHandler = () => {
    if (!isLoginMode) {
      setFormData(
        {
          ...formState.inputs,
          name: undefined,
        },
        formState.inputs.email.isValid && formState.inputs.password.isValid
      );
    } else {
      setFormData(
        {
          ...formState.inputs,
          name: {
            value: "",
            isValid: false,
          },
        },
        false
      );
    }
    setIsLoginMode((prevMode) => !prevMode);
  };

  const authSubmitHandler = async (event) => {
    event.preventDefault();

    let url = `${baseUrl}/users/login`;
    let formData = {
      email: formState.inputs.email.value,
      password: formState.inputs.password.value,
    };
    if (!isLoginMode) {
      url = `${baseUrl}/users/signup`;
      formData = {
        ...formData,
        name: formState.inputs.name.value,
        authType: authType,
      };
    }
    try {
      const responseData = await sendRequest(
        url,
        "POST",
        JSON.stringify(formData),
        {
          "Content-Type": "application/json",
        }
      );
      // console.log(responseData.userId, responseData.token, responseData.role);
      if(responseData.status == 'active'){
        auth.login(
          responseData.userId,
          responseData.token,
          responseData.role,
          responseData.image
        );
        localStorage.setItem("locale", "en");
        history.push("/scenarios");
      }else{
        setFormSuccessMessage(staticContent[language]?.instructor_account_needs_to_get_approved)
      }
      
    } catch (err) {
      
    }
  };

  let submitBtnText = isLoginMode ? "Login" : "Signup";

  if(props.submitBtnText){
      submitBtnText = props.submitBtnText;
  }

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />

      <div className="invite-form-wrapper">
        {isLoading && <LoadingSpinner asOverlay />}
        {/* <h2>Login Required</h2> */}
        <hr />
        { formSuccessMessage && 
          <div className="invite-form-success-message">{formSuccessMessage}</div>
        }
        { !formSuccessMessage &&
        <form onSubmit={authSubmitHandler} className={props.formStyle}>
          {!isLoginMode && (
            <Input
              element="input"
              id="name"
              type="text"
              label="Your Name"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Please enter a name."
              onInput={inputHandler}
            />
          )}
          <Input
            element="input"
            id="email"
            type="email"
            label="E-Mail"
            validators={[VALIDATOR_EMAIL()]}
            errorText="Please enter a valid email address."
            onInput={inputHandler}
          />

          <Input
            element="input"
            id="password"
            type="password"
            label="Password"
            validators={[VALIDATOR_MINLENGTH(6)]}
            errorText="Please enter a valid password, at least 6 characters."
            onInput={inputHandler}
          />
          <br />
          <Button type="submit" disabled={!formState.isValid} btnStyle={props.formStyle == 'dark-form' ? 'btn-aqua' : 'btn-dark'}>
            {submitBtnText}
          </Button>

        </form>
        }
        { props.switchBtn && 
        <Button inverse onClick={switchModeHandler} btnStyle={props.formStyle == 'dark-form' ? 'btn-aqua' : 'btn-dark'}>
          Switch to {isLoginMode ? "signup" : "login"}
        </Button>
        }
        {props.forgotPassword && 
        <Link to="/forgot-password" className="black">
          Forgot your password?
        </Link>
        }
        <br />
      </div>
    </>
  );
};

export default AuthForm;
