import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Input from "../../shared/components/FormElements/Input";
import Button from '../../shared/components/FormElements/Button';
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { VALIDATOR_MINLENGTH } from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { Row, Col } from "antd";
import "./AuthInvite.css";

const ResetPassword = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();
  const baseUrl = process.env.REACT_APP_API_URL;
  const [token, setToken] = useState(null)
  

  const [formState, inputHandler ] = useForm(
    {
      newPassword: {
        value: "",
        isValid: false,
      },
      confirmNewPassword: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  useEffect(() => {
    const url = window.location.href;    ;
    setToken(url.split("/").pop());
  }, []);

  const authSubmitHandler = async (event) => {
    event.preventDefault();

    let url = `${baseUrl}/users/reset_password`;
    let formData = {
      token: token,
      new_password: formState.inputs.newPassword.value,
      verify_password: formState.inputs.confirmNewPassword.value
    };
    try {
      const responseData = await sendRequest(
        url,
        "POST",
        JSON.stringify(formData),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData) {
        history.push('/auth');
      }
    } catch (err) {}
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <div className="auth-wrapper">
        <Row>
          <Col span={12} className="left-side">
            <div className="header">
              <div className="logo-wrapper">
                <Link to="/">
                  <img src="/assets/imgs/logo.png" width="100%" />
                  <span>Data Literacy Game</span>
                </Link>
              </div>
            </div>
            <h1 className="invite-headline">
                Reset Your Password
            </h1>
            <div className="invite-form-wrapper">
              {isLoading && <LoadingSpinner asOverlay />}
              <hr />
              <form onSubmit={authSubmitHandler}>
                <Input
                  element="input"
                  id="newPassword"
                  type="password"
                  label="New Password"
                  validators={[VALIDATOR_MINLENGTH(6)]}
                  errorText="Please enter a valid password, at least 6 characters."
                  onInput={inputHandler}
                />

                <Input
                  element="input"
                  id="confirmNewPassword"
                  type="password"
                  label="Confirm New Password"
                  validators={[VALIDATOR_MINLENGTH(6)]}
                  errorText="Please enter a valid password, at least 6 characters."
                  onInput={inputHandler}
                />
                <br/>
                <Button type="submit" disabled={!formState.isValid}>
                  Reset Password
                </Button>
              </form>
            </div>
          </Col>
          <Col span={12}>
            <img src="/assets/imgs/auth.jpg" width="100%" />
          </Col>
        </Row>
      </div>
      <div className="ecampus-logo">
        <img src="/assets/imgs/logo-ecampus.png" />
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
