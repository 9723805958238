import React, { useEffect, useState, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { staticContent, language } from "../../shared/data/dictionary";

import PlaysList from "../../scenario/components/PlaysList";
import { Button } from "antd";

const PlayerPlays = () => {
  const auth = useContext(AuthContext);
  const classHttp = useHttpClient();
  const classesHttp = useHttpClient();
  const [loadedPlays, setLoadedPlays] = useState();
  const [playerName, setPlayerName] = useState();
  const [classTitle, setClassTitle] = useState();

  const scenarioId = useParams().scenarioid;
  const classId = useParams().classid;
  const playerId = useParams().playerid;
  const baseUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const url = `${baseUrl}/classes/${classId}/players/${playerId}`;
        const responseData = await classesHttp.sendRequest(url, "GET", null, {
          Authorization: "Bearer " + auth.token,
        });
        setLoadedPlays(
          responseData.plays.map((play) => {
            return {
              id: play._id,
              scenario: {
                _id: play.scenario_id._id,
                title: play.scenario_id.title,
              },
              player: {
                _id: play.player_id._id,
                name: play.player_id.name,
                email: play.player_id.email,
              },
              score: play.submitted_at ? play.score : 0,
              submitted_at: play.submitted_at,
            };
          })
        );
        setPlayerName(responseData.plays[0].player_id.name);
        setClassTitle(responseData.plays[0].class_id.title);
      } catch (err) {
        console.log("fetchClasses err", err);
      }
    };
    fetchClasses();
  }, [classHttp.sendRequest]);

  return (
    <React.Fragment>
      <ErrorModal error={classesHttp.error} onClear={classesHttp.clearError} />

      <div className="section-header">
        <h1>
          {playerName}
          {!classesHttp.isLoading && playerName && classTitle && (
            <p className="sub-headline">{classTitle}</p>
          )}
        </h1>
        <div className="btns">
          <Button type="default">
            <Link to="/scenarios">{staticContent[language]?.goBack}</Link>
          </Button>
        </div>
      </div>

      {classesHttp.isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}

      {!classesHttp.isLoading && loadedPlays && (
        <PlaysList
          items={loadedPlays}
          reviewBtn={true}
          showScenarioTitle={true}
          showScore={true}
          showLastAttemptDate={true}
          pagination={false}
        />
      )}
    </React.Fragment>
  );
};

export default PlayerPlays;
