import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
import Card from '../../shared/components/UIElements/Card';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
  VALIDATOR_MAXLENGTH
} from '../../shared/util/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import ImageUpload from "../../shared/components/FormElements/ImageUpload";
import { toast } from "react-toastify";
import { Row, Col } from 'antd';
import SectionHeader from '../../panel/components/SectionHeader';

const UpdatePlace = () => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [scenarioSetting, setScenarioSetting] = useState();
  const scenarioId = useParams().scenarioId;
  const history = useHistory();
  const baseUrl = process.env.REACT_APP_API_URL;
  const [formState, inputHandler, setFormData] = useForm(
    {
      title: {
        value: '',
        isValid: false
      },
      title_fr: {
        value: '',
        isValid: false
      },
      description: {
        value: '',
        isValid: false
      },
      description_fr: {
        value: '',
        isValid: false
      },
    },
    false
  );

  useEffect(() => {
    const fetchScenario = async () => {
      try {
        const responseData = await sendRequest(
          `${baseUrl}/scenarios/${scenarioId}/setting`, "GET", null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setScenarioSetting(responseData);
        console.log('gggg', responseData);
      } catch (err) {}
    };
    fetchScenario();
  }, [sendRequest, scenarioId, setFormData]);

  const submitHandler = async event => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("title", formState.inputs.title.value);
    formData.append("title_fr", formState.inputs.title_fr.value);
    formData.append("description", formState.inputs.description.value);
    formData.append("description_fr", formState.inputs.description_fr.value);
    formData.append("image", formState.inputs.image ? formState.inputs.image.value : null);
    formData.append("max_score", formState.inputs.max_score.value);
    formData.append("full_score", formState.inputs.full_score.value);
    formData.append("min_passing", formState.inputs.min_passing.value);
    formData.append("submit_max", formState.inputs.submit_max.value);
    formData.append("resubmit_penalty", formState.inputs.resubmit_penalty.value);
    formData.append("score_feedback_max", formState.inputs.score_feedback_max.value);
    formData.append("score_feedback_penalty", formState.inputs.score_feedback_penalty.value);
    formData.append("quick_feedback_max", formState.inputs.quick_feedback_max.value);
    formData.append("quick_feedback_penalty", formState.inputs.quick_feedback_penalty.value);
    formData.append("max_background", formState.inputs.max_background.value);
    formData.append("max_data", formState.inputs.max_data.value);
    formData.append("max_insight", formState.inputs.max_insight.value);

    try {
      await sendRequest(
        `${baseUrl}/scenarios/${scenarioId}/setting`,
        'PATCH',
        formData,
        {
          'Authorization': 'Bearer ' + auth.token
        }
      );
      // setFormData();
      history.push('/scenarios');
      toast('Updated successfully');
    } catch (err) {
      console.log(err)
    }
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!scenarioSetting && !error) {
    return (
      <div className="center">
        <Card>
          <h2>Could not find scenario!</h2>
        </Card>
      </div>
    );
  }

  return (
    <React.Fragment>
      <SectionHeader title="Update scenario settings" backBtn={true}/>
      <ErrorModal error={error} onClear={clearError} />
      {!isLoading && scenarioSetting && (
        <form className="form" onSubmit={submitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        <Input
          id="title"
          element="input"
          type="text"
          label="Title"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please enter a valid title."
          onInput={inputHandler}
          initialValue={scenarioSetting.scenario.title}
          initialValid={true}
        />
        {console.log(scenarioSetting)}
        <Input
          id="title_fr"
          element="input"
          type="text"
          label="Title(Fr)"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please enter a valid title."
          onInput={inputHandler}
          initialValue={scenarioSetting.scenario?.fr?.title}
          initialValid={true}
        />
        <Input
          id="description"
          element="textarea"
          label="Description"
          validators={[]}
          errorText="Please enter a valid description (at least 5 characters)."
          onInput={inputHandler}
          initialValue={scenarioSetting.scenario.description}
          initialValid={true}
        />
        <Input
          id="description_fr"
          element="textarea"
          label="Description(Fr)"
          validators={[]}
          errorText="Please enter a valid description (at least 5 characters)."
          onInput={inputHandler}
          initialValue={scenarioSetting.scenario?.fr?.description}
          initialValid={true}
        />
        <ImageUpload
          id="image"
          onInput={inputHandler}
          errorText="Image upload"
          previewUrl={scenarioSetting.scenario.image}
        />
        <hr />
        <Input
          id="max_score"
          element="input"
          label="Maximum possible score"
          validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
          errorText="Please enter a valid number for maximum score."
          onInput={inputHandler}
          initialValue={scenarioSetting.max_score}
          initialValid={true}
        />
        <Input
          id="full_score"
          element="input"
          label="Full mark score"
          guide="Recommended by our team"
          validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
          errorText="Please enter a valid number for full score."
          onInput={inputHandler}
          initialValue={scenarioSetting.full_score}
          initialValid={true}
        />
        <Input
          id="min_passing"
          element="input"
          label="Minimum passing"
          guide="Recommended by our team"
          validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
          errorText="Please enter a valid minimum passing."
          onInput={inputHandler}
          initialValue={scenarioSetting.min_passing}
          initialValid={true}
        />
        <Input
            id="submit_max"
            element="input"
            label="Maximum number of attempts"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(2)]}
            errorText="Please enter a valid integer number for retry"
            onInput={inputHandler}
            initialValue={scenarioSetting.submit_max}
            initialValid={true}
          />
          <Input
            id="resubmit_penalty"
            element="input"
            label="Attempts penalty"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
            errorText="Please enter a valid retry penalty, it should be negative number"
            onInput={inputHandler}
            initialValue={scenarioSetting.resubmit_penalty}
            initialValid={true}
          />
          <Input
            id="score_feedback_max"
            element="input"
            label="Maximum number of score range feedback"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(2)]}
            errorText="Please enter a valid integer number for brief"
            onInput={inputHandler}
            initialValue={scenarioSetting.score_feedback_max}
            initialValid={true}
          />
          <Input
            id="score_feedback_penalty"
            element="input"
            label="Score range feedback penalty"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
            errorText="Please enter a valid brief feedback penalty."
            onInput={inputHandler}
            initialValue={scenarioSetting.score_feedback_penalty}
            initialValid={true}
          />
          <Input
            id="quick_feedback_max"
            element="input"
            label="Maximum number of quick feedback"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(2)]}
            errorText="Please enter a valid integer number for quick"
            onInput={inputHandler}
            initialValue={scenarioSetting.quick_feedback_max}
            initialValid={true}
          />
          <Input
            id="quick_feedback_penalty"
            element="input"
            label="Quick feedback penalty"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
            errorText="Please enter a valid quick feedback penalty."
            onInput={inputHandler}
            initialValue={scenarioSetting.quick_feedback_penalty}
            initialValid={true}
          />

          <br/> <hr/>
          <h5 className="no-margin">Maximum number allowed for deck slide</h5>
          <Row gutter={16}>
            <Col span={8}>
                <Input
                id="max_background"
                element="input"
                label="Background slides"
                validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
                errorText="Please enter a valid quick feedback penalty."
                onInput={inputHandler}
                initialValue={scenarioSetting.max_background}
                initialValid={true}
              />
            </Col>
            <Col span={8}>
              <Input
                  id="max_data"
                  element="input"
                  label="Data slides"
                  validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
                  errorText="Please enter a valid quick feedback penalty."
                  onInput={inputHandler}
                  initialValue={scenarioSetting.max_data}
                  initialValid={true}
                />
            </Col>
            <Col span={8}>
              <Input
                  id="max_insight"
                  element="input"
                  label="Insight slides"
                  validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
                  errorText="Please enter a valid quick feedback penalty."
                  onInput={inputHandler}
                  initialValue={scenarioSetting.max_insight}
                  initialValid={true}
                />
            </Col>
          </Row>

          <Button
            type="submit"
            disabled={!formState.isValid}
            fullWidth={true}
            marginClass="mt-30"
          >
          Update
        </Button>
      </form>
      )}
    </React.Fragment>
  );
};

export default UpdatePlace;
