import React from "react";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../shared/context/auth-context";
import { useHttpClient } from "../shared/hooks/http-hook";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";
import Slide from "./Slide";
import { staticContent, language } from "../shared/data/dictionary";
import { Translate } from "../shared/util/translator";

export function ImageDescriptionDialog({ open, handleClose, description }) {

  return (
    <Modal show={open} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{staticContent[language].image_description}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container style={{ marginBottom: "20px" }}>
          <p>{description ? description : staticContent[language].resource_not_found}</p>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
