import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import ScenariosList from '../components/ScenariosList';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import SectionHeader from '../../panel/components/SectionHeader';
import { toast } from "react-toastify";

const Scenarios = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedScenarios, setLoadedScenarios] = useState();
  const auth = useContext(AuthContext);
  const baseUrl = process.env.REACT_APP_API_URL;
  // const location = useLocation();

  // useEffect(() => {
  //   console.log("Url has changed")
  //   toast(location.state)
  //   location.state = null
  // }, [location])

  useEffect(() => {

    const fetchScenarios = async () => {
      try {
        const responseData = await sendRequest(`${baseUrl}/scenarios`, 'GET', null, {
          Authorization: 'Bearer ' + auth.token
        });

        setLoadedScenarios(responseData);
      } catch (err) {
        console.log("fetchScenarios err", err)
      }
    };
    fetchScenarios();

  }, [sendRequest]);


  const sectionHeaderBtns = [
    { text: 'Create new scenario', link: '/scenarios/create' }
  ]

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}

      <SectionHeader title="Scenarios" btns={sectionHeaderBtns}/>
      {!isLoading && loadedScenarios && <ScenariosList items={loadedScenarios} deleteBtn={false}/>}
      
    </React.Fragment>
  );
};

export default Scenarios;
