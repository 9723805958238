import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Avatar from "../../shared/components/UIElements/Avatar";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { Popconfirm, message } from "antd";
import { DeleteOutlined, UserOutlined } from "@ant-design/icons";

// import Card from '../../shared/components/UIElements/Card';
import "./ScenarioItem.css";

const ScenarioItem = (props) => {
  const auth = useContext(AuthContext);
  const baseUrl = process.env.REACT_APP_API_URL;
  const uploadUrl = process.env.REACT_APP_UPLOADS_URL;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const deleteScenarioHandler = async () => {
    try {
      const response = await sendRequest(
        `${baseUrl}/scenarios/${props.id}`,
        "DELETE",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      toast("Deleted successfully");
      location.reload();
    } catch (err) {
      console.log("fetchScenarios err", err);
    }
  };

  const cancelDelete = () => {
    // message.error('Click on No');
  };

  return (
    <div className="scenario-item">
      <Card className="horizonal-card scenario-card">
        <Card.Img variant="top" src={uploadUrl + props.image} />
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
          <Card.Text>{props.description}</Card.Text>
          {props.playBtn && (
            <Link to={`/scenarios/${props.id}/play`}>
              <Button
                size={"small"}
                variant="dark"
                className="btn__scenario-play"
              >
                Play
              </Button>
            </Link>
          )}
          {props.isAdmin && (
            <div className="card-btns">
              {props.deleteBtn && (
                <Popconfirm
                  title="Are you sure to delete this scenario?"
                  onConfirm={deleteScenarioHandler}
                  onCancel={cancelDelete}
                  okText="Yes"
                  cancelText="No"
                  okType="danger"
                  icon={<DeleteOutlined />}
                  placement="left"
                >
                  <Button
                    size={"large"}
                    variant="danger"
                    type="dashed"
                    className="margin-right-auto"
                  >
                    Delete scenario
                  </Button>
                </Popconfirm>
              )}

              {props.addSlideBtn && (
                <Link to={`/scenarios/${props.id}/slides/create`}>
                  <Button
                    size={"small"}
                    variant="dark"
                    className="btn__scenario-play"
                  >
                    Add new slide
                  </Button>
                </Link>
              )}
              {props.addCombinationScore && (
                <Link to={`/scenarios/${props.id}/combos/edit`}>
                  <Button
                    size={"small"}
                    variant="dark"
                    className="btn__scenario-play"
                  >
                    Codes and Combos
                  </Button>
                </Link>
              )}

              {props.editScenarioBtn && (
                <Link to={`/scenarios/${props.id}`}>
                  <Button
                    size={"small"}
                    variant="dark"
                    className="btn__scenario-play"
                  >
                    More info
                  </Button>
                </Link>
              )}

              {props.settingScenarioBtn && (
                <Link to={`/scenarios/${props.id}/setting`}>
                  <Button
                    size={"small"}
                    variant="dark"
                    className="btn__scenario-play"
                  >
                    Setting
                  </Button>
                </Link>
              )}
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ScenarioItem;
