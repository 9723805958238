import React, { useState } from "react";
import { Link } from "react-router-dom";

import Card from "react-bootstrap/Card";
import { Button } from "antd";
// import Card from '../../shared/components/UIElements/Card';
import "./ScenarioItemInstructor.css";
import { staticContent, language } from "../../shared/data/dictionary";

const ScenarioItemInstructor = (props) => {

  const [selected, setSelected] = useState(props.selected)

  const scenarioSelectHandler = () => {
    props.onScenarioSelected(props.scenarioId)
    setSelected(prevState => !prevState)
    return true
  }


  return (
    <div className="scenario-item">
      <Card className={`horizonal-card scenario-card-instructor ${
        selected && "selected"
      }`}>
        {/* <Card.Img variant="top" src={`http://localhost:5000/${props.image}`} /> */}
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
          {props.stats && (
            <div className="stats">
              {/* each stats */}
              <div className="each-stats">
                <span className="number">280</span>
                <span className="label">{staticContent[language]?.players} {staticContent[language]?.played}</span>
              </div>
              {/* each stats */}
              <div className="each-stats">
                <span className="number">120</span>
                <span className="label">{staticContent[language]?.average} {staticContent[language]?.score}</span>
              </div>
            </div>
          )}
          <div className="btns">
            {props.selectbtn && (
              <Button type="default" onClick={scenarioSelectHandler}>
                { !selected ? staticContent[language].select : staticContent[language].unSelect }
              </Button>
            )}
            
            {props.morebtn && 
            <Link to={`/scenarios/${props.scenarioId}/${props.classId}`}>
              <Button type="default">{staticContent[language]?.moreDetails}</Button>
            </Link>
            }
            
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ScenarioItemInstructor;
