import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import MainHeader from "./MainHeader";
import NavLinks from "./NavLinks";
import RightNavLinks from "./RightNavLinks";
import SideDrawer from "./SideDrawer";
import Backdrop from "../UIElements/Backdrop";
import "./MainNavigation.css";
import Container from "../UIElements/Container";
import Logo from "../UIElements/Logo";

const MainNavigation = (props) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  return (
    <React.Fragment>
      <div className="main-navigation">
        <Container variant={props.variant}>
          <button
            className="main-navigation__menu-btn"
            onClick={openDrawerHandler}
          >
            {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
            <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
              <nav className="main-navigation__drawer-nav">
                <NavLinks />
              </nav>
            </SideDrawer>
            <span />
            <span />
            <span />
          </button>
          <MainHeader>
            <div className="main-navigation__header-left-nav">
              <div className="main-navigation__logo-wrapper">
                <div className="main-navigation__title">
                  <Logo />
                </div>
              </div>
              <nav className="main-navigation__header-nav">
                <NavLinks role={props.role} avatar={props.avatar} />
              </nav>
            </div>
            <nav className="main-navigation__header-right-nav">
              <RightNavLinks role={props.role} avatar={props.avatar} />
            </nav>
          </MainHeader>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MainNavigation;
