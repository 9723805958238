import React from "react";
import Table from "react-bootstrap/Table";
import { useSelector, useDispatch } from "react-redux";
import classNames from 'classnames';
import { ExpandState } from "../../../store/types";
import { expanderActions } from "../../../store/expander"


export const Expander = function (props: any) {
  const expandedContainer = useSelector(
    (state: ExpandState) => state.expandedContainer
  );

  const dispatch = useDispatch();

  const expandHandler = (event: any) => {
    dispatch(expanderActions.toggleExpand());
  };

  return (
    <div className="expander-wrapper" onClick={expandHandler}>
      <img src="/assets/imgs/icons/left-arrow.png" alt="" className="" />
    </div>
  );
};

export default Expander;
