export const Translate = (entity, property) => {
    const lang = localStorage.getItem("locale");
    if(lang == 'fr'){
        if (entity[lang]) {
            return entity[lang][property];
        } else {
            return '';
        }
    }else{
        return entity[property]
    }
}