import React, {CSSProperties} from 'react';
import classNames from 'classnames';

import styles from './Action.module.css';

export interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  active?: {
    fill: string;
    background: string;
  };
  cursor?: CSSProperties['cursor'];
}

export function Action({active, className, cursor, style, ...props}: Props) {

  const focusHandler = (e: any) => {
  
    const slide = e.target?.closest(".item") as HTMLElement
    slide?.classList.add("active-item")

  }

  const blurHandler = (e: any) => {
  
    const slide = e.target?.closest(".item") as HTMLElement
    slide?.classList.remove("active-item")
    
  }

  return (
    <button
      {...props}
      className={classNames(styles.Action, className)}
      tabIndex={0}
      // tabIndex={undefined}
      onFocus={focusHandler}
      onBlur={blurHandler}
      style={
        {
          ...style,
          cursor,
          '--fill': active?.fill,
          '--background': active?.background,
        } as CSSProperties
      }
    />
  );
}
