const data = [
  // background
  {
    title: "Last race of the season",
    text: "Tomorrow's race is the 25th and final race of the season for Team Gamma. Team Gamma's current ranking is 4th. If they do well tomorrow, they could finish the season in 3rd place.",
    type: "background",
    id: "BB1",
  },

  // data
  {
    title: "Severity of piston failures",
    type: "data",
    text: "2 races resulted in more than one piston failing.",
    id: "D06",
    image: null
  },

  // insight
  {
    title: "Cylinder failure impact",
    type: "insight",
    text: "This car has never experienced a 4-cylinder failure in its entire season.\nEven if 3 cylinders fail, we will have several months to refurbish and improve on the engine.",
    id: "IR1",
  }
];

export default data;