import React, { useEffect, useState, useContext } from 'react';
import { Link, useParams, useHistory } from "react-router-dom";
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

import PlayersList from '../components/PlayersList';
import { Button } from "antd";
import { staticContent } from "../../shared/data/dictionary";

const language = localStorage.getItem("locale");
const ClassInstructor = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedClass, setLoadedClass] = useState();
  const auth = useContext(AuthContext);
  const baseUrl = process.env.REACT_APP_API_URL
  const classId = useParams().id;

  useEffect(() => {

    const fetchClass = async () => {
      try {
        const responseData = await sendRequest(`${baseUrl}/classes/${classId}`, 'GET', null, {
          Authorization: 'Bearer ' + auth.token
        });
        setLoadedClass(responseData);
      } catch (err) {
        // console.log("fetchClass err", err)
      }
    };
    fetchClass();

  }, [sendRequest]);

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      <div className="section-header">
        <h1>
          {staticContent[language]?.players}
          {!isLoading && loadedClass && <p className="sub-headline">{loadedClass.title}</p> }
        </h1>
        <div className="btns">
          <Button type="default" className="btn-dark"><Link to="/classes">{staticContent[language]?.goBack}</Link></Button>
        </div>
      </div>
      {!isLoading && loadedClass && <PlayersList classId={classId} items={loadedClass.players} />}
      
    </React.Fragment>
  );
};

export default ClassInstructor;
