import Navbar from "react-bootstrap/Navbar";
// import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { staticContent } from "../../shared/data/dictionary";
import { toast } from "react-toastify";
import {
  FileOutlined,
  DiffOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
import { Popconfirm, Tooltip, Button } from "antd";
import { FeedbackDialog } from "../feedbackDialog";
import { GuideDialog } from "../guideDialog";

function FilterNav(props) {
  const language = localStorage.getItem("locale") || "en";
  const [feedbackType, setFeedbackType] = useState("");
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [guideModalOpen, setGuideModalOpen] = useState(false);
  const items = useSelector((state) => state.scenario.slides);

  // get filters names
  const slides = useSelector((state) => state.scenario.slides);
  const bankContainer = slides.bankContainer.map((slide) => JSON.parse(slide));
  const uniqueThemes = new Set();
  for (const slide of bankContainer) {
    if (slide.theme) {
      const theme = slide.theme;
      uniqueThemes.add(theme);
    }
  }

  const handleFilter = function (selectedOptions) {
    const selectedValues = selectedOptions.map((option) => option.value);
    props.onFilterChange(selectedValues);
  };

  // @ts-ignore
  const values = props.filterBy.map((filter) => {
    return {
      value: filter,
      label: staticContent[language][filter]
        ? staticContent[language][filter]
        : filter,
    };
  });

  const options = [
    // @ts-ignore
    { value: "background", label: staticContent[language]?.background },
    // @ts-ignore
    { value: "data", label: staticContent[language]?.data },
    // @ts-ignore
    { value: "insight", label: staticContent[language]?.insight },
    // @ts-ignore
    { value: "rejected", label: staticContent[language]?.rejected },
  ];

  for (const theme of Array.from(uniqueThemes)) {
    options.push({ value: theme, label: theme });
  }
  // console.log("values", values)
  // console.log("options", options)

  // @ts-ignore
  const scenarioId = useParams().id;
  // @ts-ignore
  const classId = useParams().classid;

  // const values = [
  //   { value: 'background', label: 'Background'}
  // ]

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "34px",
      height: "34px",
      boxSizing: "content-box",
      fontSize: "13px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "100%",
      padding: "0px 8px",
      marginTop: "-2px",
      fontSize: "14px",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      margin: "0 2px",
      // textTransform: 'capitalize'
    }),
    singleValue: (provided, state) => ({
      ...provided,
      textTransform: "capitalize",
      color: "#808080",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      // display: 'none',
    }),
    menu: (provided, state) => ({
      ...provided,
      minWidth: "350px",
      transform: "translateX(-45px)",
    }),
  };

  const FilterSelect = () => (
    <Select
      options={options}
      value={values}
      isMulti
      className="react-select"
      styles={customStyles}
      onChange={handleFilter}
      placeholder={staticContent[language].filter}
      tabIndex="0"
    />
  );

  const handleSort = function (selectedValue) {
    props.onSortChange(selectedValue.value);
  };

  const sortOptions = [
    { value: "type", label: staticContent[language].sortByType },
    { value: "title", label: staticContent[language].sortByTitle },
    // { value: 'color', label: 'Sort by Color' },
    { value: "theme", label: staticContent[language].sortByTheme },
  ];
  const sortValue = {
    value: props.sortBy,
    label: staticContent[language].sortBy + " " + props.sortBy,
  };
  const SortSelect = () => (
    <Select
      options={sortOptions}
      value={sortValue}
      className="react-select sort-select"
      styles={customStyles}
      onChange={handleSort}
      placeholder={staticContent[language].sort}
      tabIndex="0"
    />
  );

  // toggle expand
  const expandedContainer = useSelector(
    (state) => state.expander.expandedContainer
  );

  // deck validation
  const deckErrors = useSelector((state) => state.scenario.deckErrors);

  const showDeckErrorAlert = () => {
    toast.error(staticContent[language].deckError, { autoClose: 8000 });
  };

  const numberOfNonBackgroundSlideinDeck = items.selectedContainer
    .map((item) => JSON.parse(item))
    .filter((item) => item.type == "data" || item.type == "insight").length;
  const soloFeedbackDisabled =
    numberOfNonBackgroundSlideinDeck < 2 ? true : false;

  const openIndividualFeedbackDialog = () => {
    setFeedbackType("individual");
    setFeedbackModalOpen(true);
  };

  const openComboFeedbackDialog = () => {
    setFeedbackType("combo");
    setFeedbackModalOpen(true);
  };

  const openGuideDialog = () => {
    setGuideModalOpen(true);
  };

  return (
    <div className="filter-nav">
      <div className="core-section-types">
        <div className="bank">
          <div className="title">{staticContent[language].slideBank}</div>
          <div className="filter-form-wrapper">
            <Form inline>
              {/* filter by */}
              {/* <Form.Group>
                    <Form.Control as="select" onChange={handleFilter}>
                        <option value="all">show all</option>
                        <option>background</option>
                        <option>data</option>
                        <option>insight</option>
                    </Form.Control>
                </Form.Group> */}
              {expandedContainer == "bankContainer" && (
                <>
                  <div className="react-select-parent">
                    <FilterSelect></FilterSelect>
                  </div>
                  <div className="react-select-parent">
                    <SortSelect></SortSelect>
                  </div>
                </>
              )}
            </Form>
          </div>
        </div>
        <div className="selected">
          <span>{staticContent[language].slideDeck}</span>
          <div className="feedback-btn">
            <Popconfirm
              title={staticContent[language]?.feedbackConfirmPopup}
              onConfirm={openIndividualFeedbackDialog}
              okText="Yes"
              cancelText="No"
              disabled={soloFeedbackDisabled}
            >
              <Tooltip
                title={`${staticContent[language]?.individualFeedback} ${
                  soloFeedbackDisabled && staticContent[language]?.unavailable
                }`}
              >
                <Button
                  className={`btn-dark btn-icon ${
                    soloFeedbackDisabled && "btn-disabled"
                  }`}
                  icon={<FileOutlined />}
                  tabIndex={2}
                ></Button>
              </Tooltip>
            </Popconfirm>
            <Popconfirm
              title={staticContent[language]?.feedbackConfirmPopup}
              onConfirm={openComboFeedbackDialog}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title={staticContent[language]?.comboFeedback}>
                <Button
                  className="btn-dark btn-icon"
                  icon={<DiffOutlined />}
                  tabIndex={2}
                ></Button>
              </Tooltip>
            </Popconfirm>
            <Tooltip title={staticContent[language]?.guide}>
              <Button
                className="btn-dark btn-icon"
                icon={<QuestionOutlined />}
                tabIndex={2}
                onClick={openGuideDialog}
              ></Button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div
        className={classNames("selected-submit")}
        onClick={
          deckErrors.length == 0
            ? props.onDeckSubmitAttemptHandler
            : showDeckErrorAlert
        }
      >
        <Button
          className={classNames(
            "selected-submit-btn",
            deckErrors.length > 0 && "disabled"
          )}
          tabIndex={2}
        >
          {staticContent[language].submit}
        </Button>
      </div>
      <FeedbackDialog
        feedbackType={feedbackType}
        open={feedbackModalOpen}
        scenarioId={scenarioId}
        classId={classId}
        handleClose={setFeedbackModalOpen}
        items={items}
      />

      <GuideDialog open={guideModalOpen} handleClose={setGuideModalOpen} />
    </div>
  );
}

export default FilterNav;
