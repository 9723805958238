import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

import ClassesListInstructor from '../components/ClassesListInstructor';
import { Button } from "antd";
import { staticContent, language } from '../../shared/data/dictionary';

const ClassesInstructor = () => {

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedClasses, setLoadedClasses] = useState();
  const auth = useContext(AuthContext);
  const baseUrl = process.env.REACT_APP_API_URL

  useEffect(() => {

    const fetchClasses = async () => {
      try {
        const responseData = await sendRequest(baseUrl+'/classes', 'GET', null, {
          Authorization: 'Bearer ' + auth.token
        });

        setLoadedClasses(responseData.classes);
      } catch (err) {
        // console.log("fetchClasses err", err)
      }
    };
    fetchClasses();

  }, [sendRequest]);

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      <div className="section-header">
        <h1>Classes</h1>
        <div className="btns">
          <Button type="primary"><Link to="/classes/create">{staticContent[language]?.addNewClass}</Link></Button>
        </div>
      </div>
      {!isLoading && loadedClasses && 
        <ClassesListInstructor 
          items={loadedClasses} 
          settingbtn={true} 
          selectbtn={false} 
          playersbtn={true}
          invitebtn={true}
        />
      }
      
    </React.Fragment>
  );
};

export default ClassesInstructor;
