/* eslint-disable */
import React, { useEffect, useContext, useState, useRef } from "react";
import // CancelDrop,
// closestCorners,
// CollisionDetection,
// rectIntersection,
"@dnd-kit/core";
import { rectSortingStrategy } from "@dnd-kit/sortable";

import {
  CoreLoopContainers,
  // defaultContainerStyle,
  // VOID_ID,
} from "./CoreLoopContainers";
// import { ConfirmModal } from "./components";
import Header from "../../components/Header";
// import slideType from "../../data/SlideType";
import initialSlidesData from "../../data/slidesData";
import FilterNav from "./FilterNav.jsx";
import Expander from "./components/Expander";
import { useSelector, useDispatch } from "react-redux";
import { slidesActions } from "../../store/scenario";
// import { ExpandState } from "../../store/types";
import ScoreModal from "../ScoreModal.jsx";
// import { Link, Redirect } from 'react-router-dom';
import { useHistory, useParams, useLocation } from "react-router-dom";
import { AuthContext } from "../../shared/context/auth-context";
import { saveSlides, getScoreRange } from '../saveSlides';
import { GuideDialog } from '../guideDialog';
import { ImageDescriptionDialog } from '../imageDescriptionDialog';
// type Items = Record<string, string[]>;

// keyboard navigator
const slideHeight = 460;
const slideWidth  = 420;

const whatAmI = function (source: HTMLElement) {
  //this function tells you which number the child is
  //in everything nested in the parent element

  // const childCount = source?.closest('ul')?.children?.length

  const childrens = source?.closest("ul")?.querySelectorAll(".item");

  if (childrens && childrens.length) {
    for (let x = 0; x < childrens.length; x++) {
      if (source?.classList.contains("hide") === false) {
        if (childrens[x] === source)
          // return alert("I am child #" + x);
          return x;
      }
    }
  }
};

const handleKeyDown = function (e: any) {
  const dragging_item_exist = document
    .getElementsByClassName("item-dragging")
    .item(0);
  if (dragging_item_exist) {
    return false;
  }
  const arrowKeys = [37, 38, 39, 40];
  const { innerWidth: width, innerHeight: height } = window;

  if (arrowKeys.includes(e.keyCode)) {
    const activeItem = document.getElementsByClassName("active-item").item(0) as HTMLElement;
    if (activeItem) {
      let whichChildAmI = whatAmI(activeItem);

      if (whichChildAmI !== undefined) {
        const coreLoop = document.getElementById("core-loop");

        let upDownModifier = 1;
        if (coreLoop?.classList.contains("expand-bankContainer")) {
          if(innerWidth <= 1378){
            upDownModifier = 2;
          }else{
            upDownModifier = 3;
          }
        }

        let newActiveElementNumber = 0;
        switch (e.keyCode) {
          case 37:
            newActiveElementNumber = whichChildAmI - 1;
            break;
          case 39:
            newActiveElementNumber = whichChildAmI + 1;
            break;
          case 38:
            newActiveElementNumber = whichChildAmI - upDownModifier;
            break;
          case 40:
            newActiveElementNumber = whichChildAmI + upDownModifier;
            break;

          default:
            break;
        }

        // focus on the item based on arrow keys pressed
        const newActiveItem = document
          .getElementsByClassName("item")
          .item(newActiveElementNumber) as HTMLElement;

        if (newActiveItem !== undefined) {
          setTimeout(() => {
            activeItem.classList.remove("active-item");
            newActiveItem?.classList.add("active-item");

            const newActiveDragHandle = newActiveItem?.querySelector(
              ".drag-handle"
            ) as HTMLElement;
            newActiveDragHandle?.focus({ preventScroll: true });

            // scroll
            const bankContainer = document.getElementById("bankContainer");
            // update scroll position smoothly
            const scrollTo =
              Math.trunc(newActiveElementNumber / 3) * slideHeight;
            // console.log(newActiveElementNumber)
            bankContainer?.scrollTo({ top: scrollTo, behavior: "smooth" });
          }, 100);
        }
      }
    }
  }

  // if fKey on keyboard pressed | nKey = 78
  if (e.keyCode === 70) {
    // const activeItem = document.getElementsByClassName("active-item")?.item(0) as HTMLElement;
    // if(activeItem){
    //   activeItem.classList.remove("active-item");
    // }
    const first_item = document.getElementsByClassName("item")?.item(0) as HTMLElement;
    // first_item?.classList.add("active-item");
    const first_drag_handle = first_item?.querySelector(
      ".drag-handle"
    ) as HTMLElement;
    first_drag_handle?.focus({ preventScroll: true });

    // scroll
    const bankContainer = document.getElementById("bankContainer");
    bankContainer?.scrollTo({ top: 0, behavior: "smooth" });
  }
  
  // whenever tab is pressed
  if (e.keyCode === 9) {
    const activeElement = document.activeElement
    const slide = activeElement?.closest(".item") as HTMLElement
    // console.log(slide)
    // slide?.classList.add("red")
    // if(slide){
    //   const activeItem = document.getElementsByClassName("active-item")?.item(0) as HTMLElement;
    //   if(activeItem){
    //     activeItem.classList.remove("active-item");
    //   }

    //   slide?.classList.add("active-item");
    //   const slide_drag_handle = slide?.querySelector(
    //     ".drag-handle"
    //   ) as HTMLElement;
    //   slide_drag_handle?.focus({ preventScroll: true });
    // }
  }
};

const handleClick = (e: any) => {
  let clickedElement = e.target;
  
  let clickedItem:HTMLElement;
  // select parent item if slide childrens clickled
  if(clickedElement.classList.contains("item") === false){
    clickedItem = clickedElement.closest(".item")
  }else{
    clickedItem = clickedElement
  }

  if(!clickedItem){
    return;
  }

  // remove active item if user clicked on other parts of screen by mouse
  if (clickedItem.classList.contains("active-item") === false) {
    const item = document
      .getElementsByClassName("active-item")
      .item(0) as HTMLElement;
    item?.classList.remove("active-item");
  }
  if (clickedItem.classList.contains("item")) {
    setTimeout(() => {
      const activeItem = document
        .getElementsByClassName("active-item")
        .item(0) as HTMLElement;
      activeItem?.classList.remove("active-item");

      clickedItem?.classList.add("active-item");

      const newActiveDragHandle = clickedItem?.querySelector(
        ".drag-handle"
      ) as HTMLElement;
      newActiveDragHandle?.focus({ preventScroll: true });
    }, 100);
  }
};

// end of keyboard navigator

function CoreLoop() {
  const resetFocusRef = useRef<HTMLSpanElement>(null);
  const preventDefaultKeyDown = (e: KeyboardEvent) => {
    if (
      // "Space", 
      ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(
        e.code
      ) > -1
    ) {
      e.preventDefault();
    }
  }

  const [isLoading, setIsLoading] = useState(false);
  const [scenario, setScenario] = useState<any>();
  const [scoreModalInfo, setScoreModalInfo] = useState<any>();
  const [guideModalOpen, setGuideModalOpen] = useState(false)
  const [imageLongDescriptionModalShow, setImageLongDescriptionModalShow] = useState(false);
  const [imageModalDescription, setImageModalDescription] = useState('');
  const [scoreRange, setScoreRange] = useState(false)
  const [scoreRangeMessage, setScoreRangeMessage] = useState(false)
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  let developmentStage = urlParams.get('stage') ? urlParams.get('stage') : 'production';
  developmentStage = 'testing';
  
  const dispatch = useDispatch();

  const separators = [
    {
      title: "Background Slides",
      text: "",
      type: "background-separator",
      id: "background-separator",
    },
    {
      title: "Data Slides",
      text: "",
      type: "data-separator",
      id: "data-separator",
    },
    {
      title: "Insight Slides",
      text: "",
      type: "insight-separator",
      id: "insight-separator",
    },
  ];

  const auth = useContext(AuthContext);
  const scenarioId = useParams<any>().id;
  const classId = useParams<any>().classid;
  const baseUrl = process.env.REACT_APP_API_URL

  useEffect(() => {
    setGuideModalOpen(true);
  }, [])

  useEffect(() => {

    // resetFocusRef.current?.focus();

    const sendRequest = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${baseUrl}/scenarios/${scenarioId}/${classId}/play`,
          {
            headers: { Authorization: "Bearer " + auth.token },
          }
        );

        const responseData = await response.json();
        
        setScoreModalInfo({
          scoreFeedbackCount: responseData.scoreFeedbackCount,
          quickFeedbackCount: responseData.quickFeedbackCount,
          detailFeedbackCount: responseData.detailFeedbackCount,
        })
        setScenario(responseData.scenario);

        const bankSlides = responseData.slides.filter(
          (slide: any) => !slide.inDeck
        );
        let deckSlides = responseData.slides.filter(
          (slide: any) => slide.inDeck
        );

        deckSlides.push(...separators)

        const bankSlides_str = bankSlides.map((slide: object) =>
          JSON.stringify(slide)
        );
        const deckSlides_str = deckSlides.map((slide: object) =>
          JSON.stringify(slide)
        );


        setScenario(responseData.scenario);

        dispatch(
          slidesActions.updateItems({
            bankContainer: bankSlides_str,
            selectedContainer: deckSlides_str,
          })
        );

        dispatch(slidesActions.addSeparators());
        dispatch(slidesActions.validateDeck());

      } catch (err: any) {
        history.push('/auth');
      }
      setIsLoading(false);
    };
    sendRequest();

    const sendSettingRequest = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${baseUrl}/scenarios/${scenarioId}/${classId}/setting`,
          {
            headers: { Authorization: "Bearer " + auth.token },
          }
        );
        const responseData = await response.json();        
        if (!response.ok) {
          throw new Error(responseData.message);
        }
        
        dispatch(
          slidesActions.updateScenarioSetting(responseData)
        );

      } catch (err: any) {
        console.log(err)
      }
      setIsLoading(false);
    };
    sendSettingRequest();

    // keyboard navigator
    window.addEventListener("keydown", preventDefaultKeyDown, false);
    document.addEventListener("keydown", handleKeyDown, false);
    document.addEventListener("click", handleClick, false);
    // end of keyboard navigator

    // cleanup this component
    return () => {
      window.removeEventListener("keydown", preventDefaultKeyDown);
    };

  }, []);

  const slidesData = initialSlidesData;
  const [filterBy, setFilterBy] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<string>("type");
  const [deckResort, setDeckResort] = useState<boolean>(false);
  const expandedContainer = useSelector(
    (state: any) => state.expander.expandedContainer
  );
  const scenarioSetting = useSelector(
    (state: any) => state.scenario.scenarioSetting
  );
  const items = useSelector((state: any) => state.scenario.slides);

  const updateSlidesByFilter = function (filterBy: any) {
    setFilterBy(filterBy);
  };

  const updateSlidesBySort = function (sortBy: any) {
    setSortBy(sortBy);
  };

  const resortDeckHandler = function () {
    setDeckResort((state: any) => !state);
  };

  const [modalShow, setModalShow] = useState(false);

  // const handleClose = () => setModalShow(false);

  const handleDeckSubmitAttempt = async (e: any) => {
    e.preventDefault();
    const {scoreRange, message} = await getScoreRange(items, scenarioId, classId, auth);
    setScoreRange(scoreRange)
    setScoreRangeMessage(message)
    setModalShow(true);
  };

  const modalCloseHandler = () => {
    const score = null;
    setModalShow(false);
  };

  const history = useHistory();
  const ScoreModalSubmitHandler = async () => {
    await saveSlides(items, scenarioId, classId, auth);
    history.push("feedback");
  };

  const showImageLongDescriptionDialog = (description:string) => {
    setImageLongDescriptionModalShow(true);
    setImageModalDescription(description);
  }

  return (
    <div id="core-loop" className={"expand-" + expandedContainer}>
      <span ref={resetFocusRef} tabIndex={-1} />
      {/* 
      // @ts-ignore */}
      {!isLoading && scenario && (
      <Header deckSortBy={'theme'} onResortDeckClick={resortDeckHandler} save={false} scenarioTitle={scenario}></Header>
      )}
      {!isLoading && scenario && (
      <FilterNav
        onFilterChange={updateSlidesByFilter}
        filterBy={filterBy}
        onSortChange={updateSlidesBySort}
        sortBy={sortBy}
        onDeckSubmitAttemptHandler={handleDeckSubmitAttempt}
      ></FilterNav>
      ) }
      {!isLoading && (
        <CoreLoopContainers
          // slidesData={slidesData}
          filterBy={filterBy}
          sortBy={sortBy}
          deckResort={deckResort}
          columns={2}
          strategy={rectSortingStrategy}
          wrapperStyle={() => ({
            width: slideWidth,
            height: slideHeight,
          })}
          handle={true}
          expandedContainer={expandedContainer}
          showImageLongDescriptionDialog={showImageLongDescriptionDialog}
        />
      )}
      <Expander></Expander>
      <ScoreModal
        scoreRange={scoreRange}
        scoreRangeMessage={scoreRangeMessage}
        show={modalShow}
        onModalClose={modalCloseHandler}
        onModalSubmit={ScoreModalSubmitHandler}
        score_feedback_max={scenarioSetting.score_feedback_max}
        quick_feedback_max={scenarioSetting.quick_feedback_max}
        submit_max={scenarioSetting.submit_max}
        score_feedback_count={scoreModalInfo?.scoreFeedbackCount}
        quick_feedback_count={scoreModalInfo?.quickFeedbackCount}
        detail_feedback_count={scoreModalInfo?.detailFeedbackCount}
      />
      <GuideDialog open={guideModalOpen} handleClose={setGuideModalOpen}/>
      <ImageDescriptionDialog open={imageLongDescriptionModalShow} handleClose={setImageLongDescriptionModalShow} description={imageModalDescription}/>
    </div>
  );
}

export default CoreLoop;
