import { Link, useParams } from "react-router-dom";

import { Table, Button, Space } from "antd";

import "./PlaysList.css";
import { staticContent } from "../../shared/data/dictionary";

const language = localStorage.getItem("locale");

const PlaysList = (props) => {
  const scenarioId = useParams().scenarioid;
  const classId = useParams().classid;
  const dateOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  // if (props.items.length === 0) {
  //   return (
  //     <div className="center">
  //       <div className="">
  //         <h2>{staticContent[language].notFoundPlayers}</h2>
  //       </div>
  //     </div>
  //   );
  // }

  const columns = [];

  if (props.showPlayerName) {
    columns.push({
      title: staticContent[language].name,
      dataIndex: "player",
      key: "player_name",
      render: (text, record) => <a>{record.player.name}</a>,
    });
  }

  if (props.showScenarioTitle) {
    columns.push({
      title: staticContent[language].scenario,
      dataIndex: "scenario",
      key: "scenario_title",
      render: (text, record) => <a>{record.scenario.title}</a>,
    });
  }

  if (props.showScore) {
    columns.push({
      title: staticContent[language].score,
      dataIndex: "score",
      key: "score",
      render: (text) => <a>{text}</a>,
    });
  }

  if (props.showLastAttemptDate) {
    columns.push({
      title: staticContent[language].last_attempt,
      key: "submitted_at",
      render: (text, record) =>
        record.submitted_at
          ? new Date(record.submitted_at).toLocaleDateString(
              undefined,
              dateOptions
            )
          : staticContent[language].not_attempted,
      className: "table-action",
      width: "20%",
    });
  }

  if (props.reviewBtn) {
    columns.push({
      title: staticContent[language].reviewDeck,
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            {...(!record.submitted_at && { disabled: true })}
          >
            <Link
              disable
              to={`/scenarios/${scenarioId}/${classId}/${record.player._id}/feedback`}
            >
              {staticContent[language].review}
            </Link>
          </Button>
        </Space>
      ),
      className: "table-action",
    });
  }

  const data = props.items.map((item) => {
    return { ...item, key: item.id };
  });

  return (
    <div className="players-list">
      <Table
        columns={columns}
        dataSource={data}
        pagination={props.pagination}
      />
    </div>
  );
};

export default PlaysList;
