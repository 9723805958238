import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Button from '../../shared/components/FormElements/Button';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ImageUpload from '../../shared/components/FormElements/ImageUpload';
import Input from '../../shared/components/FormElements/Input';
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH
} from '../../shared/util/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import './Form.css';
import { staticContent } from '../../shared/data/dictionary'
import SectionHeader from '../../panel/components/SectionHeader';
const language = localStorage.getItem("locale");

const NewSlide = () => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler] = useForm(
    {
      type: {
        value: '',
        isValid: false
      },
      title: {
        value: '',
        isValid: false
      },
      title_fr: {
        value: '',
        isValid: false
      },
      score: {
        value: '',
        isValid: false
      },

      feedback: {
        value: '',
        isValid: true
      },
      feedback_fr: {
        value: '',
        isValid: true
      },
      text: {
        value: '',
        isValid: false
      },
      text_fr: {
        value: '',
        isValid: false
      },
      image: {
        value: null,
        isValid: true
      },
      image_fr: {
        value: null,
        isValid: true
      },
      image_description: {
        value: '',
        isValid: true
      },
      image_description_fr: {
        value: '',
        isValid: true
      },
      start_in_deck: {
        value: false,
        isValid: true
      },
      removable_from_deck: {
        value: false,
        isValid: true
      }
    },
    false
  );

  const history = useHistory();
  const scenarioId = useParams().scenarioId
  const baseUrl = process.env.REACT_APP_API_URL;
  const slideSubmitHandler = async event => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append('type', formState.inputs.type.value);
      formData.append('title', formState.inputs.title.value);
      formData.append('title_fr', formState.inputs.title_fr.value);
      formData.append('score', formState.inputs.score.value);
      formData.append('code', formState.inputs.code.value);
      formData.append('theme', formState.inputs.theme.value);
      formData.append('text', formState.inputs.text.value);
      formData.append('text_fr', formState.inputs.text_fr.value);
      formData.append('feedback', formState.inputs.feedback.value);
      formData.append('feedback_fr', formState.inputs.feedback_fr.value);
      formData.append('start_in_deck', formState.inputs.start_in_deck.value);
      formData.append('removable_from_deck', formState.inputs.removable_from_deck.value);
      formData.append('image', formState.inputs.image.value);
      formData.append('image_fr', formState.inputs.image.value);
      formData.append('image_description', formState.inputs.image_description.value);
      formData.append('image_description_fr', formState.inputs.image_description_fr.value);
      await sendRequest(`${baseUrl}/scenarios/${scenarioId}/slides`, 'POST', formData, {
        Authorization: 'Bearer ' + auth.token
      });

      history.push(`/scenarios/${scenarioId}`);
      toast('Slide added successfully');
    } catch (err) {
      console.log(err)
    }
  };

  const sectionHeaderBtns = [
    { text: 'Go back', link: '/scenarios' }
  ]

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <SectionHeader title="New Slide" btns={sectionHeaderBtns}/>
      <form className="form" onSubmit={slideSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        <h4 className="form-title">
            {staticContent[language].addNewSlide}
        </h4>
        <Input
          id="type"
          element="select"
          label="Type"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please enter a valid type."
          onInput={inputHandler}
          options={[
            {
              label: '',
              value: ''
            },
            {
              label: staticContent[language].background,
              value: 'background'
            },
            {
              label: staticContent[language].data,
              value: 'data'
            },
            {
              label: staticContent[language].insight,
              value: 'insight'
            }
          ]}
        />
        <Input
          id="title"
          element="input"
          type="text"
          label="Title"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please enter a valid title."
          onInput={inputHandler}
        />
        <Input
          id="title_fr"
          element="input"
          type="text"
          label="Title(Fr)"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please enter a valid title."
          onInput={inputHandler}
        />
        <Input
          id="score"
          element="input"
          label="Score"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Score should be a number."
          onInput={inputHandler}
        />
        <Input
          id="code"
          element="input"
          label="Code"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Code should be a number."
          onInput={inputHandler}
        />
        <Input
          id="theme"
          element="input"
          label="Theme"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Theme should be a string."
          onInput={inputHandler}
        />
        <Input
          id="text"
          element="textarea"
          rows={4}
          label="text"
          validators={[VALIDATOR_MINLENGTH(10)]}
          errorText="Please enter a valid description (at least 10 characters)."
          onInput={inputHandler}
        />
        <Input
          id="text_fr"
          element="textarea"
          rows={4}
          label="text(Fr)"
          validators={[VALIDATOR_MINLENGTH(10)]}
          errorText="Please enter a valid description (at least 10 characters)."
          onInput={inputHandler}
        />
        <Input
          id="feedback"
          element="textarea"
          rows={4}
          label="General feedback"
          validators={[]}
          errorText="Please enter a valid feedback (at least 10 characters)."
          onInput={inputHandler}
        />
        <Input
          id="feedback_fr"
          element="textarea"
          rows={4}
          label="General feedback (Fr)"
          validators={[]}
          errorText="Please enter a valid feedback (at least 10 characters)."
          onInput={inputHandler}
        />
        <Input
          id="start_in_deck"
          element="checkbox"
          rows={4}
          label="Start in deck"
          validators={[]}
          errorText="Start in deck is not valid."
          onInput={inputHandler}
        />
        <Input
          id="removable_from_deck"
          element="checkbox"
          rows={4}
          label="Removable from deck"
          validators={[]}
          errorText="Removable from deck is not valid."
          onInput={inputHandler}
        />
        <ImageUpload
          id="image"
          onInput={inputHandler}
          errorText="Please provide an image."
          required={false}
        />
        <Input
          id="image_description"
          element="textarea"
          rows={4}
          label="Image description"
          validators={[VALIDATOR_MINLENGTH(25)]}
          errorText="Please enter a valid description (at least 25 characters)."
          onInput={inputHandler}
          />
        <ImageUpload
          id="image_fr"
          onInput={inputHandler}
          errorText="Please provide the french image."
          required={false}
        />
        <Input
          id="image_description_fr"
          element="textarea"
          rows={4}
          label="Image description (Fr)"
          validators={[VALIDATOR_MINLENGTH(25)]}
          errorText="Please enter a valid description (at least 25 characters)."
          onInput={inputHandler}
          />
        <Button type="submit" disabled={!formState.isValid} fullWidth={true} marginClass='mt-30'>
          Add Slide
        </Button>
      </form>
    </React.Fragment>
  );
};

export default NewSlide;
