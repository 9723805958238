import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {DraggableSyntheticListeners} from '@dnd-kit/core';
import {Transform} from '@dnd-kit/utilities';

import {Handle, Remove} from './components';
import Slide from '../../../Slide';

import styles from './Item.module.css';

import { useDispatch, useSelector } from "react-redux";
import { ExpandState } from "../../../../store/types";
import { Translate } from '../../../../shared/util/translator';
import { slidesActions } from '../../../../store/scenario';

export interface Props {
  dragOverlay?: boolean;
  color?: string;
  disabled?: boolean;
  dragging?: boolean;
  handle?: boolean;
  height?: number;
  index?: number;
  fadeIn?: boolean;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  sorting?: boolean;
  style?: React.CSSProperties;
  transition?: string | null;
  wrapperStyle?: React.CSSProperties;
  value: React.ReactNode;
  showImageLongDescriptionDialog: number;
  onRemove?(): void;
  renderItem?(args: {
    dragOverlay: boolean;
    dragging: boolean;
    sorting: boolean;
    index: number | undefined;
    fadeIn: boolean;
    listeners: DraggableSyntheticListeners;
    ref: React.Ref<HTMLElement>;
    style: React.CSSProperties | undefined;
    transform: Props['transform'];
    transition: Props['transition'];
    value: Props['value'];
    showImageLongDescriptionDialog: Props['showImageLongDescriptionDialog'];

  }): React.ReactElement;
}

export const Item = React.memo(
  React.forwardRef<HTMLLIElement, Props>(
    (
      {
        color,
        showImageLongDescriptionDialog,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        height,
        index,
        listeners,
        onRemove,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        value,
        wrapperStyle,
        ...props
      },
      ref
    ) => {

      handle = false
      let disableHandleClass = ''

      if(JSON.parse(String(value)).type != 'data-separator'){
        handle = true
      }

      if(JSON.parse(String(value)).start_in_deck == true && JSON.parse(String(value)).removable_from_deck == false){
        disableHandleClass = 'disable-handle';
      }

      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = 'grabbing';

        return () => {
          document.body.style.cursor = '';
        };
      }, [dragOverlay]);

      const filtered = JSON.parse(String(value)).filtered;

      const typeClass = JSON.parse(String(value)).type+'-item';
      let typeClassLi = JSON.parse(String(value)).type;
      if(typeClassLi.includes('separator')){
        typeClassLi = typeClassLi + ' separator-li'
      }

      let rejectedClass = '';
      if(JSON.parse(String(value)).rejected){
        rejectedClass = ' rejected-item'
      }

      const [zoomed, setZoomed] = useState(false)
      const dispatch = useDispatch();

      const onZoomClicked = () => {
        dispatch(slidesActions.toggleSlideZoomActive())
        setZoomed((prevState) => {
          return !prevState
        })
      }

      const handleKeyDown = (event: any) => {
        console.log('A key was pressed', event.keyCode);
        
        if(event.keyCode === 27 && zoomed){
          setZoomed((prevState) => {
            return !prevState
          })
        }
      };
    
      useEffect(() => {
        if(!zoomed) {
          return 
        }
        window.addEventListener('keydown', handleKeyDown);
    
        // cleanup this component
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, [zoomed]);


      let zoomedClass = '';
      if(zoomed){
        zoomedClass = 'zoomed'
      }

      const isSeperator = ['background-separator', 'data-separator', 'insight-separator'].includes(JSON.parse(String(value)).type)

      const expandedContainer = useSelector(
        (state: any) => state.expander.expandedContainer
      );
      if(expandedContainer == 'bankContainer'){
        wrapperStyle = {
          ...wrapperStyle,
          height: isSeperator ? 50 : 460
        }
      }



      return renderItem ? (
        renderItem({
          dragOverlay: Boolean(dragOverlay),
          dragging: Boolean(dragging),
          sorting: Boolean(sorting),
          index,
          fadeIn: Boolean(fadeIn),
          listeners,
          ref,
          style,
          transform,
          transition,
          value,
          showImageLongDescriptionDialog,
        })
      ) : (
          <>
          { zoomed && <div className="slide-backdrop"></div>}
          <li
            className={classNames(
              styles.Wrapper,
              fadeIn && styles.fadeIn,
              sorting && styles.sorting,
              dragOverlay && styles.dragOverlay,
              'item-wrapper',
              filtered && 'hide',
              typeClassLi,
              rejectedClass,
              zoomedClass
            )}
            style={
              {
                ...wrapperStyle,
                transition,
                '--translate-x': transform
                  ? `${Math.round(transform.x)}px`
                  : undefined,
                '--translate-y': transform
                  ? `${Math.round(transform.y)}px`
                  : undefined,
                '--scale-x': transform?.scaleX
                  ? `${transform.scaleX}`
                  : undefined,
                // '--scale-y': transform?.scaleY
                //   ? `${transform.scaleY}`
                //   : undefined,
                '--index': index,
                '--color': color,
              } as React.CSSProperties
            }
            ref={ref}
            // data-tabNumber={index}
          >
            <div
              className={classNames(
                styles.Item,
                dragging && styles.dragging,
                handle && styles.withHandle,
                dragOverlay && styles.dragOverlay,
                disabled && styles.disabled,
                color && styles.color,
                'item',
                dragging && 'item-dragging',
                typeClass,
                filtered && 'hide'
              )}
              style={style}
              data-cypress="draggable-item"
              {...undefined}
              {...props}
              // tabIndex={!handle ? 0 : undefined}
            >
              {handle ? <Handle tabIndex={1} {...( disableHandleClass != 'disable-handle' ? listeners : undefined)} className={`drag-handle ${disableHandleClass}`} /> : null}
              
              <Slide 
                key={JSON.parse(String(value)).id}
                image={Translate(JSON.parse(String(value)), 'image')}
                image_description={Translate(JSON.parse(String(value)), 'image_description')}
                table={Translate(JSON.parse(String(value)), 'table')}
                title={Translate(JSON.parse(String(value)), 'title')}
                text={Translate(JSON.parse(String(value)), 'text')}
                type={JSON.parse(String(value)).type}
                rejected={JSON.parse(String(value)).rejected}
                theme={Translate(JSON.parse(String(value)), 'theme')}
                id={ JSON.parse(String(value)).id }
                onZoomClicked={onZoomClicked}
                showImageLongDescriptionDialog={showImageLongDescriptionDialog}
                zoomed={zoomed}
                tags={true}
                unSelectBtn={disableHandleClass == 'disable-handle' ? false : true}
                footer={true}
                imageDescriptionBtn={true}
                ></Slide>
              <span className={styles.Actions}>
                {onRemove ? (
                  <Remove className={styles.Remove} onClick={onRemove} />
                ) : null}
                {/* {handle ? <Handle {...listeners} className="drag-handle"/> : null} */}
              </span>
            </div>
          </li>
        </>
      );
    }
  )
);
