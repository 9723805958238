import { createSlice } from '@reduxjs/toolkit';

const expanderSlice = createSlice({
    name: 'expander',
    initialState: { expandedContainer: 'bankContainer' },
    reducers: { 
        toggleExpand(state){
            if(state.expandedContainer === 'bankContainer'){
                return { expandedContainer: 'selectedContainer' }
            }else{
                return { expandedContainer: 'bankContainer' }
            }
        }
    }
});

export const expanderActions = expanderSlice.actions;

export default expanderSlice.reducer;
