import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
import Card from '../../shared/components/UIElements/Card';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
  VALIDATOR_MAXLENGTH
} from '../../shared/util/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import ImageUpload from "../../shared/components/FormElements/ImageUpload";
import { toast } from "react-toastify";
import { staticContent } from '../../shared/data/dictionary';
import SectionHeader from '../../panel/components/SectionHeader';

const language = localStorage.getItem("locale");

const Account = () => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [userSetting, setUserSetting] = useState();
  const userId = auth.userId;
  const history = useHistory();

  const [formState, inputHandler, setFormData] = useForm(
    {
      name: {
        value: '',
        isValid: true
      },
      email: {
        value: '',
        isValid: true
      },
      image: {
        value: '',
        isValid: true
      }
    },
    true
  );
  const baseUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchuser = async () => {
      try {
        const responseData = await sendRequest(
          `${baseUrl}/users/account`, "GET", null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setUserSetting(responseData);
        console.log(responseData);
      } catch (err) {}
    };
    fetchuser();
  }, [sendRequest, userId, setFormData]);

  const submitHandler = async event => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", formState.inputs.name.value);
    formData.append("email", formState.inputs.email.value);
    formData.append("image", formState.inputs.image ? formState.inputs.image.value : null);
    try {
      const response = await sendRequest(
        `${baseUrl}/users`,
        'PATCH',
        formData,
        {
          'Authorization': 'Bearer ' + auth.token
        }
      );
      history.push('/scenarios');
      toast('Updated successfully');
      if(formState.inputs.image){
        console.log("response", response);
        // auth.updateAuthImage(response.image)
      }
    } catch (err) {
      console.log(err)
    }
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!userSetting && !error) {
    return (
      <div className="center">
        <Card>
          <h2>Could not find user!</h2>
        </Card>
      </div>
    );
  }

  return (
    <React.Fragment>
      <SectionHeader title="Account setting" backBtn={true}/>
      <ErrorModal error={error} onClear={clearError} />
      <br/>
      {!isLoading && userSetting && (
        <form className="form" onSubmit={submitHandler}>
        <h4 className="form-title">{staticContent[language]?.accountFormTitle}</h4>
        {isLoading && <LoadingSpinner asOverlay />}
        <Input
          id="name"
          element="input"
          type="text"
          label={staticContent[language]?.name}
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please enter a valid name."
          onInput={inputHandler}
          initialValue={userSetting.name}
          initialValid={true}
        />
        <Input
          id="email"
          element="input"
          label={staticContent[language]?.email}
          validators={[VALIDATOR_MINLENGTH(5)]}
          errorText="Please enter a valid email address."
          onInput={inputHandler}
          initialValue={userSetting.email}
          initialValid={true}
        />
        <ImageUpload
          id="image"
          onInput={inputHandler}
          errorText="Image upload"
          previewUrl={userSetting.image}
          label={staticContent[language]?.profile_image}
          required={false}
        />
        <hr />
          <Button
            type="submit"
            disabled={!formState.isValid}
            fullWidth={true}
            marginClass="mt-30"
          >
          {staticContent[language].update}
        </Button>
      </form>
      )}
    </React.Fragment>
  );
};

export default Account;
