import React, { useEffect, useState, useContext } from 'react';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

import ScenariosListPlayer from '../components/ScenariosListPlayer';
import { staticContent, language } from '../../shared/data/dictionary'

const ScenariosPlayer = () => {
  const classHttp = useHttpClient();
  const scenarioHttp = useHttpClient();
  // const { scenariosIsLoading, scenarioRrror, sendRequestScenario, scenarioClearError } = useHttpClient();
  const [loadedScenarios, setLoadedScenarios] = useState();
  const auth = useContext(AuthContext);
  const baseUrl = process.env.REACT_APP_API_URL;
  
  useEffect(() => {

    const fetchClasses = async () => {
      try {
        const responseData = await classHttp.sendRequest(`${baseUrl}/scenarios`, 'GET', null, {
          Authorization: 'Bearer ' + auth.token
        });
        setLoadedScenarios(responseData);
      } catch (err) {
        console.log("fetchClasses err", err)
      }
    };
    fetchClasses();

  }, [classHttp.sendRequest]);


  return (
    <React.Fragment>
      <ErrorModal error={classHttp.error} onClear={classHttp.clearError} />
      <ErrorModal error={scenarioHttp.error} onClear={scenarioHttp.clearError} />
      {classHttp.isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {/* <h5>{staticContent[language]?.scenarios}</h5> */}
      {!scenarioHttp.isLoading && loadedScenarios && <ScenariosListPlayer items={loadedScenarios} />}
      
    </React.Fragment>
  );
};

export default ScenariosPlayer;
