import React, { useEffect, useState, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

import ScenarioStats from "../components/ScenarioStats";
import PlaysList from "../components/PlaysList";
import { Button } from "antd";
import PlayersList from "../../class/components/PlayersList";
import { staticContent, language } from "../../shared/data/dictionary";

const ScenarioDashboard = () => {
  const http = useHttpClient();
  const [dashboardData, setDashboardData] = useState();
  const auth = useContext(AuthContext);

  const scenarioId = useParams().scenarioid;
  const classId = useParams().classid;
  const baseUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchScenarioDashboard = async () => {
      try {
        const url = `${baseUrl}/scenarios/${scenarioId}/${classId}/dashboard`;
        const res = await http.sendRequest(url, "GET", null, {
          Authorization: "Bearer " + auth.token,
        });
        setDashboardData(res);
      } catch (err) {
        console.log("fetchScenarioDashboard err", err);
      }
    };
    fetchScenarioDashboard();
  }, [http.sendRequest]);

  return (
    <React.Fragment>
      <ErrorModal error={http.error} onClear={http.clearError} />

      {http.isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}

      {!http.isLoading && dashboardData && (
        <React.Fragment>
          <div className="section-header">
            <h1>
              {dashboardData.scenarioTitle}
              <p className="sub-headline">{dashboardData.classTitle}</p>
            </h1>
            <div className="btns">
              <Button type="default">
                <Link to="/scenarios">{staticContent[language]?.goBack}</Link>
              </Button>
              <Button type="default">
                <Link to={`/scenarios/${scenarioId}/${classId}/setting`}>
                  {staticContent[language]?.scenarioSetting}
                </Link>
              </Button>
              <Button type="default" className="btn-dark">
                <Link to={`/scenarios/${scenarioId}/${classId}/plays`}>{staticContent[language].studentsList}</Link>
              </Button>
            </div>
          </div>

          <ScenarioStats stats={dashboardData.stats}></ScenarioStats>

          <h5 className="">{staticContent[language]?.top_scoring_players}</h5>
          <PlaysList items={dashboardData.topPlays} reviewBtn={true} pagination={false} showPlayerName={true} showScore={true} reviewBtn={true}/>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ScenarioDashboard;
