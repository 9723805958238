import {
  closestCorners,
  getViewRect,
  getScrollableAncestors,
  KeyboardCode,
  DroppableContainer,
  KeyboardCoordinateGetter,
} from "@dnd-kit/core";

const directions: string[] = [
  KeyboardCode.Down,
  KeyboardCode.Right,
  KeyboardCode.Up,
  KeyboardCode.Left,
];

// function sleep(ms: number) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }

// function moveToSelected() {
//   const selected_container = document.getElementById("selectedContainer");
//   if (selected_container) {
//     selected_container.scrollTop = 0;

//     // var promise = new Promise(function(resolve, reject) {
//     //   setTimeout(function() {
//     //     if(selected_container.scrollTop == 0){
//     //       var rect = selected_container.getBoundingClientRect();
//     //       console.log(rect.top + 20, rect.right, rect.bottom, rect.left);

//     //       const newCoordinates = {
//     //         x: rect.left + 20,
//     //         y: rect.top + 20,
//     //       };
//     //       resolve(newCoordinates);
//     //     }
//     //   }, 3000);
//     // });

//     if (selected_container.scrollTop == 0) {
//       var rect = selected_container.getBoundingClientRect();
//       console.log(rect.top + 20, rect.right, rect.bottom, rect.left);

//       const newCoordinates = {
//         x: rect.left + 20,
//         y: rect.top + 20,
//       };
//       return newCoordinates;
//     }
//   }
// }

export const namanKeyboardCoordinates: KeyboardCoordinateGetter = (
  event,
  {
    context: {
      active,
      droppableContainers,
      translatedRect,
      scrollableAncestors,
    },
  }
) => {
  // move an item to selected container
  // if (event.code === "KeyS") {
  //   // alert("hello");
  //   const selected_container = document.getElementById("selectedContainer");
  //   if (selected_container) {
  //     selected_container.scrollTop = 0;
  //     if (selected_container.scrollTop == 0) {
  //       var rect = selected_container.getBoundingClientRect();
  //       console.log(rect.top + 20, rect.right, rect.bottom, rect.left);

  //       const newCoordinates = {
  //         x: rect.left + 50,
  //         y: rect.top + 20,
  //       };
        

  //       let audio = new Audio("/assets/audio/UI_Quirky_48.mp3");
  //       audio.play();

  //       return newCoordinates;
  //     }
  //   }
  // }

  // move an from item to bank container
  if (event.code === "KeyD") {
    const selected_container = document.getElementById("bankContainer");
    if (selected_container) {
      var rect = selected_container.getBoundingClientRect();
      var height = selected_container.offsetHeight;
      console.log(rect.top + 20, rect.right, rect.bottom, rect.left, height);

      const newCoordinates = {
        x: rect.left + 20,
        y: rect.bottom - 20,
      };
      return newCoordinates;
    }
  }

  if (directions.includes(event.code)) {
    event.preventDefault();

    if (!active || !translatedRect) {
      return;
    }

    const filteredContainers: DroppableContainer[] = [];

    droppableContainers.getEnabled().forEach((entry) => {
      if (!entry || entry?.disabled) {
        return;
      }

      const node = entry?.node.current;

      if (!node) {
        return;
      }

      const rect = getViewRect(node);
      const container: DroppableContainer = {
        ...entry,
        rect: {
          current: rect,
        },
      };

      switch (event.code) {
        case KeyboardCode.Down:
          if (translatedRect.top + translatedRect.height <= rect.top) {
            filteredContainers.push(container);
          }
          break;
        case KeyboardCode.Up:
          if (translatedRect.top >= rect.top + rect.height) {
            filteredContainers.push(container);
          }
          break;
        case KeyboardCode.Left:
          if (translatedRect.left >= rect.left + rect.width) {
            filteredContainers.push(container);
          }
          break;
        case KeyboardCode.Right:
          if (translatedRect.left + translatedRect.width <= rect.left) {
            filteredContainers.push(container);
          }
          break;
      }
    });

    console.log("filteredContainers");
    console.log(filteredContainers);

    const closestId = closestCorners({
      active,
      collisionRect: translatedRect,
      droppableContainers: filteredContainers,
    });

    if (closestId) {
      const newNode = droppableContainers.get(closestId)?.node.current;

      if (newNode) {
        const newScrollAncestors = getScrollableAncestors(newNode);
        const hasDifferentScrollAncestors = newScrollAncestors.some(
          (element, index) => scrollableAncestors[index] !== element
        );
        const newRect = getViewRect(newNode);
        const offset = hasDifferentScrollAncestors
          ? {
              x: 0,
              y: 0,
            }
          : {
              x: translatedRect.width - newRect.width,
              y: translatedRect.height - newRect.height,
            };
        const newCoordinates = {
          x: newRect.left - offset.x,
          y: newRect.top - offset.y,
        };

        return newCoordinates;
      }
    }
  }

  return undefined;
};
