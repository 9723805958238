import "./Container.css";

const Container = ({children, variant}) => {

    return <div className="container" style={ { maxWidth:  variant != 'dark' ? 'none' : ''} }>
        {children}
    </div>
    
}

export default Container;