import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
import Card from '../../shared/components/UIElements/Card';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
  VALIDATOR_MAXLENGTH
} from '../../shared/util/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import ImageUpload from "../../shared/components/FormElements/ImageUpload";
import { toast } from "react-toastify";
import { staticContent } from '../../shared/data/dictionary'
import SectionHeader from '../../panel/components/SectionHeader';
const language = localStorage.getItem("locale");

const UpdateSlide = () => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [slide, setSlide] = useState();
  const [loadedCodes, setLoadedCodes] = useState();
  const slideId = useParams().slideId;
  const history = useHistory();
  const baseUrl = process.env.REACT_APP_API_URL;
  const [formState, inputHandler, setFormData] = useForm();

  useEffect(() => {

    // get codes
    const fetchCodes = async (scenarioId) => {
      try {
        const responseData = await sendRequest(
          `${baseUrl}/scenarios/${scenarioId}/codes`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        const codesData = responseData.codes.map(each => {
          return {
            label: each.code,
            value: each.code,
          }
        })
        setLoadedCodes(codesData);
      } catch (err) {
        console.log(err)
      }
    };

    const fetchSlide = async () => {
      try {
        const slideResponse = await sendRequest(
          `${baseUrl}/scenarios/slides/${slideId}`, "GET", null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setSlide(slideResponse);
        fetchCodes(slideResponse.scenario);
      } catch (err) {
        // console.log(err);
      }
    };
    fetchSlide();


  }, [sendRequest, slideId, setFormData]);

  const slideSubmitHandler = async event => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append('type', formState.inputs.type.value);
      formData.append('title', formState.inputs.title.value);
      formData.append('title_fr', formState.inputs.title_fr.value);
      formData.append('score', formState.inputs.score.value);
      formData.append('code', formState.inputs.code.value);
      formData.append('theme', formState.inputs.theme.value);
      formData.append('theme_fr', formState.inputs.theme_fr.value);
      formData.append('text', formState.inputs.text.value);
      formData.append('text_fr', formState.inputs.text_fr.value);
      formData.append('feedback', formState.inputs.feedback.value);
      formData.append('feedback_fr', formState.inputs.feedback_fr.value);
      formData.append('start_in_deck', formState.inputs.start_in_deck.value);
      formData.append('removable_from_deck', formState.inputs.removable_from_deck.value);
      formData.append('table', formState.inputs.table.value);
      formData.append('table_fr', formState.inputs.table_fr.value);
      formData.append('image', formState.inputs.image ? formState.inputs.image.value : null);
      formData.append('image_fr', formState.inputs.image_fr ? formState.inputs.image_fr.value : null);
      formData.append('image_description', formState.inputs.image_description.value);
      formData.append('image_description_fr', formState.inputs.image_description_fr.value);
      // formData.append('deck', formState.inputs.deck.value);
      await sendRequest(`${baseUrl}/scenarios/slides/${slideId}`, 'PATCH', formData, {
        Authorization: 'Bearer ' + auth.token
      });
      history.push(`/scenarios/${slide.scenario}`);
    } catch (err) {
      console.log(err)
    }
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!slide && !error) {
    return (
      <div className="center">
        <Card>
          <h2>Could not find scenario!</h2>
        </Card>
      </div>
    );
  }

  return (
    <React.Fragment>
      <SectionHeader title="Update slide" backBtn={true}/>
      <ErrorModal error={error} onClear={clearError} />
      {!isLoading && slide && loadedCodes && (
        <form className="form" onSubmit={slideSubmitHandler}>
            {isLoading && <LoadingSpinner asOverlay />}
            <h4 className="form-title">
                Update a slide
            </h4>
            <Input
              id="type"
              element="select"
              label="Type"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Please enter a valid type."
              onInput={inputHandler}
              options={[
                {
                  label: '',
                  value: ''
                },
                {
                  label: staticContent[language].background,
                  value: 'background'
                },
                {
                  label: staticContent[language].data,
                  value: 'data'
                },
                {
                  label: staticContent[language].insight,
                  value: 'insight'
                }
              ]}
              initialValue={slide.type}
              initialValid={true}
            />
            <Input
            id="title"
            element="input"
            type="text"
            label="Title"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter a valid title."
            onInput={inputHandler}
            initialValue={slide.title}
            initialValid={true}
            />
            <Input
            id="title_fr"
            element="input"
            type="text"
            label="Title(Fr)"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter a valid title."
            onInput={inputHandler}
            initialValue={slide.fr?.title}
            initialValid={true}
            />
            <Input
            id="score"
            element="input"
            label="Score"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Score should be a number."
            onInput={inputHandler}
            initialValue={slide.score}
            initialValid={true}
            />
            <Input
              id="code"
              element="select"
              label="Code"
              validators={[]}
              errorText="Please select a code."
              onInput={inputHandler}
              options={[{label: 'Select a code', value: ''}, ...loadedCodes]}
              initialValue={slide.code}
              initialValid={true}
            />
            <Input
            id="theme"
            element="input"
            label="Theme"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Theme should be a string."
            onInput={inputHandler}
            initialValue={slide.theme}
            initialValid={true}
            />
            <Input
            id="theme_fr"
            element="input"
            label="Theme(Fr)"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Theme should be a string."
            onInput={inputHandler}
            initialValue={slide.fr?.theme}
            initialValid={true}
            />
            <Input
            id="text"
            element="textarea"
            rows={4}
            label="text"
            validators={[VALIDATOR_MINLENGTH(25)]}
            errorText="Please enter a valid description (at least 25 characters)."
            onInput={inputHandler}
            initialValue={slide.text}
            initialValid={true}
            />
            <Input
            id="text_fr"
            element="textarea"
            rows={4}
            label="text(Fr)"
            validators={[VALIDATOR_MINLENGTH(25)]}
            errorText="Please enter a valid description (at least 25 characters)."
            onInput={inputHandler}
            initialValue={slide.fr?.text}
            initialValid={true}
            />
            <Input
            id="feedback"
            element="textarea"
            rows={4}
            label="General feedback"
            validators={[VALIDATOR_MINLENGTH(25)]}
            errorText="Please enter a valid feedback (at least 25 characters)."
            onInput={inputHandler}
            initialValue={slide.feedback}
            initialValid={true}
            />
            <Input
            id="feedback_fr"
            element="textarea"
            rows={4}
            label="General feedback (Fr)"
            validators={[VALIDATOR_MINLENGTH(25)]}
            errorText="Please enter a valid feedback (at least 25 characters)."
            onInput={inputHandler}
            initialValue={slide.fr?.feedback}
            initialValid={true}
            />
            <hr />
            <Input
              id="start_in_deck"
              element="checkbox"
              label="Start in deck"
              validators={[]}
              errorText="Start in deck is not valid."
              onInput={inputHandler}
              initialValue={slide.start_in_deck}
              initialValid={true}
            />
            <Input
              id="removable_from_deck"
              element="checkbox"
              label="Removable from deck"
              validators={[]}
              errorText="Removable from deck is not valid."
              onInput={inputHandler}
              initialValue={slide.removable_from_deck}
              initialValid={true}
            />
            <hr />
            <Input
            id="table"
            element="textarea"
            rows={4}
            label="Table data"
            validators={[VALIDATOR_MINLENGTH(25)]}
            errorText="Please enter a valid feedback (at least 25 characters)."
            onInput={inputHandler}
            initialValue={slide.table}
            initialValid={true}
            guide="Separate columns with commas, and rows with a new line. The first line is used for header."
            />
            <Input
            id="table_fr"
            element="textarea"
            rows={4}
            label="Table data (Fr)"
            validators={[VALIDATOR_MINLENGTH(25)]}
            errorText="Please enter a valid feedback (at least 25 characters)."
            onInput={inputHandler}
            initialValue={slide.fr?.table}
            initialValid={true}
            guide="Separate columns with commas, and rows with a new line. The first line is used for header."
            />
            <ImageUpload
            id="image"
            onInput={inputHandler}
            errorText="Please provide an image."
            required={false}
            previewUrl={slide.image}
            />
            <Input
            id="image_description"
            element="textarea"
            rows={4}
            label="Image description"
            validators={[VALIDATOR_MINLENGTH(25)]}
            errorText="Please enter a valid description (at least 25 characters)."
            onInput={inputHandler}
            initialValue={slide.image_description}
            initialValid={true}
            />
            <ImageUpload
            id="image_fr"
            onInput={inputHandler}
            errorText="Please provide the french image."
            required={false}
            previewUrl={slide.fr?.image}
            />
            <Input
            id="image_description_fr"
            element="textarea"
            rows={4}
            label="Image description(Fr)"
            validators={[VALIDATOR_MINLENGTH(25)]}
            errorText="Please enter a valid description (at least 25 characters)."
            onInput={inputHandler}
            initialValue={slide.fr?.image_description}
            initialValid={true}
            />
            <Button type="submit" disabled={!formState.isValid} fullWidth={true} marginClass='mt-30'>
            Update Slide
            </Button>
        </form>
      )}
    </React.Fragment>
  );
};

export default UpdateSlide;
