import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import CombosList from "../components/CombosList";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import SectionHeader from "../../panel/components/SectionHeader";
import { useHttpClient } from "../../shared/hooks/http-hook";
import "./Combos.css";
import { AuthContext } from "../../shared/context/auth-context";
import scenario from "../../store/scenario";
import { Table, Button, Space, Modal, Row, Col, Popconfirm } from "antd";
import CreateCombo from "../components/CreateCombo";
import CreateCode from "../components/CreateCode";
import { toast } from "react-toastify";
import {
  DeleteOutlined
} from '@ant-design/icons';


const EditCombos = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const deleteHttp = useHttpClient();
  const [loadedScenarioTitle, setLoadedScenarioTitle] = useState();
  const [loadedCodes, setLoadedCodes] = useState();
  const [loadedCombos, setLoadedCombos] = useState();
  const auth = useContext(AuthContext);
  const baseUrl = process.env.REACT_APP_API_URL;
  const scenarioId = useParams().scenarioId;
  const [isComboModalVisible, setIsComboModalVisible] = useState(false);
  const [isCodeModalVisible, setIsCodeModalVisible] = useState(false);

  const showComboModal = () => {
    setIsComboModalVisible(true);
  };

  const handleComboOk = () => {
    setIsComboModalVisible(false);
  };
  const handleComboCancel = () => {
    setIsComboModalVisible(false);
  };

  const updateCombos = (combo) => {
    setLoadedCombos(prev => {
      return [ ...prev, combo ]
    })
  }

  const showCodeModal = () => {
    setIsCodeModalVisible(true);
  };

  const handleCodeOk = () => {
    setIsCodeModalVisible(false);
  };

  const handleCodeCancel = () => {
    setIsCodeModalVisible(false);
  };

  const updateCodes = (code) => {
    setLoadedCodes(prev => {
      return [...prev, {key: code.code, code: code.code, description: code.description}]
    })
  }

  const deleteCode = (code) => {
    const fetchDeleteCode = async () => {
      try {
        const responseData = await deleteHttp.sendRequest(
          `${baseUrl}/scenarios/${scenarioId}/codes/${code}`,
          "DELETE",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setLoadedCodes(prev => {
          return prev.filter(function(el) { return el.code != code; });
        });
        setLoadedCombos(prev => {
          return prev.filter(function(el) { 
            return el.code1 != code && el.code2 != code;
           });
        });
        toast('Code is deleted successfully');
      } catch (err) {}
    };
    fetchDeleteCode();
  }

  const deleteCombo = (comboId) => {
    const fetchDeleteCombo = async () => {
      try {
        await deleteHttp.sendRequest(
          `${baseUrl}/scenarios/${scenarioId}/combos/${comboId}`,
          "DELETE",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setLoadedCombos(prev => {
          return prev.filter(function(el) { return el.key != comboId; });
        });
        toast('Combo is deleted successfully');
      } catch (err) {}
    };
    fetchDeleteCombo();
  }

  const codesColumns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      // width: "90%",
      render: (text) => text,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      // width: "90%",
      render: (text) => text,
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm title="Are you sure you want to delete this resource?" onConfirm={() => { deleteCode(record.code) }} okText="Yes" cancelText="No">
            <Button type="text" icon={<DeleteOutlined />}></Button>
          </Popconfirm>
        </Space>
      ),
    }
  ]

  const combosColumns = [
    {
      title: "Code",
      dataIndex: "code1",
      key: "code1",
      width: "10%",
      render: (text) => text,
    },
    {
      title: "Code",
      dataIndex: "code2",
      key: "code2",
      width: "10%",
      render: (text) => text,
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      width: "10%",
      render: (text) => text,
    },
    {
      title: "Feedback",
      dataIndex: "feedback",
      key: "feedback",
      // width: "30%",
      render: (text) => text,
    },
    {
      title: "Feedback(Fr)",
      dataIndex: "feedback_fr",
      key: "feedback_fr",
      // width: "30%",
      render: (text) => text,
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm title="Are you sure you want to delete this resource?" onConfirm={() => deleteCombo(record.key) } okText="Yes" cancelText="No">
            <Button type="text" icon={<DeleteOutlined />}></Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    // get scenario with slides
    const fetchScenario = async () => {
      try {
        const responseData = await sendRequest(
          `${baseUrl}/scenarios/${scenarioId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setLoadedScenarioTitle(responseData.scenario.title);
      } catch (err) {}
    };
    fetchScenario();


    // get codes
    const fetchCodes = async () => {
      try {
        const responseData = await sendRequest(
          `${baseUrl}/scenarios/${scenarioId}/codes`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        const codesData = responseData.codes.map(code => {
          return {
            key: code._id,
            code: code.code,
            description: code.description,
            feedback: code.feedback,

          }
        })
        setLoadedCodes(codesData);
      } catch (err) {}
    };
    fetchCodes();

    // get Combos
    const fetchCombos = async () => {
      try {
        const responseData = await sendRequest(
          `${baseUrl}/scenarios/${scenarioId}/combos`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        const CombosData = responseData.map(combo => {
          return {
            key: combo._id,
            code1: combo.code1,
            code2: combo.code2,
            score: combo.score,
            feedback: combo.feedback,
            feedback_fr: combo.fr?.feedback,
          }
        })
        setLoadedCombos(CombosData);
      } catch (err) {}
    };
    fetchCombos();

  }, [sendRequest]);

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {!isLoading && loadedCodes && (
        <React.Fragment>
          <SectionHeader title="Edit Combos" subtitle={loadedScenarioTitle} backBtn={true}/>
          <Modal
            title="Add new combo"
            visible={isComboModalVisible}
            footer={false}
            onCancel={handleComboCancel}
          >
            {/* <CreateCombo scenarioId={12} codes={loadedCodes.map(code => code.code)}/> */}
            <CreateCombo scenarioId={scenarioId} codes={loadedCodes} 
                         handleComboCancel={handleComboCancel} 
                         updateCombos={updateCombos} />
          </Modal>

          <Modal
            title="Add new code"
            visible={isCodeModalVisible}
            footer={false}
            onCancel={handleCodeCancel}
          >
            <CreateCode scenarioId={scenarioId} handleCodeCancel={handleCodeCancel} 
            updateCodes={updateCodes}/>
          </Modal>
          <hr /><br />
          <Row gutter={[34, 34]}>
            <Col lg={{ span: 24}} sm={{ span: 24}} >
              <div className="panel-header">
                <h5>Codes</h5>
                <Button type="primary" size={'small'} onClick={showCodeModal}>
                  Add a new code
                </Button>
              </div>
              <Table
                columns={codesColumns}
                dataSource={loadedCodes}
                className="border-gray"
                pagination={false}
              />
            </Col>
            <Col lg={{ span: 24}} sm={{ span: 24}}>
              <div className="panel-header">
                <h5>Combos</h5>
                <Button type="primary" size={'small'} onClick={showComboModal} disabled={loadedCodes.length > 1 ? false : true}>
                  Add a new combo
                </Button>
              </div>
              <Table
                columns={combosColumns}
                dataSource={loadedCombos}
                className="border-gray"
                pagination={false}
              />
            </Col>
          </Row>
          
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default EditCombos;
