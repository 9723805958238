import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ImageUpload from "../../shared/components/FormElements/ImageUpload";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
  VALIDATOR_MAXLENGTH,
} from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import "./Form.css";
import { Row, Col } from "antd";
import SectionHeader from '../../panel/components/SectionHeader';


const NewScenario = () => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler] = useForm(
    {
      title: {
        value: "",
        isValid: false,
      },
      title_fr: {
        value: "",
        isValid: false,
      },
      description: {
        value: "",
        isValid: false,
      },
      description_fr: {
        value: "",
        isValid: false,
      },
      image: {
        value: null,
        isValid: false,
      },
    },
    false
  );
  const baseUrl = process.env.REACT_APP_API_URL;
  const history = useHistory();

  const scenarioSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("title", formState.inputs.title.value);
      formData.append("description", formState.inputs.description.value);
      formData.append("image", formState.inputs.image.value);
      formData.append("title_fr", formState.inputs.title_fr.value);
      formData.append("description_fr", formState.inputs.description_fr.value);
      formData.append("max_score", formState.inputs.max_score.value);
      formData.append("full_score", formState.inputs.full_score.value);
      formData.append("min_passing", formState.inputs.min_passing.value);
      formData.append("submit_max", formState.inputs.submit_max.value);
      formData.append("resubmit_penalty", formState.inputs.resubmit_penalty.value);
      formData.append("score_feedback_max", formState.inputs.score_feedback_max.value);
      formData.append("score_feedback_penalty", formState.inputs.score_feedback_penalty.value);
      formData.append("quick_feedback_max", formState.inputs.quick_feedback_max.value);
      formData.append("quick_feedback_penalty", formState.inputs.quick_feedback_penalty.value);
      formData.append("max_background", formState.inputs.max_background.value);
      formData.append("max_data", formState.inputs.max_data.value);
      formData.append("max_insight", formState.inputs.max_insight.value);
      await sendRequest(`${baseUrl}/scenarios`, "POST", formData, {
        Authorization: "Bearer " + auth.token,
      });
      history.push("/scenarios");
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
         
      <SectionHeader title="Create new scenario" backBtn={true}/>
      <form className="form" onSubmit={scenarioSubmitHandler}>
        <h4 className="form-title">Create a new scenario</h4>
        {isLoading && <LoadingSpinner asOverlay />}
        <Input
          id="title"
          element="input"
          type="text"
          label="Title"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please enter a valid title."
          onInput={inputHandler}
        />
        <Input
          id="title_fr"
          element="input"
          type="text"
          label="Title(Fr)"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please enter a valid title."
          onInput={inputHandler}
        />
        <Input
          id="description"
          element="textarea"
          label="Description"
          validators={[VALIDATOR_MINLENGTH(5)]}
          errorText="Please enter a valid description (at least 5 characters)."
          onInput={inputHandler}
        />
        <Input
          id="description_fr"
          element="textarea"
          label="Description(Fr)"
          validators={[VALIDATOR_MINLENGTH(5)]}
          errorText="Please enter a valid description (at least 5 characters)."
          onInput={inputHandler}
        />
        <ImageUpload
          id="image"
          onInput={inputHandler}
          errorText="Image upload"
        />
        <hr />
        <Row gutter={16}>
          <Col span={8}>
            <Input
              id="max_score"
              element="input"
              label="Maximum score"
              validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
              errorText="Please enter a valid number for maximum score."
              onInput={inputHandler}
            />
          </Col>
          <Col span={8}>
            <Input
              id="full_score"
              element="input"
              label="Full mark score"
              guide="Recommended by our team"
              validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
              errorText="Please enter a valid number for full score."
              onInput={inputHandler}
            />
          </Col>
          <Col span={8}>
            <Input
              id="min_passing"
              element="input"
              label="Minimum passing"
              guide="Recommended by our team"
              validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
              errorText="Please enter a valid minimum passing."
              onInput={inputHandler}
            />
          </Col>
        </Row>
        <hr />
        <Row gutter={16}>
          <Col span={10}>
            <Input
              id="submit_max"
              element="input"
              label="Maximum number of submissions"
              validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(2)]}
              errorText="Please enter a valid integer number for retry"
              onInput={inputHandler}
            />
          </Col>
          <Col span={10}>
            <Input
              id="resubmit_penalty"
              element="input"
              label="Resubmit penalty"
              validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
              errorText="Please enter a valid retry penalty, it should be negative number"
              onInput={inputHandler}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={10}>
            <Input
              id="score_feedback_max"
              element="input"
              label="Maximum number of score range feedback"
              validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(2)]}
              errorText="Please enter a valid integer number for quick"
              onInput={inputHandler}
            />
          </Col>
          <Col span={10}>
            <Input
              id="score_feedback_penalty"
              element="input"
              label="Score range feedback penalty"
              validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
              errorText="Please enter a valid quick feedback penalty, it should be 0 or a negative number"
              onInput={inputHandler}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={10}>
            <Input
              id="quick_feedback_max"
              element="input"
              label="Maximum number of quick feedback"
              validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(2)]}
              errorText="Please enter a valid integer number for quick"
              onInput={inputHandler}
            />
          </Col>
          <Col span={10}>
            <Input
              id="quick_feedback_penalty"
              element="input"
              label="Quick feedback penalty"
              validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
              errorText="Please enter a valid quick feedback penalty, it should be 0 or a negative number"
              onInput={inputHandler}
            />
          </Col>
        </Row>
        <br /> <hr /> <br />
        <h5 className="no-margin">Maximum number allowed for deck slide</h5>
        <Row gutter={16}>
          <Col span={8}>
            <Input
              id="max_background"
              element="input"
              label="Background slides"
              validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
              errorText="Please enter a valid quick feedback penalty, it should be 0 or a negative number"
              onInput={inputHandler}
              initialValid={true}
            />
          </Col>
          <Col span={8}>
            <Input
              id="max_data"
              element="input"
              label="Data slides"
              validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
              errorText="Please enter a valid quick feedback penalty, it should be 0 or a negative number"
              onInput={inputHandler}
              initialValid={true}
            />
          </Col>
          <Col span={8}>
            <Input
              id="max_insight"
              element="input"
              label="Insight slides"
              validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
              errorText="Please enter a valid quick feedback penalty, it should be 0 or a negative number"
              onInput={inputHandler}
              initialValid={true}
            />
          </Col>
        </Row>
        <Button
          type="submit"
          disabled={!formState.isValid}
          fullWidth={true}
          marginClass="mt-30"
        >
          Add Scenario
        </Button>
      </form>
    </React.Fragment>
  );
};

export default NewScenario;
