import React, { useState, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import SectionHeader from "../components/SectionHeader";
import "../panel.css";
import { staticContent } from '../../shared/data/dictionary'

const language = localStorage.getItem("locale");
const DashboardInstructor = () => {
  const http = useHttpClient();
  const [dashboardData, setDashboardData] = useState();
  const auth = useContext(AuthContext);

  const scenarioId = useParams().scenarioid;
  const classId = useParams().classid;
  const baseUrl = process.env.REACT_APP_API_URL

  // useEffect(() => {
  //   const fetchDashboard = async () => {
  //     try {
  //       const url = `${baseUrl}/dashboard`;
  //       const res = await http.sendRequest(url, "GET", null, {
  //         Authorization: "Bearer " + auth.token,
  //       });
  //       setDashboardData(res);
  //     } catch (err) {
  //       console.log("fetchDashboard err", err);
  //     }
  //   };
  //   fetchDashboard();
  // }, [http.sendRequest]);

  return (
    <React.Fragment>
      <ErrorModal error={http.error} onClear={http.clearError} />

      <SectionHeader title={staticContent[language].dashboard} btns={[]} />

      {/* {http.isLoading && ( */}
        {/* <div className="center"> */}
          {/* <LoadingSpinner /> */}
        {/* </div> */}
      {/* )} */}

      {/* {!http.isLoading && dashboardData && ( */}
        {/* <React.Fragment> */}

          {/* <ScenarioStats stats={dashboardData.stats}></ScenarioStats> */}

          {/* <h5 className="">Best players</h5> */}
          {/* <PlaysList items={dashboardData.bestPlayers} /> */}
        {/* </React.Fragment> */}
      {/* )} */}

    </React.Fragment>
  );
};

export default DashboardInstructor;
