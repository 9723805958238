import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import { staticContent, language } from "../../shared/data/dictionary";

import PlaysList from '../components/PlaysList';
import { Button } from "antd";

const ScenarioPlays = () => {
  const classHttp = useHttpClient();
  const scenarioHttp = useHttpClient();
  const [loadedPlays, setLoadedPlays] = useState();
  const auth = useContext(AuthContext);
  const [scenarioTitle, setScenarioTitle] = useState();
  const [classTitle, setClassTitle] = useState();

  const scenarioId = useParams().scenarioid
  const classId = useParams().classid
  const baseUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {

    const fetchClasses = async () => {
      try {
        const url = `${baseUrl}/scenarios/${scenarioId}/${classId}/plays`
        const responseData = await scenarioHttp.sendRequest(url, 'GET', null, {
          Authorization: 'Bearer ' + auth.token
        });
        setLoadedPlays(responseData.plays);
        setScenarioTitle(responseData.scenarioTitle);
        setClassTitle(responseData.classTitle);
      } catch (err) {
        console.log("fetchClasses err", err)
      }
    };
    fetchClasses();

  }, [classHttp.sendRequest]);

  return (
    <React.Fragment>
      
      <ErrorModal error={scenarioHttp.error} onClear={scenarioHttp.clearError} />

      <div className="section-header">
        <h1>
          {staticContent[language].players}
          {!scenarioHttp.isLoading && scenarioTitle && classTitle && 
            <p className="sub-headline">{scenarioTitle} - {classTitle}</p>
          }
        </h1>
        <div className="btns">
          <Button type="default">
            <Link to={`/scenarios/${scenarioId}/${classId}`}>{staticContent[language]?.goBack}</Link>
          </Button>
        </div>
      </div>

      {scenarioHttp.isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}

      {!scenarioHttp.isLoading && loadedPlays && <PlaysList items={loadedPlays} reviewBtn={true} showPlayerName={true} showScore={true} showLastAttemptDate={true} pagination={false}/>}
      
    </React.Fragment>
  );
};

export default ScenarioPlays;
