import React, { useContext } from 'react';
import { AuthContext } from "../../shared/context/auth-context";
// import { useAuth } from "./shared/hooks/auth-hook";

import './FeedbackListPlayer.css';

import IndividualFeedback from './IndividualFeedback';
import ComboFeedback from './ComboFeedback';
import { Card } from 'antd';
import { staticContent } from '../../shared/data/dictionary'

const language = localStorage.getItem("locale");
const FeedbackListPlayer = props => {

  // const { token, login, logout, userId } = useAuth();
  const auth = useContext(AuthContext);

  const feedbackItems = props.feedbackItems;

  // if (feedbackItems.length === 0) {
  //   return (
  //     <></>
  //   );
  // }

  return (
    <div className="feedback-list">
      {
      feedbackItems.map(feedback => {

        if(props.feedbackType == 'individual'){

          return <IndividualFeedback
            slide={feedback}
            key={feedback.id}
          />
          
        }            
        
        if(props.feedbackType == 'combo'){
      
          return <ComboFeedback
            comboFeedback={feedback}
            key={feedback.slide1.id + feedback.slide2.id}
          />

        }

      }
      )}
    </div>
  );
};

export default FeedbackListPlayer;
