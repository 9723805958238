import { NavLink } from "react-router-dom";
import { staticContent, language } from "../../data/dictionary";

import "./NavLinks.css";


const NavLinks = (props) => {

  return (
    <ul className="nav-links">
      {true && (
        <>
          {/* <li>
            <NavLink to="/" exact>
              {staticContent[language]?.home}
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/about" exact>
              {staticContent[language]?.about}
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="/faq" exact>
              {staticContent[language]?.faq}
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" exact>
              {staticContent[language]?.contact}
            </NavLink>
          </li> */}
        </>
      )}
    </ul>
  );
};

export default NavLinks;
