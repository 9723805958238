if (localStorage.getItem("locale") === null) {
  localStorage.setItem("locale", "en");
}
export const language = localStorage.getItem("locale") || "en";

export const staticContent = {
  "fr": {
    "sign_up": "Inscription",
    "latest_attempt": "Dernière tentative",
    "latest_score": "Plus récent score",
    "view_latest_attempt's_feedback": "Voir les commentaires de la plus récente tentative",
    "attempts_remaining": "Nombre de tentatives restantes",
    "filter_by": "Filtrer par…",
    "sort_by": "Trier par…",
    "slide_type": "Type de diapositive",
    "slide_theme": "Thème de diapositive",
    "drag_slide": "Glisser la diapositive",
    "slide_title": "Titre de diapositive",
    "slide_content": "Contenu de diapositive",
    "see_in_full_screen": "Voir en plein écran",
    "add_to_slide_deck": "Ajouter au paquet de diapositives",
    "move_slide_to_the_end_of_the_slide_bank": "Placer la diapositive au fond de la banque",
    "Check_how_you_did_on_your_last_attempt": "Consultez le résultat de votre plus récente tentative",
    "feedback": "Commentaires",
    "profile_image": "Image de profil (facultatif)",
    "upload_new": "Télécharger nouveau",
    "Submit_attempt": "Soumettre la tentative",
    "Are_you_sure_ready_to_submit": "Voulez-vous vraiment soumettre votre tentative?",
    "Submit": "Soumettre!",
    "Press_the_button_to_see_these_instructions_anytime": "En tout temps, cliquez sur le bouton (?) pour revoir ces instructions.",
    "Goal_create_a_convincing_slide_deck_to_answer": "Objectif : obtenir un paquet de diapositives qui répond de manière convaincante aux exigences du scénario.",
    "How_to_play": "Comment jouer:",
    "The_screen_is_divided_into_two_areas": "L'écran est divisé en deux parties. À gauche, la banque contient toutes les diapositives disponibles pour ce scénario. À droite, il s'agit du paquet que vous soumettrez en réponse au scénario.",
    "The_slide_deck_already_contains_several": "Votre paquet contient déjà plusieurs diapositives de contexte qui vous aident à comprendre le scénario et votre objectif. Lisez-les attentivement!",
    "Browse_the_available_slides_in_the_slide_bank": "Consultez les diapositives en banque et glissez les plus pertinentes ou convaincantes dans votre paquet, pour qu'elles fassent partie de la solution.",
    "When_you're_finished,_submit_your_attempt": "Lorsque vous avez fini, soumettez votre tentative pour obtenir votre score et des commentaires.",
    "Each_slide_in_the_bank_is_either_a_data_slide_or": "Chaque diapositive en banque contient des données ou une note. Les diapositives de données appuient les notes, de sorte que votre paquet devrait comprendre ces deux types.",
    "Each_slide_also_has_a_theme._Data_slides_and": "Chaque diapositive a également un thème. Les diapositives de données et de notes qui sont reliées affichent le même thème.",
    "For_each_slide_you_can": "Pour chaque diapositive, vous pouvez : ",
    "Zoom_in_to_see_it_in_detail": "Zoomer pour la voir en détail.",
    "Select_it_or_drag_it_over_to_add_it_to_the_deck": "La sélectionner ou la glisser pour l'ajouter à votre paquet.",
    "Reject_it_to_move_it_to_the_bottom_of_the_": "La rejeter pour qu'elle arrive au bas de la page, afin de vous concentrer sur les autres diapositives.",
    "Rules": "Règles :",
    "Your_deck_has_a_limited_number_of_slots": "Votre paquet a un nombre limité de places disponibles pour les diapositives de données et de notes. Utilisez-les à bon escient!",
    "The_order_of_slides_in_your_deck_does_not_matter": "L'ordre des diapositives de votre paquet n'a aucune importance.",
    "You_may_assume_background_slides_are_true_and_accurate": "Vous pouvez tenir pour acquis que les diapositives de contexte sont justes et véridiques.",
    "All_data_in_the_slides_is_true,_but_it_isnot_always_presented_well": "Toutes les données des diapositives sont justes, mais pas toujours bien présentées.",
    "Not_all_insights_from_the_slides_are_accurate": "Les notes présentées ne sont pas forcément véridiques ou étayées par des données. Rejetez les moins bonnes!",
    "Hints:": "Indices :",
    "Get_a_hint_about_a_random_slide_currently_in_your_deck": "Obtenez un indice sur une diapositive prise au hasard dans votre paquet.",
    "Get_a_hint_about_how_a_random_pair_of_slides_in_your_deck_go_together": "Obtenez un indice qui montre comment sont reliées deux cartes prises au hasard dans votre paquet.",
    "Careful_The_more_hints_you_ask_for,_the_less_specific_they_become": "Attention! Plus vous demandez d'indices, plus ils deviennent flous.",
    "Scoring": "Le calcul du score",
    "Your_attempt's_score_depends_on:": "Le score d'une tentative dépend :",
    "The_quality_of_each_slide": "De la qualité de chaque diapositive",
    "How_well_your_slides_support_each_other": "De la manière dont vos diapositives se complètent mutuellement",
    "Start_keyboard_navigation": "Utilisation du clavier",
    "Move_slide_focus_up_down_left_right_arrow_keys": "Mieux voir certains endroits d'une diapositive : fléches haut/bas/gauche/droite",
    "Move_selected_slide_to_the_deck": "Placer la diapositive sélectionnée dans le paquet",
    "Remove_selected_slide_from_the_deck": "Retirer la diapositive sélectionnée du paquet",
    "Reject_slide": "Rejeter la diapositive",
    "Unreject_slide": "Annuler le rejet de la diapositive",
    "Closed_no_one_can_join": "Fermé : personne ne peut s'ajouter",
    "Open_students_can_join": "Ouvert : des personnes peuvent s'ajouter",
    "Close_class": "Fermer la classe",
    "Open_class": "Ouvrir la classe",
    "Average_score": "Score moyen",
    "Average_time_per_attempt": "Durée moyenne d'une tentative",
    "Students_attempted": "Nombre de personnes ayant effectué une tentative",
    "Attempts_per_student": "Nombre de tentatives par personne",
    "Top_scoring_players": "Meilleurs joueurs et meilleures joueuses",
    "Review_submission": "Réviser la soumission",
    "selectClass": "Choisir la classe",
    "selectScenario": "Choisir le scénario",
    "Back_to_scenario_list": "Retour à la liste des scénarios",
    "Scenario_settings": "Réglages du scénario",
    "Absolute_maximum_score": "Score maximal absolu",
    "The_absolute_highest_score_possible_on_this_scenario.": "Le plus haut score absolu possible pour ce scénario.",
    "Minimum_excellent_score": "Minimum pour un score Excellent",
    "The_minimum_score_you_would_consider_Excellent": "Le score minimal que vous considérez comme Excellent",
    "Minimum_passing_score": "Minimum pour un score de passage",
    "The_score_that_separates_Failing_from_Okay": "Le score qui sépare Échec de Passable",
    "Attempts_allowed": "Nombre de tentatives autorisées",
    "Number_of_quick_hints_allowed": "Nombre d'indices rapides autorisés",
    "Students_can_request_quick_hints_about_slides_or_slide_combinations_while_playing": "Durant le jeu, il est possible de demander des indices rapides à propos des diapositives ou de combinaisons de diapositives",
    "Number_of_score_previews_allowed": "Nombre de consultations préalables du score autorisées",
    "Students_get_a_rough_idea_of_their_attempts_score_just_before_they_submit.": "Chaque personne peut obtenir son score approximatif avant de soumettre sa proposition.",
    "Number_of_slide_slots_in_the_slide_deck": "Nombre d'emplacements disponibles dans le paquet",
    "Are_you_sure_deleting_a_scenario_is_permanent.": "Aucun doute? L'effacement du scénario est irréversible.",
    "login": "Connexion",
    "invitationCardFirstTitle": "1. Partage du lien d'invitation",
    "invitationCardSecondTitle": "2. Envoi d'un courriel d'invitation",
    "account": "Compte",
    "account_setting": "Réglages du compte",
    "action": "Action",
    "addNewSlide": "Ajouter une nouvelle diapositive",
    "addClass": "Ajouter une classe",
    "addNewClass": "Ajouter une nouvelle classe",
    "reset_password_email_sent": "Nous vous avons envoyé un courriel d'instructions pour créer un nouveau mot de passe.",
    "unexpected_error": "Une erreur inattendue est survenue. Veuillez réessayer plus tard ou contacter l'administrateur.",
    "assignedToClass": "Assigné à la classe",
    "authentication_failed": "Échec de l'authentification!",
    "average": "Moyenne",
    "background": "Contexte",
    "best": null,
    "brief": null,
    "check_how_you_did_on_your_latest_attempt": "Consultez le résultat de votre dernière tentative",
    "invitationSubHeader": "Vous pouvez ajouter des personnes à votre liste de deux manières :",
    "class": "Classe",
    "classes": "Classes",
    "combo": "Combinaison ",
    "combo_already_exist": "La combinaison existe déjà.",
    "completed": "Terminé",
    "config": "Configuration",
    "copy": "Copie",
    "request_error": "Commande impossible, veuillez contacter l'administrateur.",
    "createNewClass": "Créer une nouvelle classe",
    "dashboard": "Tableau de bord",
    "data": "Données",
    "feedbackDetailedHeader": "Solo Slides Points and Feedback (Fr)",
    "description": "Description",
    "disable": "Désactiver",
    "dueDate": "Échéance",
    "edit": "Édition",
    "email": "Courriel",
    "errors": "Erreurs",
    "excellent": "Excellent",
    "failing": "Échec ",
    "filter": "Filtrer par",
    "fullMark": null,
    "generalFeedback": "Commentaires généraux",
    "goBack": "Retour",
    "good": "Bien",
    "guide": "Guide",
    "image": "Image",
    "individual": "Individuel",
    "insight": "Note",
    "invalid_credentials": "Identifiants invalides, accès refusé",
    "form_validation_error": "Entrée invalide, vérifiez le formulaire.",
    "invitations": "Invitations",
    "invite": "Inviter",
    "invitationHeader": "Inviter des personnes à rejoindre la classe",
    "last_attempt": "Dernière tentative",
    "late": "Retard",
    "link": "Lien",
    "logout": "Déconnexion",
    "maximum": "Maximum",
    "minimum": "Minimum",
    "more": "Plus",
    "moreDetails": "Plus de détails",
    "moreInfo": "Plus d'informations",
    "name": "Nom ",
    "notFoundClass": "Aucune classe trouvée",
    "notFoundFeedback": "Aucun commentaire trouvé",
    "notFoundPlayers": "Aucun joueur trouvé",
    "notFoundScenarios": "Aucun scénario trouvé",
    "numberOf": "Nombre de ",
    "numberOfAttempt": "Nombre de tentatives ",
    "okay": "Passable",
    "or": "Ou",
    "overallStats": "Statistiques générales",
    "panel": "Panneau ",
    "passing": "Note de passage",
    "penalty": "Pénalité",
    "percentages": "Pourcentages",
    "play": "Jouer",
    "playScenario": "Lancer ce scénario",
    "played": "Déjà effectué",
    "player": "Joueur",
    "players": "Joueurs",
    "image_error": "Choisissez un fichier jpg ou png de moins de 1 MO.",
    "briefPenaltyError": "Entrez une pénalité courte valide : 0 ou un nombre négatif.",
    "dueDateError": "Entrez une échéance valide.",
    "please_enter_a_valid_email_address": "Entrez un courriel valide.",
    "valid_email": "Entrez un courriel valide.",
    "briefMaxError": "Entrez un nombre entier",
    "fullScoreError": null,
    "retryError": "Entrez un nombre entier pour le nombre de nouvelles tentatives",
    "latePenaltyError": "Entrez une pénalité de retard valide : il doit s'agir d'un nombre négatif.",
    "minPassingError": "Entrez une note de passage minimale valide.",
    "please_enter_a_valid_password": "Saisissez un mot de passe valide d'au moins 6 caractères.",
    "retryPenaltyError": "Entrez une pénalité valide pour toute nouvelle tentative : il doit s'agir d'un nombre négatif.",
    "please_enter_a_valid_title": "Entrez un titre valide.",
    "please_enter_your_name": "Saisissez votre nom.",
    "invitationEmailError": "Veuillez séparer les courriels par des virgules.",
    "possible": "Potentiel",
    "profile": "Profil",
    "range": "Fourchette",
    "ranges": "Fourchettes",
    "submitDeckHeader": "Votre soumission est prête?",
    "reject": "Rejeter",
    "rejected": "Rejeté",
    "resource_created": "Ressource créée avec succès",
    "resource_deleted": "Ressource effacée avec succès",
    "resource_updated": "Ressource mise à jour avec succès",
    "resource_not_found": "Ressource_non_trouvée",
    "retries": "Nouvelles tentatives",
    "retry": "Réessayer",
    "review": "Réviser",
    "reviewDeck": "Réviser le paquet",
    "reviewPoints": "Réviser les points",
    "review_submission": "Réviser la soumission",
    "save": "Sauvegarder",
    "scenario": "Scénario",
    "scenarioSetting": "Réglages du scénario",
    "scenarios": "Scénarios",
    "score": "Score",
    "scoreAndFeedback": "Score et commentaires",
    "select": "Choisir",
    "scenarioInstructorOne": "Choisir une classe",
    "scenarioInstructorTwo": "Choisir un scénario",
    "send": "Envoi",
    "setting": "Mise en place ",
    "invitationCardFirstContent": "Accès possible par toute personne disposant du lien",
    "invitationCardSecondContent": "Accès réservé à des personnes spécifiques",
    "show": "Montrer",
    "slideBank": "Banque de diapositives",
    "slideDeck": "Paquet de diapositives ",
    "slides": "Diapositives",
    "submitDeckBody": "Jusqu'ici, votre paquet ressemble à : ",
    "sortBy": "Classer par",
    "sort": "Classer par",
    "sortByTheme": "Classer par thème",
    "sortByTitle": "Classer par titre",
    "sortByType": "Classer par type",
    "student": "Étudiant·e",
    "students": "Étudiant·e·s",
    "studentsList": "Liste de classe",
    "submit": "Soumettre la tentative",
    "submitTheDeck": "Soumettre la tentative",
    "required_field": "Ce champ est requis.",
    "min_lenght": "Ce champ doit contenir au moins 5 caractères. ",
    "max_lenght": "Ce champ doit contenir moins de 5 caractères. ",
    "reset_link_not_valid": "Ce lien n'est pas ou plus valide. Veuillez réinitialiser votre mot de passe.",
    "feedbackDetailedParagraph": "Voici les points et commentaires obtenus pour les dispositives ajoutées à votre paquet",
    "moved_to_bank": "Cette diapositive a été mise en banque.",
    "moved_to_deck": "Cette diapositive a été ajoutée à votre paquet.",
    "timePerAttempt": "Durée de chaque tentative",
    "title": "Titre",
    "too_many_background_slides": "Trop de diapositives de contexte! Enlevez-en certaines et réessayez.",
    "too_many_data_slides": "Trop de diapositives de données! Enlevez-en certaines et réessayez.",
    "too_many_insight_slides": "Trop de diapositives de notes! Enlevez-en certaines et réessayez.",
    "topPlayers": "Meilleurs résultats pour ce scénario",
    "top_scores": "Meilleurs scores",
    "top_scoring_players": "Meilleurs joueurs et meilleures joueuses",
    "totalPoints": "Total des points",
    "unReject": "Annuler le rejet",
    "unSelect": "Annuler la sélection ",
    "update": "Mise à jour",
    "updated_successfully": "Mise à jour réussie.",
    "uploadNew": "Télécharger nouveau",
    "user_already_exist": "Ce nom d'utilisateur existe déjà, veuillez vous connecter.",
    "users": "Utilisateurs",
    "veryGood": "Très bien",
    "view_last_attempt_feedback": "Consultez les commentairess de votre dernière tentative",
    "class_title_already_exist": "Vous avez déjà une classe qui porte ce titre, choisissez-en un autre.",
    "youAreInTheTop": "Vous êtes dans le top",
    "submitDeckFooter": "Vous avez soumis [X] tentatives.",
    "accountFormTitle": "Votre compte",
    "zoom": "Zoomer",
    "zoomOut": "Dézoomer",
    "times": "fois",
    "your_attempt_looks_like": "Fr",
    "score_range_feedback_exceeded": "Fr",
    "submission_exceeded": "Fr",
    "feedbackConfirmPopup": "Fr",
    "notPlayedYet": "Not played yet (Fr)",
    "yourScoreIs": "Your score is (Fr)",
    "comboFeedbackDetailedHeader": "Fr",
    "comboFeedbackDetailedParagraph": "Fr",
    "home": "Accueil",
    "about": "About",
    "about_data_literacy_game": "Le Data Literacy Game est un jeu d'apprentissage expérientiel destiné à enseigner la culture des données et la visualisation. Le but du jeu est de fournir aux étudiants une expérience interactive pour mettre en pratique les compétences de compréhension des données, de compréhension des problèmes basés sur les données, d'interprétation des visualisations, de présentation des données et de construction d'histoires convaincantes basées sur les données. Plusieurs scénarios sont proposés dans le jeu pour explorer l'utilisation de la prise de décision basée sur les données dans différents contextes.",
    "team": "Équippe",
    "team_description": "L'équipe travaillant sur ce projet est composée de professeurs et d'étudiants de plusieurs institutions en Ontario.",
    "project_leads": "Responsables du projet",
    "game_design_lead_project_manager": "Conception du jeu / Chef de projet",
    "lead_programmer": "Programmeur en chef / développeur full stack",
    "Content_and_Scenario_Development": "Développement de contenu et de scénarios",
    "AODA_Support": "Soutien LAPHO",
    "French_Translation": "Traduction française",
    "ecampus_statement": "Ce projet est rendu possible grâce au financement du gouvernement de l'Ontario et au soutien d'eCampusOntario à la Stratégie d'apprentissage virtuel. Pour en savoir plus sur la Stratégie d'apprentissage virtuel, visitez le site :",
    "individualFeedback": "Individuel Rétroaction",
    "comboFeedback": "Combiné Rétroaction",
    "yourScoreRangeIs": "Votre plage de score est:",
    "quick": "bref",
    "quickMaxError": "Veuillez entrer un nombre entier valide pour bref",
    "quickPenaltyError": "Veuillez entrer une brève pénalité valide, elle doit être 0 ou un nombre négatif",
    "closeTheClass": "Fermer la classe",
    "openTheClass": "Ouvrir la classe",
    "studentsCanJoin": "Ouvert : des personnes peuvent s'ajouter",
    "studentsCanNotJoin": "Fermé : personne ne peut s'ajouter",
    "cancel": "Annuler",
    "scenario_stats": "Statistiques générales",
    "students_attempted": "Nombre de personnes ayant effectué une tentative",
    "attempts_per_student": "Nombre de tentatives par personne",
    "average_score": "Score moyen",
    "time_per_attempt": "Time per attempt",
    "back_to_scenario_list": "Retour à la liste des scénarios",
    "scenario_settings": "Réglages du scénario",
    "not_attempted": "pas tenté",
    "players_list": "Players list (Fr)",
    "valid_number": "S'il vous plait, entrez un nombre valide.",
    "image_description": "Description de l'image",
    "slide_img_alt": "Description de l'image disponible via le bouton long-description.",
    "play_guide_header": "Comment jouer:",
    "delete_sure_message": "Are you sure you want to delete this resource?",
    deckError: "The number of slides your deck is incorrect. Please remove or add some slides and try again.",
    faq: "FAQs",
    contact: "Contact",
    hero_headline: "Master Data Visualization Through Game",
    request_access: "Request Access",
    are_you_instructor: "Are you an instructor?",
    purpose_data_literacy_game: "The purpose of the game is to provide students with an interactive experience to practice the skills of understanding data, making sense of data based problems, interpreting visualizations, presenting data and building persuasive data driven stories.",
    signup: "Signup",
    will_contact_you: "We will contact you in 24 hours",
    background_slides: "Background Slides (Fr)",
    data_slides: "Data Slides (Fr)",
    insight_slides: "Insight Slides (Fr)",
    unavailable: "(Unavailable (Fr))",
    instructor_account_needs_to_get_approved: "Thank you for registering as a instructor in data literacy game. Your account will be approved in the next few days. (Fr)",
  },
  "en": {
    "sign_up": "sign up",
    "latest_attempt": "latest attempt",
    "latest_score": "Latest score",
    "view_latest_attempt's_feedback": "view latest attempt's feedback",
    "attempts_remaining": "Attempts remaining",
    "filter_by": "filter by...",
    "sort_by": "sort by...",
    "slide_type": "slide type",
    "slide_theme": "slide theme",
    "drag_slide": "drag slide",
    "slide_title": "slide title",
    "slide_content": "slide content",
    "see_in_full_screen": "see in full screen",
    "add_to_slide_deck": "add to slide deck",
    "move_slide_to_the_end_of_the_slide_bank": "move slide to the end of the slide bank",
    "Check_how_you_did_on_your_last_attempt": "Check how you did on your last attempt",
    "feedback": "Feedback",
    "profile_image": "Profile image (optional)",
    "upload_new": "Upload new",
    "Submit_attempt": "Submit attempt",
    "Are_you_sure_ready_to_submit": "Are you sure you're ready to submit?",
    "Submit": "Submit!",
    "Press_the_button_to_see_these_instructions_anytime": "Press the (?) button to see these instructions anytime.",
    "Goal_create_a_convincing_slide_deck_to_answer": "Goal: create a convincing slide deck to answer the scenario prompt.",
    "How_to_play": "How to play:",
    "The_screen_is_divided_into_two_areas": "The screen is divided into two areas. On the left, the slide bank contains all the slides available to you for this scenario. On the right is the slide deck you will submit to solve the scenario.",
    "The_slide_deck_already_contains_several": "The slide deck already contains several background slides which will help you understand the scenario and your task. Read them carefully!",
    "Browse_the_available_slides_in_the_slide_bank": "Browse the available slides in the slide bank and drag the most relevant and convincing ones into your deck to build your solution.",
    "When_you're_finished,_submit_your_attempt": "When you're finished, submit your attempt to receive your score and feedback on your slides",
    "Each_slide_in_the_bank_is_either_a_data_slide_or": "Each slide in the bank is either a data slide or an insight slide. Data slides support insight slides, so your deck should include both.",
    "Each_slide_also_has_a_theme._Data_slides_and": "Each slide also has a theme. Data slides and insight slides that relate to each other have the same theme.",
    "For_each_slide_you_can": "For each slide, you can:",
    "Zoom_in_to_see_it_in_detail": "Zoom in to see it in detail",
    "Select_it_or_drag_it_over_to_add_it_to_the_deck": "Select it or drag it over to add it to the deck",
    "Reject_it_to_move_it_to_the_bottom_of_the_": "Reject it to move it to the bottom of the page, letting you focus on other slides.",
    "Rules": "Rules:",
    "Your_deck_has_a_limited_number_of_slots": "Your deck has a limited number of slots for data and insight slides, so use them wisely!",
    "The_order_of_slides_in_your_deck_does_not_matter": "The order of slides in your deck does not matter.",
    "You_may_assume_background_slides_are_true_and_accurate": "You may assume background slides are true and accurate.",
    "All_data_in_the_slides_is_true,_but_it_isnot_always_presented_well": "All data in the slides is true, but it isn't always presented well.",
    "Not_all_insights_from_the_slides_are_accurate": "Not all insights from the slides are accurate or supported by data. Avoid the weak ones!",
    "Hints:": "Hints:",
    "Get_a_hint_about_a_random_slide_currently_in_your_deck": "Get a hint about a random slide currently in your deck",
    "Get_a_hint_about_how_a_random_pair_of_slides_in_your_deck_go_together": "Get a hint about how a random pair of slides in your deck go together",
    "Careful_The_more_hints_you_ask_for,_the_less_specific_they_become": "Careful! The more hints you ask for, the less specific they become.",
    "Scoring": "Scoring",
    "Your_attempt's_score_depends_on:": "Your attempt's score depends on:",
    "The_quality_of_each_slide": "The quality of each slide",
    "How_well_your_slides_support_each_other": "How well your slides support each other",
    "Start_keyboard_navigation": "Start keyboard navigation",
    "Move_slide_focus_up_down_left_right_arrow_keys": "Move slide focus: up/down/left/right arrow keys",
    "Move_selected_slide_to_the_deck": "Move selected slide to the deck",
    "Remove_selected_slide_from_the_deck": "Remove selected slide from the deck",
    "Reject_slide": "Reject slide",
    "Unreject_slide": "Unreject slide",
    "Closed_no_one_can_join": "Closed: no one can join",
    "Open_students_can_join": "Open: students can join",
    "Close_class": "Close class",
    "Open_class": "Open class",
    "Average_score": "Average score",
    "Average_time_per_attempt": "Average time per attempt",
    "Students_attempted": "Students attempted",
    "Attempts_per_student": "Attempts per student",
    "Top_scoring_players": "Top scoring players",
    "Review_submission": "Review submission",
    "selectClass": "Select class",
    "selectScenario": "Select scenario",
    "Back_to_scenario_list": "Back to scenario list",
    "Scenario_settings": "Scenario settings",
    "Absolute_maximum_score": "Absolute maximum score",
    "The_absolute_highest_score_possible_on_this_scenario.": "The absolute highest score possible on this scenario.",
    "Minimum_excellent_score": "Minimum excellent score",
    "The_minimum_score_you_would_consider_Excellent": "The minimum score you would consider \"Excellent\"",
    "Minimum_passing_score": "Minimum passing score",
    "The_score_that_separates_Failing_from_Okay": "The score that separates \"Failing\" from \"Okay\"",
    "Attempts_allowed": "Attempts allowed",
    "Number_of_quick_hints_allowed": "Number of quick hints allowed",
    "Students_can_request_quick_hints_about_slides_or_slide_combinations_while_playing": "Students can request quick hints about slides or slide combinations while playing",
    "Number_of_score_previews_allowed": "Number of score previews allowed",
    "Students_get_a_rough_idea_of_their_attempts_score_just_before_they_submit.": "Students get a rough idea of their attempt's score just before they submit.",
    "Number_of_slide_slots_in_the_slide_deck": "Number of slide slots in the slide deck",
    "Are_you_sure_deleting_a_scenario_is_permanent.": "Are you sure? Deleting a scenario is permanent.",
    "login": "Login",
    "invitationCardFirstTitle": "1. Share invitation link",
    "invitationCardSecondTitle": "2. Send an invitation email",
    "account": "account",
    "account_setting": "Account Settings",
    "action": "Action",
    "addNewSlide": "Add a new slide",
    "addClass": "Add Class",
    "addNewClass": "Add New Class",
    "reset_password_email_sent": "We sent you an e-mail with instructions for resetting your password.",
    "unexpected_error": "An unexpected error occurred, please try again later or contact administrator.",
    "assignedToClass": "Assigned to Class",
    "authentication_failed": "Authentication failed!!",
    "average": "Average",
    "background": "Background",
    "best": "Best",
    "brief": "brief",
    "check_how_you_did_on_your_latest_attempt": "Check how you did on your latest attempt",
    "invitationSubHeader": "There are two ways to add students to your list:",
    "class": "class",
    "classes": "Classes",
    "combo": "Combo",
    "combo_already_exist": "Combo already exists",
    "completed": "Completed",
    "config": "Configure",
    "copy": "Copy",
    "request_error": "Could not handle the request, please contact the administrator.",
    "createNewClass": "Create New Class",
    "dashboard": "Dashboard",
    "data": "Data",
    "feedbackDetailedHeader": "Solo Slides Points and Feedback",
    "description": "Description",
    "disable": "Disable",
    "dueDate": "Due Date",
    "edit": "Edit",
    "email": "Email",
    "errors": "Errors",
    "excellent": "Excellent",
    "failing": "Failing",
    "filter": "Filter by",
    "fullMark": "Full Mark",
    "generalFeedback": "General Feedback",
    "goBack": "Go back",
    "good": "Good",
    "guide": "Guide",
    "image": "image",
    "individual": "Individual",
    "insight": "Insight",
    "invalid_credentials": "Invalid credentials, could not log you in.",
    "form_validation_error": "Invalid inputs passed, please check your data.",
    "invitations": "Invitations",
    "invite": "Invite",
    "invitationHeader": "Invite students to join this class",
    "last_attempt": "Last attempt",
    "late": "Late",
    "link": "Link",
    "logout": "Logout",
    "maximum": "Maximum",
    "minimum": "Minimum",
    "more": "more",
    "moreDetails": "More details",
    "moreInfo": "More Info",
    "name": "name",
    "notFoundClass": "No Classes found.",
    "notFoundFeedback": "No feedback found.",
    "notFoundPlayers": "No players found.",
    "notFoundScenarios": "No Scenarios found.",
    "numberOf": "number of",
    "numberOfAttempt": "number of attempt",
    "okay": "Okay",
    "or": "Or",
    "overallStats": "Overall stats",
    "panel": "Panel",
    "passing": "Passing",
    "penalty": "Penalty",
    "percentages": "Percentages",
    "play": "Play",
    "playScenario": "Play scenario",
    "played": "Played",
    "player": "Player",
    "players": "Players",
    "image_error": "Please choose a jpg or png file that is less than 1 mb",
    "briefPenaltyError": "Please enter a valid brief penalty, it should be 0 or a negative number",
    "dueDateError": "Please enter a valid due date.",
    "please_enter_a_valid_email_address": "Please enter a valid email address.",
    "valid_email": "Please enter a valid email address.",
    "briefMaxError": "Please enter a valid integer number for brief",
    "fullScoreError": "Please enter a valid integer number for full score",
    "retryError": "Please enter a valid integer number for retry",
    "latePenaltyError": "Please enter a valid late penalty, it should be negative number",
    "minPassingError": "Please enter a valid minimum passing.",
    "please_enter_a_valid_password": "Please enter a valid password, at least 6 characters.",
    "retryPenaltyError": "Please enter a valid retry penalty, it should be negative number",
    "please_enter_a_valid_title": "Please enter a valid title.",
    "please_enter_your_name": "Please enter your name.",
    "invitationEmailError": "Please use comma to separate email addresses.",
    "possible": "Possible",
    "profile": "Profile",
    "range": "Range",
    "ranges": "Ranges",
    "submitDeckHeader": "Ready to submit?",
    "reject": "Reject",
    "rejected": "Rejected",
    "resource_created": "Resource created successfully",
    "resource_deleted": "Resource deleted successfully",
    "resource_updated": "Resource updated successfully",
    "resource_not_found": "resource_not_found",
    "retries": "retries",
    "retry": "Retry",
    "review": "Review",
    "reviewDeck": "Review deck",
    "reviewPoints": "Review points",
    "review_submission": "Review submission",
    "save": "Save",
    "scenario": "scenario",
    "scenarioSetting": "Scenario setting",
    "scenarios": "Scenarios",
    "score": "Score",
    "scoreAndFeedback": "Score and feedback",
    "select": "Select",
    "scenarioInstructorOne": "Select a class",
    "scenarioInstructorTwo": "Select a scenario",
    "selectScenarios": "Select scenarios you want to use for this class",
    "send": "Send",
    "setting": "Setting",
    "invitationCardFirstContent": "Anyone with the link can join",
    "invitationCardSecondContent": "Only specific students can join",
    "show": "Show",
    "slideBank": "Slide Bank",
    "slideDeck": "Slide Deck",
    "slides": "Slides",
    "submitDeckBody": "So far, your deck appears to be:",
    "sortBy": "Sort by",
    "sort": "Sort by",
    "sortByTheme": "Sort by Theme",
    "sortByTitle": "Sort by Title",
    "sortByType": "Sort by Type",
    "student": "student",
    "students": "students",
    "studentsList": "Students list",
    "submit": "Submit attempt",
    "submitTheDeck": "Submit attempt",
    "required_field": "The field is required.",
    "min_lenght": "The field should have at least 5 characters.",
    "max_lenght": "The field should have at most 5 characters.",
    "reset_link_not_valid": "The link is not valid or has expired. Please reset your password again.",
    "feedbackDetailedParagraph": "The points and feedbacks you got for the slides you chosen for the deck",
    "moved_to_bank": "The slide moved to bank",
    "moved_to_deck": "The slide moved to deck",
    "timePerAttempt": "time per attempt",
    "title": "Title",
    "too_many_background_slides": "Too many background slides!, remove some and try again.",
    "too_many_data_slides": "Too many data slides!, remove some and try again.",
    "too_many_insight_slides": "Too many insight slides!, remove some and try again.",
    "topPlayers": "Top players in scenario",
    "top_scores": "Top scores",
    "top_scoring_players": "Top scoring players",
    "totalPoints": "Total Points",
    "unReject": "Unreject",
    "unSelect": "Unselect",
    "update": "Update",
    "updated_successfully": "Updated successfully.",
    "uploadNew": "Upload New",
    "user_already_exist": "User exists already, please login instead.",
    "users": "Users",
    "veryGood": "Very Good",
    "view_last_attempt_feedback": "View last attempt’s feedback",
    "class_title_already_exist": "You already have created another class with the same title, please choose another title.",
    "youAreInTheTop": "You are in the top",
    "submitDeckFooter": "You have submitted [X] attempts",
    "accountFormTitle": "Your account",
    "zoom": "Zoom in",
    "zoomOut": "Zoom out",
    "times": "times",
    "your_attempt_looks_like": "So far, your deck appears to be:",
    "score_range_feedback_exceeded": "You no longer can see your score range.",
    "submission_exceeded": "You no longer can submit the deck.",
    "feedbackConfirmPopup": "Ready for a slide hint? Hints become less specific the more you receive.",
    "notPlayedYet": "Not played yet",
    "yourScoreIs": "Your score is",
    "comboFeedbackDetailedHeader": "Combo Slides Points and Feedback",
    "comboFeedbackDetailedParagraph": "The points and feedbacks you got for the combo slides you chosen for the deck",
    "home": "home",
    "about": "About",
    "about_data_literacy_game": "The Data Literacy Game is an experiential-learning game to teach data literacy and visualization. The purpose of the game is to provide students with an interactive experience to practice the skills of understanding data, making sense of data based problems, interpreting visualizations, presenting data and building persuasive data driven stories. There are several scenarios to choose from within the game to explore the use of data driven decision making in different contexts.",
    "team": "Team",  
    "team_description": "The team working on this project consists of professors and students from several institutions in Ontario.",
    "project_leads": "Project Leads",
    "game_design_lead_project_manager": "Game Design Lead / Project Manager",
    "lead_programmer": "Lead Programmer / Full Stack Developer",
    "Content_and_Scenario_Development": "Content and Scenario Development",
    "AODA_Support": "AODA Support",
    "French_Translation": "French Translation",
    "ecampus_statement": "This project is made possible with funding by the Government of Ontario and through eCampusOntario’s support of the Virtual Learning Strategy. To learn more about the Virtual Learning Strategy visit: ",
    "individualFeedback": "Solo feedback",
    "comboFeedback": "Combo feedback",
    "yourScoreRangeIs": "Your score range is:",
    "quick": "quick",
    "quickMaxError": "Please enter a valid integer number for quick",
    "quickPenaltyError": "Please enter a valid quick penalty, it should be 0 or a negative number",
    "closeTheClass": "Close class",
    "openTheClass": "Open class",
    "studentsCanJoin": "Open: students can join",
    "studentsCanNotJoin": "Closed: no one can join",
    "cancel": "Cancel",
    "scenario_stats": "Overall stats",
    "students_attempted": "Students attempted",
    "attempts_per_student": "attempts per student",
    "average_score": "Average score",
    "time_per_attempt": "Time per attempt",
    "back_to_scenario_list": "Back to scenario list",
    "scenario_settings": "Scenario settings",
    "not_attempted": "Not attempted",
    "players_list": "Players list",
    "valid_number": "Please enter a valid number.",
    "image_description": "Image description",
    "slide_img_alt": "Image description available via long-description button",
    "play_guide_header": "How to play:",
    "delete_sure_message": "Are you sure you want to delete this resource?",
    deckError: "The number of slides your deck is incorrect. Please remove or add some slides and try again.",
    faq: "FAQs",
    contact: "Contact",
    hero_headline: "Master Data Visualization Through Game",
    request_access: "Request Access",
    are_you_instructor: "Are you an instructor?",
    purpose_data_literacy_game: "The purpose of the game is to provide students with an interactive experience to practice the skills of <strong>understanding data, making sense of data based problems, interpreting visualizations, presenting data and building persuasive data driven stories.</strong>",
    signup: "Signup",
    will_contact_you: "We will contact you in 24 hours",
    background_slides: "Background Slides",
    data_slides: "Data Slides",
    insight_slides: "Insight Slides",
    unavailable: "(Unavailable)",
    instructor_account_needs_to_get_approved: "Thank you for registering as a instructor in data literacy game. Your account will be approved in the next few days.",
  }

}
