import React, { useContext } from 'react';

import ClassItemInstructor from './ClassItemInstructor';
import Card from '../../shared/components/UIElements/Card';
import './ClassesListInstructor.css';
import { AuthContext } from "../../shared/context/auth-context";
// import { useAuth } from "./shared/hooks/auth-hook";
import { staticContent } from "../../shared/data/dictionary";
import { Translate } from "../../shared/util/translator";

const language = localStorage.getItem("locale");
const ClassesListInstructor = props => {

  // const { token, login, logout, userId } = useAuth();
  const auth = useContext(AuthContext);

  // const isAdmin = auth.userId == 2 ? true : false

  const isAdmin = true

  if (props.items.length === 0) {
    return (
      <div className="center">
        <Card>
          <h2>{staticContent[language]?.notFoundClass}</h2>
        </Card>
      </div>
    );
  }
  return (
    <div className="classes-list">
      {props.items.map(classroom => (
        <ClassItemInstructor
          key={classroom.id}
          id={classroom.id}
          title={Translate(classroom, 'title')}
          description={classroom.description}
          playersCount={classroom.players.length}
          settingbtn={props.settingbtn}
          selectbtn={props.selectbtn}
          playersbtn={props.playersbtn}
          onSelect={props.onSelect}
          invitebtn={props.invitebtn}
          selected={props.selected == classroom.id}
        />
      ))}
    </div>
  );
};

export default ClassesListInstructor;
