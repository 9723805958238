import React from "react";
import Table from "react-bootstrap/Table";

export const KeyboardGuide = function (props: any) {
  return (
    <div className="empty-slide">
      <span className="title">
        Drag slides here to create a convincing presentation
      </span>
      <div className="shortcuts-wrapper">
        <div className="each-shortcut">
          <div className="shortcut-title">Start Navigating slides</div>
          <div className="shortcut-action">N</div>
        </div>
        <div className="each-shortcut">
          <div className="shortcut-title">Navigate through slides</div>
          <div className="shortcut-action">up/down/right/left arrows</div>
        </div>
        <div className="each-shortcut">
          <div className="shortcut-title">Move slide</div>
          <div className="shortcut-action">1-Space/Enter <br /> 2-ArrowKeys</div>
        </div>
        <div className="each-shortcut">
          <div className="shortcut-title">Move slide directly to deck</div>
          <div className="shortcut-action">S</div>
        </div>
        <div className="each-shortcut">
          <p>1. I think we can also write a number of paragraphs that explains how users should interact with the demo, and having only keyboard shortcuts is not enough.</p>
        </div>
        <div className="each-shortcut">
          <p>2. I made this guide box also scrollable so we can add as many as we want.</p>
        </div>
        <div className="each-shortcut">
          <p>3. This is a fake paragraph to just check the scroll when we have lots of guidance here. Eiusmod est qui proident adipisicing labore ipsum anim veniam. Cillum cillum laboris reprehenderit non sunt ex voluptate velit anim labore labore veniam. Voluptate est labore in irure consectetur minim nostrud qui velit minim. Commodo dolor mollit proident non eu. Fugiat exercitation proident pariatur sint labore ullamco irure aliqua mollit. Nulla nisi est amet esse reprehenderit ullamco ad dolor cillum non in deserunt. Veniam labore ea velit non.</p>
        </div>
        <div className="each-shortcut">
          <p>4. Eiusmod est qui proident adipisicing labore ipsum anim veniam. Cillum cillum laboris reprehenderit non sunt ex voluptate velit anim labore labore veniam. Voluptate est labore in irure consectetur minim nostrud qui velit minim. Commodo dolor mollit proident non eu. Fugiat exercitation proident pariatur sint labore ullamco irure aliqua mollit. Nulla nisi est amet esse reprehenderit ullamco ad dolor cillum non in deserunt. Veniam labore ea velit non.</p>
        </div>
      </div>

      {/* <Table
        striped
        bordered
        hover
        variant="dark"
        className="empty-slide-table"
      >
        
        <tbody>
          <tr>
            <td>Focus/navigate through slides</td>
            <td>Tab</td>
          </tr>
          <tr>
            <td>Navigate through slides</td>
            <td>up/down/right/left arrows</td>
          </tr>
          <tr>
            <td>Move slide to deck</td>
            <td>S</td>
          </tr>
        </tbody>
      </Table> */}
    </div>
  );
};

export default KeyboardGuide;
