import React, { useState, useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import Input from "../../shared/components/FormElements/Input";
// import { Button } from "antd";
import Button from "../../shared/components/FormElements/Button";

import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

import { Row, Col } from "antd";
import "./AuthInvite.css";

const Auth = () => {
  const auth = useContext(AuthContext);
  const [isLoginMode, setIsLoginMode] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const classHttp = useHttpClient();
  const inviteToken = useParams().token;
  const [loadedInstructor, setLoadedInstructor] = useState();
  const baseUrl = process.env.REACT_APP_API_URL;

  // get invited by person name
  useEffect(() => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const fetchClass = async () => {
      try {
        const responseData = await classHttp.sendRequest(
          `${baseUrl}/classes/invite/${inviteToken}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setLoadedInstructor(responseData.instructor);
      } catch (err) {
        console.log("fetch instructor err", err);
      }
    };
    fetchClass();
  }, [classHttp.sendRequest]);

  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const switchModeHandler = () => {
    if (!isLoginMode) {
      setFormData(
        {
          ...formState.inputs,
          name: undefined,
          // image: undefined
        },
        formState.inputs.email.isValid && formState.inputs.password.isValid
      );
    } else {
      setFormData(
        {
          ...formState.inputs,
          name: {
            value: "",
            isValid: false,
          },
          // image: {
          //   value: null,
          //   isValid: false
          // }
        },
        false
      );
    }
    setIsLoginMode((prevMode) => !prevMode);
  };

  const authSubmitHandler = async (event) => {
    event.preventDefault();

    let url = `${baseUrl}/users/login`;
    let formData = {
      email: formState.inputs.email.value,
      password: formState.inputs.password.value,
      inviteToken: inviteToken,
    };
    if (!isLoginMode) {
      url = `${baseUrl}/users/signup`;
      formData = { ...formData, name: formState.inputs.name.value };
    }
    try {
      const responseData = await sendRequest(
        url,
        "POST",
        JSON.stringify(formData),
        {
          "Content-Type": "application/json",
        }
      );
      auth.login(responseData.userId, responseData.token, responseData.role);
      localStorage.setItem("locale", "en");
    } catch (err) {}
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <div className="auth-wrapper">
        <Row>
          <Col span={12} className="left-side">
            <div className="header">
              <div className="logo-wrapper">
                <Link to="/">
                  <img src="/assets/imgs/logo.png" width="100%" />
                  <span>Data Literacy Game</span>
                </Link>
              </div>
            </div>
            <h1 className="invite-headline">
              You Are Invited to Play <br />
              Data Literacy Game
            </h1>
            <p className="invite-subheadline">
              Invited by:{" "}
              <span className="capitalize"> {loadedInstructor} </span>
            </p>
            <div className="invite-form-wrapper">
              {isLoading && <LoadingSpinner asOverlay />}
              {/* <h2>Login Required</h2> */}
              <hr />
              <form onSubmit={authSubmitHandler}>
                {!isLoginMode && (
                  <Input
                    element="input"
                    id="name"
                    type="text"
                    label="Your Name"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a name."
                    onInput={inputHandler}
                  />
                )}
                <Input
                  element="input"
                  id="email"
                  type="email"
                  label="E-Mail"
                  validators={[VALIDATOR_EMAIL()]}
                  errorText="Please enter a valid email address."
                  onInput={inputHandler}
                />

                <Input
                  element="input"
                  id="password"
                  type="password"
                  label="Password"
                  validators={[VALIDATOR_MINLENGTH(6)]}
                  errorText="Please enter a valid password, at least 6 characters."
                  onInput={inputHandler}
                />
                <br />
                <Button type="submit" disabled={!formState.isValid}>
                  {isLoginMode
                    ? "Login to accept invitation"
                    : "Signup to accept invitation"}
                </Button>
              </form>
              <Button inverse onClick={switchModeHandler}>
                Switch to {isLoginMode ? "signup" : "login"}
              </Button>
              <Link to="/forgot-password" className="black">
                Forgot your password?
              </Link>
              <br />
            </div>
          </Col>
          <Col span={12}>
            <img src="/assets/imgs/auth.jpg" width="100%" />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Auth;
