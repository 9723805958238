import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { useForm } from '../../shared/hooks/form-hook';
import Input from '../../shared/components/FormElements/Input';
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH
} from '../../shared/util/validators';
import Button from '../../shared/components/FormElements/Button';
import '../../shared/components/FormElements/Form.css';
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import { toast } from 'react-toastify';
import { staticContent, language } from "../../shared/data/dictionary";


// import ErrorModal from '../../shared/components/UIElements/ErrorModal';
// import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';

const CreateCombo = (props) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();
  const scenarioId = props.scenarioId;
  const baseUrl = process.env.REACT_APP_API_URL;
  const [formState, inputHandler] = useForm();

  const submitHandler = async event => {
    event.preventDefault();
    try {
      const body = JSON.stringify({
        code1: formState.inputs.code1.value,
        code2: formState.inputs.code2.value,
        score: formState.inputs.score.value,
        feedback: formState.inputs.feedback.value,
        feedback_fr: formState.inputs.feedback_fr.value,
      })
      const response = await sendRequest(`${baseUrl}/scenarios/${scenarioId}/combos`, 'POST', body, {
        Authorization: 'Bearer ' + auth.token,
        'Content-Type': 'application/json',
      });
      props.updateCombos({ key: response._id, ...JSON.parse(body) });
      props.handleComboCancel()
      toast('Combo added successfully')
    } catch (err) {
      console.log(err);
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const codes = props.codes.map(code => {
    return {label: code.code, value: code.code};
  })

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <form className="form modal-form" onSubmit={submitHandler}>
        <Input
          id="code1"
          element="select"
          label="First Code"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please select a code."
          onInput={inputHandler}
          options={[{label: 'Select a code', value: ''}, ...codes]}
        />
        <Input
          id="code2"
          element="select"
          label="Second Code"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please select a code."
          onInput={inputHandler}
          options={[{label: 'Select a code', value: ''}, ...codes]}
        />
        <Input
          id="score"
          element="input"
          label="Score"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Score should be a number (positive or negative)."
          onInput={inputHandler}
        />
        <Input
          id="feedback"
          element="textarea"
          rows={4}
          label="Feedback"
          validators={[VALIDATOR_MINLENGTH(15)]}
          errorText="Please enter a valid feedback (at least 15 characters)."
          onInput={inputHandler}
        />
        <Input
          id="feedback_fr"
          element="textarea"
          rows={4}
          label="Feedback(Fr)"
          validators={[VALIDATOR_MINLENGTH(15)]}
          errorText="Please enter a valid feedback (at least 15 characters)."
          onInput={inputHandler}
        />
        <Button type="submit" disabled={!formState.isValid} fullWidth={true} marginClass='mt-30'>
          Add Combo
        </Button>
      </form>
    </React.Fragment>
  );
};

export default CreateCombo;
