import React from "react";

import Modal from "./Modal";
import Button from "../FormElements/Button";
import { staticContent, language } from "../../data/dictionary";

const ErrorModal = (props) => {
  const error = props.error;
  return (
    <Modal
      onCancel={props.onClear}
      header="An Error Occurred!"
      show={!!props.error}
      footer={<Button onClick={props.onClear}>Okay</Button>}
    >
      <p>
        {error in staticContent[language]
          ? staticContent[language][error]
          : error}
      </p>
    </Modal>
  );
};

export default ErrorModal;
