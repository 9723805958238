import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { useForm } from '../../shared/hooks/form-hook';
import Input from '../../shared/components/FormElements/Input';
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH
} from '../../shared/util/validators';
import Button from '../../shared/components/FormElements/Button';
import '../../shared/components/FormElements/Form.css';
import { toast } from 'react-toastify';
import { staticContent, language } from "../../shared/data/dictionary";

// import ErrorModal from '../../shared/components/UIElements/ErrorModal';
// import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';

const CreateCode = (props) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();
  const scenarioId = props.scenarioId;
  const baseUrl = process.env.REACT_APP_API_URL;
  const [formState, inputHandler] = useForm();


  const submitHandler = async event => {
    event.preventDefault();
    try {
      const body = {
        code: formState.inputs.code.value,
        description: formState.inputs.description.value
      }
      await sendRequest(`${baseUrl}/scenarios/${scenarioId}/codes`, 'POST', JSON.stringify(body), {
        Authorization: 'Bearer ' + auth.token,
        'Content-Type': 'application/json',
      });
      // history.push(`scenarios/${scenarioId}/combos/edit`);
      props.updateCodes(body);
      props.handleCodeCancel()
      toast('Code added successfully')
    } catch (err) {
      console.log(err)
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <React.Fragment>
      {/* <ErrorModal error={error} onClear={clearError} /> */}
      <form className="form modal-form" onSubmit={submitHandler}>
        <Input
          id="code"
          element="input"
          label="Code"
          validators={[VALIDATOR_REQUIRE()]}
          errorText={staticContent[language]?.required_field}
          onInput={inputHandler}
        />
        <Input
          id="description"
          element="textarea"
          label="Description"
          validators={[VALIDATOR_MINLENGTH(5)]}
          errorText={staticContent[language]?.required_field}
          onInput={inputHandler}
        />
        <Button type="submit" disabled={!formState.isValid} fullWidth={true} marginClass='mt-30'>
          Add Code
        </Button>
      </form>
    </React.Fragment>
  );
};

export default CreateCode;
