// @ts-ignore
import Button from "react-bootstrap/Button";
import { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { slidesActions } from "../store/scenario";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Table } from "antd";
import parse from "html-react-parser";
import { staticContent, language } from "../shared/data/dictionary";
import { Link } from "react-router-dom";
import { CloseCircleOutlined, ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";


//var csv is the CSV file with headers
function CSVtoJSON(csv: string) {
  var lines = csv.split("\n");
  var result = [];
  var headers = lines[0].split(",");

  for (var i = 1; i < lines.length; i++) {
    var obj: any = {};
    var currentline = lines[i].split(",");

    obj["key"] = "t-" + Math.floor(Math.random() * 1000);

    for (var j = 0; j < headers.length; j++) {
      obj[headers[j].replace("\r", "")] = currentline[j];
    }

    result.push(obj);
  }

  //return result; //JavaScript object
  return JSON.stringify(result); //JSON
}

function Slide(props: any) {
  // const [zoom, setZoom] = useState<boolean>(false);
  // const baseUrl = process.env.REACT_APP_API_URL;
  const slideId = props.id;
  const uploadUrl = process.env.REACT_APP_UPLOADS_URL;
  const [table, setTable] = useState<any>();
  const rejectBtn = false // manually remove reject button, maybe later it needs to be back

  let imageSrc = "";
  if (props.image) {
    imageSrc = uploadUrl + props.image;
  }

  let text_length = 375; // charachters
  if (props.image) {
    text_length = 70;
  }
  const text = props?.text?.substring(0, text_length);

  let slideClasses = "slide";
  if (props.image) {
    slideClasses = slideClasses + " data-slide";
  }

  const slideRef = useRef<HTMLDivElement>(null);

  const typeClass = props.type + "-item";

  const dispatch = useDispatch();

  // const colorHandler = (color: string) => {
  //   dispatch(slidesActions.changeColor({ id: props.id, color: color }));
  // };

  const toggleRejectHandler = () => {
    dispatch(slidesActions.toggleReject({ id: props.id }));
    toast("The slide is rejected and is moved to the end of bank slides list.");
  };

  const scenario = useSelector((state: any) => state.scenario);
  const selectedContainerSlides = scenario.slides.selectedContainer;
  const selectedContainerSlidesObj = selectedContainerSlides.map((slide: any) =>
    JSON.parse(slide)
  );
  const slidesCount = selectedContainerSlidesObj.filter((slide: any) => {
    return slide.type == props?.type?.replace("-separator", "");
  }).length;
  const maxAllowed =
    scenario.scenarioSetting["max_" + props.type.replace("-separator", "")];

  const isInDeck = selectedContainerSlidesObj.find(
    (slide: any) => slide.id === props.id
  );

  useEffect(() => {
    let dataSource: any = [];
    if (props.table) {
      dataSource = JSON.parse(CSVtoJSON(props.table));

      var counter = 0;
      for (const key in dataSource) {
        if (counter >= 6) {
          break;
        }
        let row = dataSource[key];
        for (const key2 in row) {
          dataSource[key][key2] = parse(row[key2]);
        }
        counter += 1;
      }
    }

    let columns = [];
    for (const key2 in dataSource[0]) {
      if (key2 === "key") continue;
      columns.push({
        title: key2,
        dataIndex: key2,
        key: key2,
      });
    }

    const showTable = (
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        bordered
        className="scale"
        key="table-al"
      />
    );

    setTable(showTable);
  }, [props.table]);

  const onSelect = (event: any) => {
    // const isInDeck = event.target.innerText === "Unselect" ? true : false;

    dispatch(
      slidesActions.toggleSelect({
        id: props.id,
        isInDeck: isInDeck,
      })
    );

    dispatch(slidesActions.validateDeck());

    // notification and audio
    const message = isInDeck
      ? "The slide is moved to bank slides."
      : "The slide is moved to deck slides.";
    toast(message);
  };

  // const textt = text?.replace(RegExp("\n", "g"), "<br>");

  const onZoom = () => {
    props.onZoomClicked();
  };

  const imageInfoHandler = () => {
    props.showImageLongDescriptionDialog(props.image_description);
  };

  const deleteSlideHandler = () => {
    props.deleteSlideHandler(slideId);
  };

  return (
    <div className={slideClasses} ref={slideRef} key={props.id} id={props.id}>
      <span className="close-slide-icon" onClick={onZoom}>
        <CloseCircleOutlined />
      </span>
      {/* <div className={classNames("header", typeClass)}>
        {staticContent[language][props.type]}
      </div> */}
      <h3 className="title">{props.title}</h3>
      <p
        className={`text ${props.zoomed && !props.text ? "zerowidth" : ""}  ${
          props.zoomed && (props.table || !props.image) ? "full-width" : ""
        } `}
      >
        {text}
      </p>
      {props.table && table}
      {props.image && (
        <div className="img-wrapper">
          <img
            className="banner"
            src={imageSrc}
            alt={staticContent[language].slide_img_alt}
          />
          <div className="image-overlay"></div>
          {props.imageDescriptionBtn && (
            <Button
              className="btn-gray-border image-description-btn"
              onClick={imageInfoHandler}
              tabIndex={0}
              aria-label="long-description button"
            >
              {/* <InfoOutlined /> */}i
            </Button>
          )}
        </div>
      )}
      {props.footer && (
        <div className="actions">

          {props.slideDeleteBtn && (
            <Button
              variant="danger"
              className="btn-sm margin-right-auto"
              onClick={deleteSlideHandler}
            >
              Delete
            </Button>
          )}

          {props.slideEditBtn && (
            <Link to={`/slides/${props.id}/edit`}>
              <Button variant="dark" className="btn-sm">
                Edit Slide
              </Button>
            </Link>
          )}

          {props.theme && <span className="theme">{props.theme}</span>}
          
          <div className="slide-type">
           {staticContent[language][props.type]}
          </div>

          {props.tags && (
            <div className="tags">
              { rejectBtn &&
              <Button
                className="btn-gray-border margin-right-3 reject-btn"
                onClick={toggleRejectHandler}
                tabIndex={0}
              >
                {props.rejected
                  ? staticContent[language].unReject
                  : staticContent[language].reject}
              </Button>
              }
              <Button
                className="btn-gray-border margin-right-3 zoom-btn"
                onClick={onZoom}
                tabIndex={0}
              >
                {props.zoomed
                  ? <ZoomOutOutlined />
                  : <ZoomInOutlined />}
              </Button>

              {isInDeck && props.unSelectBtn && (
                <Button
                  className="btn-blue-fill btn-select"
                  onClick={onSelect}
                  tabIndex={0}
                >
                  {staticContent[language].unSelect}
                </Button>
              )}
              {!isInDeck && (
                <Button
                  className="btn-blue-fill btn-select"
                  onClick={onSelect}
                  tabIndex={0}
                >
                  {staticContent[language].select}
                </Button>
              )}
            </div>
          )}
          <img
            className="disregard"
            src="/assets/prohibition-symbol.png"
            alt=""
          />
        </div>
      )}

      {props.score >= 0 && (
        <div
          className={`feedback-score individual-feedback ${
            props.score >= 0 ? "positive-score" : "negative-score"
          } margin-top-25`}
        >
          <span>+{props.score} points</span>
        </div>
      )}

      {props.type.includes("separator") && (
        <div
          className={classNames(
            "slidesCounter",
            slidesCount > maxAllowed && "error"
          )}
        >
          {slidesCount}/{maxAllowed}
        </div>
      )}
    </div>
  );
}

export default Slide;
