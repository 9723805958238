import React, { useContext } from 'react';
import { AuthContext } from "../../shared/context/auth-context";
// import { useAuth } from "./shared/hooks/auth-hook";

import './ScenariosListPlayer.css';

import ScenarioItemPlayer from './ScenarioItemPlayer';
import { Card } from 'antd';
import { Translate } from '../../shared/util/translator'
import { staticContent } from '../../shared/data/dictionary'

const language = localStorage.getItem("locale");

const ScenariosListPlayer = props => {

  // const { token, login, logout, userId } = useAuth();
  const auth = useContext(AuthContext);
  if (props.items.length === 0) {
    return (
      <div className="center">
        <Card>
          <h2>{staticContent[language].notFoundScenarios}</h2>
        </Card>
      </div>
    );
  }

  return (
    <div className="scenarios-list">
      {
      props.items.map(scenario => {
        
        let selected = false
        if(props.selectedScenarios){
          selected = props.selectedScenarios.some( each => each['id'] === scenario.id )
        }
        return (
          <ScenarioItemPlayer
            key={scenario.id + scenario.class_id}
            id={scenario.id}
            title={Translate(scenario, 'title')}
            description={Translate(scenario, 'description')}
            image={scenario.image}
            stats={props.stats}
            classId={scenario.class_id}
            classTitle={scenario.class_title}
            lastAttemptDate={scenario.last_attempt_date}
            score={scenario.score}
            remainingAttempts={scenario.remaining_attempts}
            selectbtn={props.selectbtn}
            onScenarioSelected={props.onScenarioSelected}
            selected={selected}
            topPlays={scenario.topPlays}
          />
        )
      }
      )}
    </div>
  );
};

export default ScenariosListPlayer;
