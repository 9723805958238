import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

import { Row, Col } from "antd";
import "./ScenarioStats.css";
import { staticContent } from "../../shared/data/dictionary";

const language = localStorage.getItem("locale");
const ScenarioStats = ({ stats }) => {
  return (
    <div className="scenario-stats">
      <h5>{staticContent[language]?.scenario_stats}</h5>
      <Row>
        <Col span={24}>
          <Row>
            <Col className="each-stat" span={8}>
              <span className="number">
                {(stats.submittedCount / stats.totalPlayersCount).toFixed(2)}
              </span>
              <span className="label">
                {staticContent[language]?.students_attempted}
              </span>
            </Col>
            <Col className="each-stat" span={8}>
              <span className="number">{stats.avgAttempCount.toFixed(2)}</span>
              <span className="label">
                {staticContent[language]?.attempts_per_student}
              </span>
            </Col>
            <Col className="each-stat brder-bottom" span={8}>
              <span className="number">
                {stats.avgScore.toFixed(2)}/{stats.maxScore}
              </span>
              <span className="label">
                {staticContent[language]?.average_score}
              </span>
            </Col>
          </Row>
        </Col>
        {/* <Col className="each-stat large-stat brder-bottom brder-right" span={12}>

        </Col> */}
      </Row>
    </div>
  );
};

export default ScenarioStats;
