import React, { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { AuthContext } from "../../context/auth-context";
import "./NavLinks.css";
import { Avatar, Image, Menu, Dropdown } from "antd";
import { UserOutlined, DribbbleOutlined } from "@ant-design/icons";
import { staticContent } from "../../data/dictionary";

const language = localStorage.getItem("locale");

const NavLinks = (props) => {
  const auth = useContext(AuthContext);
  const uploadUrl = process.env.REACT_APP_UPLOADS_URL;
  const [locale, setLocale] = useState(null);

  useEffect(() => {
    setLocale(localStorage.getItem("locale"));
  }, []);

  useEffect(() => {
    localStorage.setItem("locale", locale);
  }, [locale]);

  const handleMenuLangClick = (e) => {
    setLocale(e.key);
    location.reload();
  };

  const menuLang = (
    <Menu onClick={handleMenuLangClick} selectable>
      <Menu.Item key="en">English</Menu.Item>
      <Menu.Item key="fr">French</Menu.Item>
    </Menu>
  );

  return (
    <ul className="nav-links">

      {auth.isLoggedIn && props.role == "player" && (
        <>
          <li>
            <NavLink to="/dashboard">
              {staticContent[language]?.dashboard}
            </NavLink>
          </li>
        </>
      )}
      {!auth.isLoggedIn && (
        <li>
          <NavLink to="/auth">{staticContent[language]?.login}</NavLink>
        </li>
      )}
      {auth.isLoggedIn && props.role == "player" && (
        <li>
          <NavLink to="/account" className="avatar-link">
            <Avatar
              src={
                <Image
                  size="large"
                  src={
                    props.avatar
                      ? uploadUrl + props.avatar
                      : "https://joeschmoe.io/api/v1/random"
                  }
                  style={{ width: "100%" }}
                />
              }
            />{" "}
            {staticContent[language]?.account}
          </NavLink>
        </li>
      )}
      {auth.isLoggedIn && (
        <li>
          <button onClick={auth.logout}>
            {staticContent[language]?.logout}
          </button>
        </li>
      )}

      {props.role != "admin" && (
        <Dropdown.Button
          className="dropDown dark"
          overlay={menuLang}
          placement="bottomCenter"
          icon={<DribbbleOutlined />}
        >
          {locale === "fr" ? "French" : "English"}
        </Dropdown.Button>
      )}
    </ul>
  );
};

export default NavLinks;
