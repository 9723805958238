import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
} from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import "../../shared/components/FormElements/Form.css";
import { staticContent, messages, language } from '../../shared/data/dictionary';

import ScenariosListInstructor from "../../scenario/components/ScenariosListInstructor";
import { toast } from "react-toastify";

const EditClass = () => {
  const auth = useContext(AuthContext);
  const [loadedScenarios, setLoadedScenarios] = useState();
  const [loadedClass, setLoadedClass] = useState();
  const classHttp = useHttpClient();
  const scenariosHTTP = useHttpClient();
  const formHttp = useHttpClient();
  const classid = useParams().id;
  const history = useHistory();
  const baseUrl = process.env.REACT_APP_API_URL
  const [formState, inputHandler, setFormData] = useForm();
  const [selectedScenarios, setSelectedScenarios] = useState();


  // get class info from api
  useEffect(() => {
    const fetchClass = async () => {
      try {
        const responseData = await classHttp.sendRequest(
          `${baseUrl}/classes/${classid}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        // console.log("responseData ids", responseData.scenarios.map(scenario => scenario.id))
        setLoadedClass(responseData);
        setSelectedScenarios(responseData.scenarios.map(scenario => scenario.id))
        const [formState, inputHandler] = useForm(
          {
            title: {
              value: responseData.title,
              isValid: true,
            },
            description: {
              value: responseData.description,
              isValid: true,
            },
          },
          true
        );
        
        // setSelectedScenarios(responseData.scenarios.map(scenario => scenario.id));

        // console.log("responseData.map(scenario => scenario.id)", responseData.scenarios.map(scenario => scenario.id))

      } catch (err) {
        // console.log("fetchClass err", err);
      }
    };
    fetchClass();
  }, [classHttp.sendRequest]);


  useEffect(() => {
    console.log("selectedScenarios changed", selectedScenarios)
  }, [selectedScenarios])

  // get scenarios from api
  useEffect(() => {
    const fetchScenarios = async () => {
      try {
        const responseData = await scenariosHTTP.sendRequest(
          baseUrl+"/scenarios",
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setLoadedScenarios(responseData);
      } catch (err) {
        // console.log("fetchScenarios err", err);
      }
    };
    fetchScenarios();
  }, [scenariosHTTP.sendRequest]);

  // add selected scenarios id to an array
  const handleScenarioSelect = (scenario_id) => {

    setSelectedScenarios(prev => {
      // console.log("prev", prev)
      if(prev.includes(scenario_id)){
        const index = prev.indexOf(scenario_id);
        prev.splice(index, 1);
      }else{
        prev.push(scenario_id)
      }
      return prev;
    });

  }

  // form submit handler
  const classSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      const body = JSON.stringify({
        title: formState.inputs.title.value,
        description: formState.inputs.description.value,
        title_fr: formState.inputs.title_fr.value,
        description_fr: formState.inputs.description_fr.value,
        scenarios: selectedScenarios
      });
      const response = await formHttp.sendRequest(
        `${baseUrl}/classes/${classid}`,
        "PATCH",
        body,
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        }
      );
      toast(staticContent[language].updated_successfully);
      // history.push('/classes');
    } catch (err) {
      console.log(err);
    }
  };

  // create component
  return (
    <React.Fragment>
      <ErrorModal
        error={scenariosHTTP.error}
        onClear={scenariosHTTP.clearError}
      />
      <ErrorModal error={classHttp.error} onClear={classHttp.clearError} />
      {classHttp.isLoading && (
        <div className="center">
          <LoadingSpinner asOverlay panelOpen={true}/>
        </div>
      )}
      <br />
      {!classHttp.isLoading && loadedClass && (
        <form className="form" onSubmit={classSubmitHandler}>
          <h4 className="form-title">Edit class</h4>
          <Input
            id="title"
            element="input"
            type="text"
            label="Title"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter a valid title."
            onInput={inputHandler}
            initialValue={loadedClass.title}
            initialValid={true}
          />
          <Input
            id="title_fr"
            element="input"
            type="text"
            label="Title (FR)"
            validators={[]}
            errorText="Please enter a valid title."
            onInput={inputHandler}
            initialValue={loadedClass.fr?.title}
            initialValid={true}
          />
          <Input
            id="description"
            element="textarea"
            label="Description"
            validators={[VALIDATOR_MINLENGTH(5)]}
            errorText={staticContent[language].min_lenght}
            onInput={inputHandler}
            initialValue={loadedClass.description}
            initialValid={true}
          />
          <Input
            id="description_fr"
            element="textarea"
            label="Description (FR)"
            validators={[]}
            errorText={staticContent[language].min_lenght}
            onInput={inputHandler}
            initialValue={loadedClass.fr?.description}
            initialValid={true}
          />
          <br />
          <h5>{staticContent[language].selectScenario}</h5>
          {!scenariosHTTP.isLoading && loadedScenarios && (
            <ScenariosListInstructor
              onScenarioSelected={handleScenarioSelect}
              items={loadedScenarios}
              stats={false}
              selectbtn={true}
              morebtn={false}
              selectedScenarios={loadedClass.scenarios}
            />
          )}
          <Button
            type="submit"
            disabled={!formState.isValid}
            fullWidth={true}
            marginClass="mt-30"
          >
            {staticContent[language]?.update}
          </Button>
        </form>
      )}
    </React.Fragment>
  );
};

export default EditClass;
