import React, { useReducer, useEffect } from "react";

import { validate } from "../../util/validators";
import { Checkbox } from 'antd';
import "./Input.css";

const inputReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH": {
      return {
        ...state,
        isTouched: true,
      };
    }
    default:
      return state;
  }
};

const Input = (props) => {

  
  const inputStateObj = {
    value: props.initialValue != null ? props.initialValue : "",
    isTouched: false,
    isValid: props.initialValid || false,
  }

  if(props.element == 'checkbox'){
    inputStateObj.value = props.initialValue
  }

  const [inputState, dispatch] = useReducer(inputReducer, inputStateObj);

  const { id, onInput } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  const changeHandler = (event) => {
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: props.validators,
    });
  };

  const changeHandlerCheckbox = (event) => {
    dispatch({
      type: "CHANGE",
      val: event.target.checked,
      validators: props.validators,
    });
  };

  const touchHandler = () => {
    dispatch({
      type: "TOUCH",
    });
  };

  let element;

  switch (props.element) {
    case "input":
      element = (
        <input
          id={props.id}
          type={props.type}
          placeholder={props.placeholder}
          onChange={changeHandler}
          onBlur={touchHandler}
          value={ inputState.value }
        />
      );
      break;
    case "checkbox":
      element = (
      <input
        id={props.id}
        type='checkbox'
        onChange={changeHandlerCheckbox}
        onBlur={touchHandler}
        value={ inputState.value }
        checked={ inputState.value }
      />);
      break;
    case "textarea":
      element = (
        <textarea
          id={props.id}
          rows={props.rows || 3}
          onChange={changeHandler}
          onBlur={touchHandler}
          value={ inputState.value }
        >{inputState.value}</textarea>
      );
      break;

    case "select":
      element = (
        <select
          id={props.id}
          onChange={changeHandler}
          onBlur={touchHandler}
          value={inputState.value}
        >
          {props.options.map((option, i) => {
            return <option key={option.value} value={option.value}>{option.label}</option>;
          })}
        </select>
      );
      break;
  }
  // const element =
  //   props.element === "input" ? (
  //     <input
  //       id={props.id}
  //       type={props.type}
  //       placeholder={props.placeholder}
  //       onChange={changeHandler}
  //       onBlur={touchHandler}
  //       value={inputState.value}
  //     />
  //   ) : (
  //     <textarea
  //       id={props.id}
  //       rows={props.rows || 3}
  //       onChange={changeHandler}
  //       onBlur={touchHandler}
  //       value={inputState.value}
  //     />
  //   );

  return (
    <div
      className={`input-wrapper ${
        !inputState.isValid && inputState.isTouched && "input-wrapper--invalid"
      }`}
    >
      {props.element != 'checkbox' && 
      <label htmlFor={props.id}>{props.label} {props.guide && <small>({props.guide})</small>} </label> 
      }
      {element}
      {props.element == 'checkbox' && 
      <label className="checkbox-label" htmlFor={props.id}>{props.label} {props.guide && <small>({props.guide})</small>} </label> 
      }
      {!inputState.isValid && inputState.isTouched && <p>{props.errorText}</p>}
    </div>
  );
};

export default Input;
