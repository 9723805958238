/* eslint-disable */
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import {
  CancelDrop,
  closestCenter,
  CollisionDetection,
  DndContext,
  DragOverlay,
  DropAnimation,
  defaultDropAnimation,
  KeyboardSensor,
  Modifiers,
  PointerSensor,
  NamanSensor,
  useDroppable,
  UniqueIdentifier,
  useSensors,
  useSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  arrayMove,
  rectSortingStrategy,
  SortingStrategy,
} from "@dnd-kit/sortable";

import { namanKeyboardCoordinates } from "./namanKeyboardCoordinates";

import { Item, List } from "./components";

import { setTimeout } from "timers";
import KeyboardGuide from "./KeyboardGuide";
import { useSelector, useDispatch } from "react-redux";
import { slidesActions } from "../../store/scenario";
// import { DroppableContainer } from "./dndHelper";

// helper
const arraysEqual = (a: any, b: any) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

function DroppableContainer({
  children,
  columns = 1,
  id,
  items,
  getStyle = () => ({}),
  fadeIn,
  expandedContainer,
}: {
  children: React.ReactNode;
  columns?: number;
  id: string;
  items: string[];
  getStyle: ({
    isOverContainer,
  }: {
    isOverContainer: boolean;
  }) => React.CSSProperties;
  fadeIn?: boolean;
  expandedContainer?: string;
}) {
  const { over, isOver, setNodeRef } = useDroppable({
    id,
  });
  const isOverContainer = isOver || (over ? items.includes(over.id) : false);

  // set number of grid columns for each container

  const [columnCount, setColumnCount] = useState(
    id === "bankContainer" ? columns : 1
  );
  const slideZoomActive = useSelector((state:any) => state.scenario.slideZoomActive);

  useEffect(() => {
    if (expandedContainer === "bankContainer") {
      columns = id === "bankContainer" ? columns : 1;
    } else {
      columns = id === "bankContainer" ? 1 : 1;
    }

    setColumnCount(columns);
  }, [expandedContainer]);

  const itemsObj = items.map((slide) => JSON.parse(slide));
  const separators_ids = [
    "background-separator",
    "data-separator",
    "insight-separator",
  ];
  const filterSeparators = itemsObj.filter(
    (slide) => !separators_ids.includes(slide.type)
  );

  const showEmpty = id === "selectedContainer" && filterSeparators.length == 0;

  return (
    <List
      ref={setNodeRef}
      style={getStyle({ isOverContainer })}
      columns={columnCount}
      id={id}
      fadeIn={fadeIn}
      slideZoomActive={slideZoomActive}
    >
      {showEmpty ? <KeyboardGuide></KeyboardGuide> : children}
    </List>
  );
}

export const defaultContainerStyle = ({
  isOverContainer,
}: {
  isOverContainer: boolean;
}) => ({
  marginTop: 40,
  backgroundColor: isOverContainer
    ? "rgb(235,235,235,1)"
    : "rgba(246,246,246,1)",
});

const dropAnimation: DropAnimation = {
  ...defaultDropAnimation,
  dragSourceOpacity: 0.5,
};

type Items = Record<string, string[]>;

interface Props {
  // slidesData: SlideType[];
  filterBy: string[];
  sortBy: string;
  deckResort: boolean;
  adjustScale?: boolean;
  cancelDrop?: CancelDrop;
  collisionDetection?: CollisionDetection;
  columns?: number;
  getItemStyles?(args: {
    value: UniqueIdentifier;
    index: number;
    overIndex: number;
    isDragging: boolean;
    containerId: UniqueIdentifier;
    isSorting: boolean;
    isDragOverlay: boolean;
  }): React.CSSProperties;
  wrapperStyle?(args: { index: number }): React.CSSProperties;
  getContainerStyle?(args: { isOverContainer: boolean }): React.CSSProperties;
  itemCount?: number;
  items?: Items;
  handle?: boolean;
  renderItem?: any;
  strategy?: SortingStrategy;
  modifiers?: Modifiers;
  trashable?: boolean;
  vertical?: boolean;
  expandedContainer?: string;
  showImageLongDescriptionDialog: any;
}

export const VOID_ID = "void";

export function CoreLoopContainers({
  // slidesData,
  filterBy = ["all"],
  sortBy = "type",
  deckResort = false,
  adjustScale = false,
  itemCount = 3,
  cancelDrop,
  collisionDetection = closestCenter,
  columns,
  handle = false,
  items: initialItems,
  getItemStyles = () => ({}),
  getContainerStyle = defaultContainerStyle,
  wrapperStyle = () => ({}),
  modifiers,
  renderItem,
  strategy = rectSortingStrategy,
  trashable = false,
  vertical = false,
  expandedContainer = "bankContainer",
  showImageLongDescriptionDialog,
}: Props) {
  const dispatch = useDispatch();

  const items = useSelector((state: any) => state.scenario.slides);


  const [fadeIn, setFadeIn] = useState<boolean>(true);
  const [clonedItems, setClonedItems] = useState<Items | null>(null);
  const [activeId, setActiveId] = useState<string | null>(null);

  // filter
  useEffect(() => {

    const addFilterdClassesBankItems = items.bankContainer.map(function (
      slide: any
    ) {
      const slideObj = JSON.parse(slide);
      slideObj.filtered = true;

      if (filterBy.length === 0) {
        slideObj.filtered = false;
      }

      const slide_rejected = slideObj.rejected ? "rejected" : "not-rejected";

      if (
        filterBy.includes(slideObj.type) ||
        filterBy.includes(slideObj.color) || 
        (slideObj.theme && filterBy.includes(slideObj.theme)) ||
        filterBy.includes(slide_rejected)
      ) {
        // console.log("Do not filter");
        slideObj.filtered = false;
      }

      return JSON.stringify(slideObj);
    });

    setFadeIn(false);
    setTimeout(() => {
      setFadeIn(true);
    }, 1000);

    // setItems((prevState) => {
    //   return {
    //     ...prevState,
    //     bankContainer: addFilterdClassesBankItems,
    //   };
    // });

    const updatedItems = {
      ...items,
      bankContainer: addFilterdClassesBankItems,
    };

    dispatch(slidesActions.updateItems(updatedItems));
  }, [filterBy]);

  const resort = (sorting: string, deck: boolean, items: any) => {

    let container = "bankContainer";
    if(deck){
      container = "selectedContainer"
    }

    const containerObj = items[container].map(function (slide: any) {
      return JSON.parse(slide);
    });

    containerObj.sort((a: any, b: any) => {
      if (a[sorting] < b[sorting]) {
        return -1;
      }
      if (a[sorting] > b[sorting]) {
        return 1;
      }
      return 0;
    });

    const containerStringified = containerObj.map(function (
      slide: object
    ) {
      return JSON.stringify(slide);
    });

    const updatedItems = {
      ...items,
      [container]: containerStringified,
    };

    dispatch(slidesActions.updateItems(updatedItems));
  }

  // reposition deck slides to be in correct type category
  const moveToCorrectPosition = (
    activeType: string,
    activeIndex: number,
    containerObj: any
  ) => {
    const background_sep_index = containerObj.findIndex(
      (o: any) => o.type === "background-separator"
    );
    const data_sep_index = containerObj.findIndex(
      (o: any) => o.type === "data-separator"
    );
    const insight_sep_index = containerObj.findIndex(
      (o: any) => o.type === "insight-separator"
    );

    // console.log(activeType, activeIndex, background_sep_index, data_sep_index)

    if (activeType == "background") {
      if (
        !(activeIndex > background_sep_index && activeIndex <= data_sep_index)
      ) {
        return background_sep_index;
      }
    }

    if (activeType == "data") {
      if (
        !(activeIndex > data_sep_index && activeIndex <= insight_sep_index)
      ) {
        return data_sep_index;
      }
    }

    if (activeType == "insight") {
      if (!(activeIndex > insight_sep_index)) {
        return insight_sep_index;
      }
    }

    return false;
  };

  const repositionDeckSlides = (selectedContainer:any, scroll:boolean) => {
    let selectedContainerObj = selectedContainer.map(function (
      slide: any
    ) {
      return JSON.parse(slide);
    });

    let selectedContainerModified = selectedContainerObj;

    selectedContainerObj.forEach(function (slide: any, index: any) {
      const newPosition = moveToCorrectPosition(
        slide.type,
        index,
        selectedContainerObj
      );
      if (newPosition !== false) {
        selectedContainerModified = arrayMove(
          selectedContainerObj,
          index,
          newPosition
        );
        const selectContainerElement = document.getElementById(
          "selectedContainer"
        ) as HTMLDivElement;
        

        if(scroll){
          let scrollTo = 0;
          if (expandedContainer === "selectedContainer") {
            scrollTo = Math.trunc(newPosition / 3) * 460 - 460;
          }

          if (expandedContainer === "bankContainer") {
            scrollTo = Math.trunc(newPosition / 1) * 460;
            if (slide.type === "background") {
              scrollTo = scrollTo - 460;
            }
            if (slide.type === "data") {
              scrollTo = scrollTo - 2 * 460;
            }
            if (slide.type === "insight") {
              scrollTo = scrollTo - 3 * 460;
            }
          }

          selectContainerElement?.scrollTo({ top: scrollTo, behavior: "smooth" });
        }
        // else{
        //   selectContainerElement?.scrollTo({ top: 0, behavior: "smooth" });
        // }
      }
    });

    if (!arraysEqual(selectedContainerObj, selectedContainerModified)) {
      const selectedContainerModifiedStr = selectedContainerModified.map(
        (slide: object) => {
          return JSON.stringify(slide);
        }
      );

      const updatedItems = {
        ...items,
        selectedContainer: selectedContainerModifiedStr,
      };

      dispatch(slidesActions.updateItems(updatedItems));
    }

    dispatch(slidesActions.validateDeck());
  }



  // sort
  useEffect(() => {
    resort(sortBy, false, items);
  }, [sortBy]);

  // items useEffect reorder deck container
  useEffect(() => {

    repositionDeckSlides(items.selectedContainer, false);

  }, [items.selectedContainer]);


  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
    useSensor(NamanSensor, {}),
    useSensor(KeyboardSensor, {
      coordinateGetter: namanKeyboardCoordinates,
    })
  );
  const findContainer = (id: string) => {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => items[key].includes(id));
  };

  const getIndex = (id: string) => {
    const container = findContainer(id);

    if (!container) {
      return -1;
    }

    const index = items[container].indexOf(id);

    return index;
  };

  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      dispatch(slidesActions.updateItems(clonedItems));
    }

    setActiveId(null);
    setClonedItems(null);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetection}
      onDragStart={({ active }) => {
        setActiveId(active.id);
        setClonedItems(items);
      }}
      onDragOver={({ active, over }) => {

        const overId = over?.id;

        if (!overId) {
          return;
        }

        const overContainer = findContainer(overId);
        const activeContainer = findContainer(active.id);

        if (!overContainer || !activeContainer) {
          return;
        }

        if (activeContainer !== overContainer) {
          const activeItems = items[activeContainer];
          const overItems = items[overContainer];
          const overIndex = overItems.indexOf(overId);
          const activeIndex = activeItems.indexOf(active.id);

          let newIndex: number;

          if (overId in items) {
            newIndex = overItems.length + 1;
          } else {
            const isBelowLastItem =
              over &&
              overIndex === overItems.length - 1 &&
              active.rect.current.translated &&
              active.rect.current.translated.offsetTop >
                over.rect.offsetTop + over.rect.height;

            const modifier = isBelowLastItem ? 1 : 0;

            newIndex =
              overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
          }

          const updatedItems = {
            ...items,
            [activeContainer]: [
              ...items[activeContainer].filter(
                (item: any) => item !== active.id
              ),
            ],
            [overContainer]: [
              ...items[overContainer].slice(0, newIndex),
              items[activeContainer][activeIndex],
              ...items[overContainer].slice(
                newIndex,
                items[overContainer].length
              ),
            ],
          };

          dispatch(slidesActions.updateItems(updatedItems));
        }
      }}
      onDragEnd={({ active, over }) => {

        // container that the item is dragged on
        const activeContainer = findContainer(active.id);

        if (!activeContainer) {
          setActiveId(null);
          return;
        }

        const overId = over?.id || VOID_ID;

        if (overId === VOID_ID) {
          dispatch(
            slidesActions.updateItems({
              ...(trashable && over?.id === VOID_ID ? items : clonedItems),
              [VOID_ID]: [],
            })
          );
          setActiveId(null);
          return;
        }

        const overContainer = findContainer(overId);

        if (activeContainer && overContainer) {
          // from index
          const activeIndex = items[activeContainer].indexOf(active.id);

          // to index
          const overIndex = items[overContainer].indexOf(overId);

          if (activeIndex !== overIndex) {

            dispatch(
              slidesActions.updateItems({
                ...items,
                [overContainer]: arrayMove(
                  items[overContainer],
                  activeIndex,
                  overIndex
                ),
              })
            );
          }
        }

        setActiveId(null);
      }}
      cancelDrop={cancelDrop}
      onDragCancel={onDragCancel}
      modifiers={modifiers}
      autoScroll={{
        // Accelerate slower than the default value (10)
        acceleration: 5,
        // Auto-scroll every 10ms instead of the default value of 5ms
        interval: 10,
      }}
    >
      <div
        style={{
          display: "block",
          boxSizing: "border-box",
          padding: "0px 0px",
          gridAutoFlow: vertical ? "row" : "column",
        }}
      >
        {Object.keys(items)
          .filter((key) => key !== VOID_ID)
          .map((containerId) => (
            <SortableContext
              key={containerId}
              items={items[containerId]}
              strategy={strategy}
            >
              <DroppableContainer
                id={containerId}
                columns={columns}
                items={items[containerId]}
                getStyle={getContainerStyle}
                fadeIn={fadeIn}
                expandedContainer={expandedContainer}
              >
                {items[containerId].map((value: any, index: any) => {
                  return (
                    <SortableItem
                      key={value}
                      id={value}
                      index={index}
                      handle={handle}
                      style={getItemStyles}
                      wrapperStyle={wrapperStyle}
                      renderItem={renderItem}
                      containerId={containerId}
                      getIndex={getIndex}
                      showImageLongDescriptionDialog={showImageLongDescriptionDialog}
                    />
                  );
                })}
              </DroppableContainer>
            </SortableContext>
          ))}
      </div>
      {createPortal(
        <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
          {activeId ? (
            <Item
              value={activeId}
              handle={handle}
              style={getItemStyles({
                containerId: findContainer(activeId) as string,
                overIndex: -1,
                index: getIndex(activeId),
                value: activeId,
                isSorting: activeId !== null,
                isDragging: true,
                isDragOverlay: true,
              })}
              wrapperStyle={wrapperStyle({ index: 0 })}
              renderItem={renderItem}
              showImageLongDescriptionDialog={showImageLongDescriptionDialog}
              dragOverlay
            />
          ) : null}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );
}

interface SortableItemProps {
  containerId: string;
  id: string;
  index: number;
  handle: boolean;
  style(args: any): React.CSSProperties;
  getIndex(id: string): number;
  renderItem(): React.ReactElement;
  wrapperStyle({ index }: { index: number }): React.CSSProperties;
  showImageLongDescriptionDialog: any;
}

function SortableItem({
  id,
  index,
  handle,
  renderItem,
  style,
  containerId,
  getIndex,
  wrapperStyle,
  showImageLongDescriptionDialog,
}: SortableItemProps) {
  const {
    setNodeRef,
    listeners,
    isDragging,
    isSorting,
    over,
    overIndex,
    transform,
    transition,
  } = useSortable({
    id,
  });
  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;

  return (
    <Item
      ref={setNodeRef}
      value={id}
      dragging={isDragging}
      sorting={isSorting}
      handle={handle}
      index={index}
      wrapperStyle={wrapperStyle({ index })}
      style={style({
        index,
        value: id,
        isDragging,
        isSorting,
        overIndex: over ? getIndex(over.id) : overIndex,
        containerId,
      })}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      renderItem={renderItem}
      showImageLongDescriptionDialog={showImageLongDescriptionDialog}
    />
  );
}

function useMountStatus() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  return isMounted;
}
