import React, { useContext } from "react";

import { Link } from "react-router-dom";
import { AuthContext } from "../../shared/context/auth-context";
import { Row, Col, Layout } from "antd";
const { Header, Content, Footer } = Layout;
import "./home.css";
import { staticContent, language } from '../data/dictionary';
import Container from "../components/UIElements/Container";
import AuthForm from "../../user/components/AuthForm";

const Home = () => {
  const auth = useContext(AuthContext);
  const baseUrl = process.env.REACT_APP_API_URL;

  return (
    <React.Fragment>
      <div className="home-page">
        <section id="hero-section">
          <Container variant="dark">
            <div className="wrapper">
              <div className="left-side">
                <p className="welcome-text">Welcome to Data Literacy Game</p>
                <h1>Learn<br/> Data Literacy<br/>Through Game</h1>
                <p className="sub-headline"><div dangerouslySetInnerHTML={{ __html: staticContent[language].purpose_data_literacy_game }} /></p>
              </div>
              <div className="right-side">
                <h2 className="form-legend">{staticContent[language].are_you_instructor}</h2>
                <AuthForm isLoginMode={false} authType="instructor" formStyle="dark-form" switchBtn={false} submitBtnText={staticContent[language].request_access}/>
                <p className="form-note">{staticContent[language].will_contact_you}</p>
              </div>
            </div>
          </Container>
        </section>

      </div>
    </React.Fragment>
  );
};

export default Home;
