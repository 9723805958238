import { Link } from "react-router-dom";
import "./SectionHeader.css";
import { Button } from "antd";
import { staticContent, language } from "../../shared/data/dictionary";
import { useHistory } from "react-router-dom";

const SectionHeader = (props) => {

  let history = useHistory();
  const goToPreviousPath = () => {
    history.goBack();
  };

  return (
    <div className="section-header">
      <h1>
        {props.title}
        {props.subtitle && <p className="subtitle">{props.subtitle}</p>}
      </h1>
      <div className="btns">
        {props.btns &&
          props.btns.map((btn) => (
            <Button type="primary">
              <Link to={btn.link}>{btn.text}</Link>
            </Button>
          ))}
        {props.backBtn && (
          <Button type="primary" onClick={goToPreviousPath}>
            {staticContent[language]?.goBack}
          </Button>
        )}
      </div>
    </div>
  );
};

export default SectionHeader;
