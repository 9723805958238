import React, { useEffect, useState, useContext } from 'react';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

import ScenariosListInstructor from '../components/ScenariosListInstructor';
import ClassesListInstructor from '../../class/components/ClassesListInstructor';
import { staticContent } from '../../shared/data/dictionary';

const language = localStorage.getItem("locale");
const ScenariosInstructor = () => {
  const classHttp = useHttpClient();
  const scenarioHttp = useHttpClient();
  // const { scenariosIsLoading, scenarioRrror, sendRequestScenario, scenarioClearError } = useHttpClient();
  const [loadedScenarios, setLoadedScenarios] = useState();
  const [loadedClasses, setLoadedClasses] = useState();
  const [selectedClassId, setSelectedClassId] = useState();
  const auth = useContext(AuthContext);
  const baseUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {

    const fetchClasses = async () => {
      try {
        const responseData = await classHttp.sendRequest(`${baseUrl}/classes`, 'GET', null, {
          Authorization: 'Bearer ' + auth.token
        });
        setLoadedClasses(responseData.classes);
      } catch (err) {
        console.log("fetchClasses err", err)
      }
    };
    fetchClasses();

  }, [classHttp.sendRequest]);


  const classSelectHandler = (classid) => {

    const fetchScenarios = async () => {
      try {
        const responseData = await scenarioHttp.sendRequest(`${baseUrl}/classes/${classid}`, 'GET', null, {
          Authorization: 'Bearer ' + auth.token
        });
        setSelectedClassId(classid)
        setLoadedScenarios(responseData.scenarios);
      } catch (err) {
        console.log("fetchScenarios err", err)
      }
    };
    fetchScenarios();

  }

  return (
    <React.Fragment>
      <ErrorModal error={classHttp.error} onClear={classHttp.clearError} />
      <ErrorModal error={scenarioHttp.error} onClear={scenarioHttp.clearError} />
      {classHttp.isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}

      <br/>
      <h5>1. {staticContent[language]?.selectClass}</h5>
      {!classHttp.isLoading && loadedClasses && <ClassesListInstructor items={loadedClasses} onSelect={classSelectHandler} settingbtn={false} selectbtn={true} selected={selectedClassId}/>}
      
      <br/>
      <h5>2. {staticContent[language]?.selectScenario}</h5>
      {!scenarioHttp.isLoading && loadedScenarios && selectedClassId && <ScenariosListInstructor items={loadedScenarios} classId={selectedClassId} morebtn={true}/>}
      
    </React.Fragment>
  );
};

export default ScenariosInstructor;
