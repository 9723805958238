import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

import Input from "../../shared/components/FormElements/Input";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
} from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";

import { Card } from "antd";
import { Button } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { staticContent } from "../../shared/data/dictionary";

const language = localStorage.getItem("locale");
const InviteClassInstructor = () => {
  const auth = useContext(AuthContext);
  const [loadedClass, setLoadedClass] = useState();
  const classHttp = useHttpClient();
  const toggleInviteHttp = useHttpClient();
  const classid = useParams().id;
  const baseUrl = process.env.REACT_APP_API_URL;
  let inviteLink = "";
  const [formState, inputHandler] = useForm(
    {
      emails: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const [copyState, setCopyState] = useState({
    value: "",
    copied: false,
  });

  const copyClickHandler = (e) => {
    setCopyState((prevState) => {
      return { ...prevState, copied: true };
    });
  };

  // get class info from api
  useEffect(() => {
    const fetchClass = async () => {
      try {
        const responseData = await classHttp.sendRequest(
          `${baseUrl}/classes/${classid}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setLoadedClass(responseData);
        const domain = baseUrl
          .replace("api.data", "data")
          .replace("/api", "/")
          .replace(":5000", ":3000");

        inviteLink = `${domain}invite/${responseData.token}`;
        setCopyState((prevState) => {
          return { ...prevState, value: inviteLink };
        });
      } catch (err) {
        // console.log("fetchClass err", err);
      }
    };
    fetchClass();
  }, [classHttp.sendRequest]);

  const sendInviteEmailSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      const body = JSON.stringify({
        class_id: loadedClass.classid,
        emails: formState.inputs.emails.value,
      });
      await sendRequest(`${baseUrl}/api/classes`, "POST", body, {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      });
      // history.replace('/classes');
    } catch (err) {
      // console.log(err);
    }
  };

  const toggleInviteOpenHandler = async (event) => {
    event.preventDefault();
    try {
      const body = JSON.stringify({
        class_id: loadedClass.classid,
        emails: formState.inputs.emails.value,
      });
      const response = await toggleInviteHttp.sendRequest(
        `${baseUrl}/classes/${classid}/inviteopen`,
        "PATCH",
        null,
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        }
      );
      location.reload();
    } catch (err) {
      // console.log(err);
    }
  };

  // create component
  return (
    <React.Fragment>
      <ErrorModal error={classHttp.error} onClear={classHttp.clearError} />
      {classHttp.isLoading && (
        <div className="center">
          <LoadingSpinner asOverlay panelOpen={true} />
        </div>
      )}
      <div className="section-header">
        <h1>
          {staticContent[language]?.invitationHeader}
          <p className="sub-headline">
            {staticContent[language]?.invitationSubHeader}
          </p>
        </h1>
        <div className="btns">
          {!classHttp.isLoading && loadedClass && loadedClass.inviteOpen ? (
            <>
              <span>{staticContent[language]?.studentsCanJoin}</span>
              <Button type="default" danger onClick={toggleInviteOpenHandler}>
                {staticContent[language]?.closeTheClass}
              </Button>
            </>
          ) : (
            <>
              <span>{staticContent[language]?.studentsCanNotJoin}</span>
              <Button
                type="default"
                className="btn-black"
                onClick={toggleInviteOpenHandler}
                danger
              >
                {staticContent[language]?.openTheClass}
              </Button>
            </>
          )}
        </div>
      </div>

      {!classHttp.isLoading && loadedClass && (
        <Card
          title={staticContent[language]?.invitationCardFirstTitle}
          style={{ width: "100%" }}
        >
          <p>{staticContent[language]?.invitationCardFirstContent}</p>

          <CopyToClipboard text={copyState.value} onCopy={copyClickHandler}>
            <Button type="primary">
              {staticContent[language]?.copy} {staticContent[language]?.link}
            </Button>
          </CopyToClipboard>
        </Card>
      )}
      <br />
      {/* <p>{staticContent[language]?.or}</p> */}
      {!classHttp.isLoading && loadedClass && false && (
        <Card
          title={staticContent[language]?.invitationCardSecondTitle}
          style={{ width: "100%" }}
        >
          <form
            onSubmit={sendInviteEmailSubmitHandler}
            className="label-no-margin"
          >
            {classHttp.isLoading && <LoadingSpinner asOverlay />}
            <p>{staticContent[language]?.invitationCardSecondContent}</p>
            <Input
              id="emails"
              element="textarea"
              label={staticContent[language]?.email}
              validators={[VALIDATOR_MINLENGTH(5)]}
              errorText={staticContent[language]?.invitationEmailError}
              onInput={inputHandler}
              guide={staticContent[language]?.invitationEmailError}
            />
            <Button
              type="submit"
              disabled={!formState.isValid}
              fullWidth={true}
              marginClass="mt-30"
              type="primary"
            >
              {staticContent[language]?.send}{" "}
              {staticContent[language]?.invitations}
            </Button>
          </form>
        </Card>
      )}
    </React.Fragment>
  );
};

export default InviteClassInstructor;
