import { createSlice } from "@reduxjs/toolkit";
import initialSlidesData from "../data/slidesData";
import { arrayMove } from "@dnd-kit/sortable";
import { staticContent } from '../shared/data/dictionary';
import { stat } from "fs";

const language = localStorage.getItem("locale");

const slides = initialSlidesData.map((slide: any) => {
  slide.color = "gray";
  slide.rejected = false;
  return JSON.stringify(slide);
});

const separators = [
  {
    title: "background slides",
    text: "",
    type: "background-separator",
    id: "background-separator",
    theme: "_",
    fr: {
      title: "background slides (Fr)"
    }
  },
  {
    title: "data slides",
    text: "",
    type: "data-separator",
    id: "data-separator",
    theme: "_",
    fr: {
      title: "data slides (Fr)"
    }
  },
  {
    title: "insight slides",
    text: "",
    type: "insight-separator",
    id: "insight-separator",
    theme: "_",
    fr: {
      title: "insight slides (Fr)"
    }
  },
];

const separators_str = separators.map((separator) => JSON.stringify(separator));

const VOID_ID = "void";
const initialItems = {
  scenario: {
    title: "scenario title",
  },
  slides: {
    bankContainer: slides,
    selectedContainer: separators_str,
  },
  scenarioSetting: {
    max_background: 3,
    max_data: 2,
    max_insight: 1,
  },
  deckErrors: ["You should at least choose one slide"],
  sortBy: "type",
  slideZoomActive: false
};

// helper
const arraysEqual = (a: any, b: any) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};


const slidesSlice = createSlice({
  name: "slides",
  initialState: initialItems,
  reducers: {
    updateItems(state, action) {
    //   const reorderedItems = addSeparators(action.payload);
    //   console.log("reorderedItems");
    //   console.log(reorderedItems);
      state.slides = action.payload;
    },
    addSeparators(state){
        const selectedContainerObj = state.slides.selectedContainer.map((slide) => JSON.parse(slide));

        const backgroundSlides = selectedContainerObj.filter(slide => slide.type == 'background')
        const dataSlides = selectedContainerObj.filter(slide => slide.type == 'data')
        const insightSlides = selectedContainerObj.filter(slide => slide.type == 'insight')

        const updatedSlidesObj = [
            separators[0], ...backgroundSlides, 
            separators[1], ...dataSlides,
            separators[2], ...insightSlides,
        ]

        const updatedSlides = updatedSlidesObj.map(slide => JSON.stringify(slide));

        state.slides.selectedContainer = updatedSlides;
    },
    changeColor(state, action) {
      const slide_id = action.payload.id;
      const color = action.payload.color;

      const bankContainer = state.slides.bankContainer.map((slide) =>
        JSON.parse(slide)
      );

      const slide_index = bankContainer.findIndex(
        (slide: any) => slide.id === slide_id
      );

      bankContainer[slide_index] = {
        ...bankContainer[slide_index],
        color: color,
      };

      state.slides.bankContainer = bankContainer.map((slide) =>
        JSON.stringify(slide)
      );
    },
    toggleReject(state, action) {
      const slide_id = action.payload.id;

      let bankContainer = state.slides.bankContainer.map((slide) =>
        JSON.parse(slide)
      );

      const slide_index = bankContainer.findIndex(
        (slide: any) => slide.id === slide_id
      );

      bankContainer[slide_index] = {
        ...bankContainer[slide_index],
        rejected: !bankContainer[slide_index].rejected,
      };

      if (bankContainer[slide_index].rejected) {
        bankContainer = arrayMove(
          bankContainer,
          slide_index,
          bankContainer.length
        );
      }
      state.slides.bankContainer = bankContainer.map((slide) =>
        JSON.stringify(slide)
      );
    },
    toggleSelect(state, action) {
      const slide_id = action.payload.id;
      const isInDeck = action.payload.isInDeck;

      if (!isInDeck) {
        let bankContainer = state.slides.bankContainer.map((slide) =>
          JSON.parse(slide)
        );
        const slide_index_bank = bankContainer.findIndex(
          (slide: any) => slide.id === slide_id
        );
        const selectedSlide = bankContainer[slide_index_bank];

        let deckContainer = state.slides.selectedContainer.map((slide) =>
          JSON.parse(slide)
        );
        const separator_index = deckContainer.findIndex(
          (slide: any) => slide.type === selectedSlide.type + "-separator"
        );
        
        if (slide_index_bank != undefined) {
          state.slides.bankContainer.splice(slide_index_bank, 1);
          state.slides.selectedContainer.splice(
            separator_index + 1,
            0,
            JSON.stringify(selectedSlide)
          );
        }
      }

      if (isInDeck) {
        let deckContainer = state.slides.selectedContainer.map((slide) =>
          JSON.parse(slide)
        );
        const slide_index_deck = deckContainer.findIndex(
          (slide: any) => slide.id === slide_id
        );
        const selectedSlide = deckContainer[slide_index_deck];
        

        let bankContainer = state.slides.bankContainer.map((slide) =>
          JSON.parse(slide)
        );
        const type_index = bankContainer.findIndex(
          (slide: any) => slide.type === selectedSlide.type
        );

        if (slide_index_deck != undefined) {
          state.slides.selectedContainer.splice(slide_index_deck, 1);
          state.slides.bankContainer.splice(
            type_index,
            0,
            JSON.stringify(selectedSlide)
          );
        }
      }
    },
    validateDeck(state) {
      const deckSlidesObj = state.slides.selectedContainer.map(
        (slide: string) => JSON.parse(slide)
      );

      const backgroundSlides = deckSlidesObj.filter(
        (slide: any) => slide.type === "background"
      );
      const dataSlides = deckSlidesObj.filter(
        (slide: any) => slide.type === "data"
      );
      const insightSlides = deckSlidesObj.filter(
        (slide: any) => slide.type === "insight"
      );

      let errors: string[] = [];

      // check if exact number of slides is not selected for each category
      if (backgroundSlides.length > state.scenarioSetting.max_background) {
        errors.push(
          "You shoud not choose more than " +
            state.scenarioSetting.max_background +
            " background slides"
        );
      }

      if (dataSlides.length > state.scenarioSetting.max_data) {
        errors.push(
          "You shoud not choose more than " +
            state.scenarioSetting.max_data +
            " data slides"
        );
      }

      if (insightSlides.length > state.scenarioSetting.max_insight) {
        errors.push(
          "You shoud not choose more than " +
            state.scenarioSetting.max_insight +
            " insight slides"
        );
      }

      if (backgroundSlides.length === 0) {
        errors.push("You shoud choose at least one background slide");
      }

      if (dataSlides.length === 0) {
        errors.push("You shoud choose at least one data slide");
      }

      if (insightSlides.length === 0) {
        errors.push("You shoud choose at least one insight slide");
      }

      state.deckErrors = errors;
    },
    updateScenarioSetting(state, action){
      state.scenarioSetting = action.payload;
    },
    setSortBy(state, action){
      state.sortBy = action.payload;
    },
    toggleSlideZoomActive(state){
      state.slideZoomActive = !state.slideZoomActive;
    }
  },
});

export const slidesActions = slidesSlice.actions;
export default slidesSlice.reducer;
