import React, { useContext } from "react";

import { Link } from "react-router-dom";
import { AuthContext } from "../../shared/context/auth-context";
import Container from "../../shared/components/UIElements/Container";
import { Row, Col, Layout } from "antd";
const { Header, Content, Footer } = Layout;
import "./about.css";
import { staticContent, language } from "../data/dictionary";

const About = () => {
  const auth = useContext(AuthContext);
  const baseUrl = process.env.REACT_APP_API_URL;

  return (
    <React.Fragment>
      <div className="about-page">
        <section id="hero-section" className="dark-section">
          <Container variant="dark">
            <div className="wrapper">
              <Content style={{ padding: "0 50px" }}>
                <h1>{staticContent[language].about}</h1>
                <p>{staticContent[language].about_data_literacy_game}</p>
              </Content>
            </div>
          </Container>
        </section>

        <section id="team-section" className="dark-section">
          <Container variant="dark">
            <div className="wrapper">
              <Content style={{ padding: "0 50px" }}>
                <h1>{staticContent[language].team}</h1>
                <p>{staticContent[language].team_description}</p>
                <h5>{staticContent[language].project_leads}</h5>
                <p>Dr. Michael Bliemel, Ontario Tech University</p>
                <p>Dr. Kelly Lyons, University of Toronto</p>
                <p> Dr. Hossam Ali-Hassan, York University</p>
                <h5>
                  {staticContent[language].game_design_lead_project_manager}
                </h5>
                <p> Velian Pandeliev, University of Toronto</p>
                <h5>{staticContent[language].lead_programmer}</h5>
                <p>Ali Naman, Ontario Tech University</p>
                <h5>
                  {staticContent[language].Content_and_Scenario_Development}
                </h5>
                <p>
                  Ciara Zogheib <br />
                  Dongqing (Debbie) Chen <br />
                  Inder Dhillon <br />
                  Jinchi Lin <br />
                  Kyra J.F. Savolainen <br />
                  Lorena Almaraz De La Garza <br />
                  Tim Feller
                </p>
                <h5>{staticContent[language].AODA_Support}</h5>
                <p>
                  Stephanie Cork, Ontario Tech University Sarah Stokes,
                  OntarioTech University
                </p>
                <h5>{staticContent[language].French_Translation}</h5>
                <p>Dr. Valérie Florentin, York University</p>
              </Content>
            </div>
          </Container>
        </section>

        <section id="ecampus-section" className="dark-section">
          <Container variant="dark">
            <div className="wrapper">
              <Content style={{ padding: "0 50px" }}>
                <p>
                  {staticContent[language].ecampus_statement}{" "}
                  <a href="https://vls.ecampusontario.ca" target="_blank">
                    https://vls.ecampusontario.ca
                  </a>
                </p>
              </Content>
            </div>
          </Container>
        </section>
      </div>
    </React.Fragment>
  );
};

export default About;
