import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { Layout, Menu } from "antd";
const { Sider } = Layout;
const { SubMenu } = Menu;
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";

import "./SideNavigation.css";
import { staticContent } from '../../data/dictionary'

const language = localStorage.getItem("locale");

const SideNavigation = (props) => {
  const [siderCollapsed, setSiderCollapsed] = useState(false);

  const onCollapse = () => {
    setSiderCollapsed((prevState) => {
      return !prevState;
    });
  };

  const getNavLinkClass = (path) => {
    return useLocation().pathname === path ? 'active' : '';
  }

  return (
    <React.Fragment>
      <Sider
        collapsible
        collapsed={siderCollapsed}
        onCollapse={onCollapse}
        theme="light"
      >
        <div className="logo" />
        <Menu theme="light" defaultSelectedKeys={[useLocation().pathname.replace('/', '')]} mode="inline">
          {/* <Menu.Item key="dashboard" icon={<PieChartOutlined />}>
            <NavLink to="/dashboard">{staticContent[language].dashboard}</NavLink>
          </Menu.Item> */}
          { (props.role == "instructor" || props.role == "admin") &&
            <Menu.Item key="scenarios" icon={<DesktopOutlined />}>
              <NavLink to="/scenarios">{staticContent[language].scenarios}</NavLink>
            </Menu.Item>
          }
          { props.role == "instructor" &&
            <Menu.Item key="classes" icon={<DesktopOutlined />}>
              <NavLink to="/classes">{staticContent[language].classes}</NavLink>
            </Menu.Item>
          }

          { props.role == "admin" &&
          <Menu.Item key="users" icon={<UserOutlined />}>
            <NavLink to="/users">{staticContent[language].users}</NavLink>
          </Menu.Item>
          }
          
          <Menu.Item key="4" icon={<UserOutlined />}>
            <NavLink to="/account">{staticContent[language].account}</NavLink>
          </Menu.Item>


          {/* <SubMenu key="sub1" icon={<UserOutlined />} title="User">
                      <Menu.Item key="3">Tom</Menu.Item>
                      <Menu.Item key="4">Bill</Menu.Item>
                      <Menu.Item key="5">Alex</Menu.Item>
                    </SubMenu> */}
        </Menu>
      </Sider>
    </React.Fragment>
  );
};

export default SideNavigation;
