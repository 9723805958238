import "./Logo.css";
import { Link } from "react-router-dom";

const Logo = ({ size }) => {

  return (
    <Link to="/" size="large">
      <div className={`logo logo-${size}`}>DLG</div>
    </Link>
  );
};

export default Logo;
