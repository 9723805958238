/* eslint-disable */
import React, { useState } from "react";

import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import CoreLoop from "./components/dnd/CoreLoop";
import Scenarios from "./scenario/pages/Scenarios";
import ScenariosInstructor from "./scenario/pages/ScenariosInstructor";
import ScenarioPlays from "./scenario/pages/ScenarioPlays";
import PlayerPlays from "./class/pages/PlayerPlays";
import ScenarioDashboard from "./scenario/pages/ScenarioDashboard";
import ScenariosPlayer from "./scenario/pages/ScenariosPlayer";
import FeedbackDetailed from "./scenario/pages/FeedbackDetailed";
import ScenarioSetting from "./scenario/pages/ScenarioSetting";
import Classes from "./class/pages/Classes";
import NewClass from "./class/pages/NewClass";
import EditClass from "./class/pages/EditClass";
import SendClassInvitation from "./class/pages/SendClassInvitation";
import AuthInvite from "./user/pages/AuthInvite";
import ClassInstructor from "./class/pages/ClassInstructor";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { ToastContainer } from "react-toastify";
import Users from "./user/pages/Users";
import InviteUser from "./user/pages/InviteUser";
import Auth from "./user/pages/Auth";
import ForgotPassword from "./user/pages/ForgotPassword";
import ResetPassword from "./user/pages/ResetPassword";
import { AuthContext } from "./shared/context/auth-context";
import { useAuth } from "./shared/hooks/auth-hook";
import "react-toastify/dist/ReactToastify.css";
import MainNavigation from "./shared/components/Navigation/MainNavigation";
import NewScenario from "./scenario/pages/NewScenario";
import EditScenario from "./scenario/pages/EditScenario";
import UpdateScenario from "./scenario/pages/UpdateScenario";
import NewSlide from "./scenario/pages/NewSlide";
import UpdateSlide from "./scenario/pages/UpdateSlide";
import EditCombos from "./scenario/pages/EditCombos";
import DashboardInstructor from "./panel/pages/DashboardInstructor";
import PanelLayout from "./panel/components/PanelLayout";
import Account from "./user/pages/Account";
import Home from "./shared/pages/home";
import About from "./shared/pages/about";
import { Layout, Breadcrumb } from "antd";

const { Content, Footer } = Layout;

if (localStorage.getItem("locale") === "null") {
  localStorage.setItem("locale", "en");
}

function App() {
  const { token, login, logout, userId, userRole, userImage } = useAuth();

  const role = userRole;

  const globalRoutes = (
    <Route path={["/", "/about"]} exact>
      {/* pannel layout */}
      <div>
        <MainNavigation role={role} avatar={userImage} variant="dark" />
        <Switch>
          <Route>
            <Route path="/" exact>
              <Home></Home>
            </Route>
            <Route path="/about" exact>
              <About></About>
            </Route>
          </Route>
        </Switch>
        <Footer style={{ textAlign: "center" }} className="dark-footer">
          Data Literacy Game
        </Footer>
      </div>
    </Route>
  );

  let routes;
  if (token) {
    // @ts-ignore
    if (role == "instructor") {
      routes = (
        <React.Fragment>
          <Switch>
            <Route
              path={[
                "/dashboard",
                "/scenarios",
                "/scenarios/:scenarioid/:classid/plays",
                "/scenarios/:scenarioid/:classid",
                "/scenarios/:scenarioid/:classid/:playerid/feedback",
                "/scenarios/plays/:playedscenarioid/feedback",
                "/scenarios/:scenarioid/:classid/setting",
                "/classes",
                "/classes/create",
                "/classes/:id/edit",
                "/classes/:id/invite",
                "/classes/:id",
                "/classes/:classid/players/:playerid",
                "/account",
              ]}
              exact
            >
              {/* pannel layout */}
              <PanelLayout role="instructor" avatar={userImage}>
                <Switch>
                  <Route path="/dashboard">
                    <DashboardInstructor></DashboardInstructor>
                  </Route>
                  <Route path="/scenarios" exact>
                    <ScenariosInstructor></ScenariosInstructor>
                  </Route>
                  <Route path="/scenarios/:scenarioid/:classid/plays" exact>
                    <ScenarioPlays></ScenarioPlays>
                  </Route>
                  <Route path="/scenarios/:scenarioid/:classid" exact>
                    <ScenarioDashboard></ScenarioDashboard>
                  </Route>
                  <Route
                    path="/scenarios/:scenarioid/:classid/:playerid/feedback"
                    exact
                  >
                    <FeedbackDetailed></FeedbackDetailed>
                  </Route>
                  <Route
                    path="/scenarios/plays/:playedscenarioid/feedback"
                    exact
                  >
                    <MainNavigation />
                    <FeedbackDetailed></FeedbackDetailed>
                  </Route>
                  <Route path="/scenarios/:scenarioid/:classid/setting" exact>
                    <ScenarioSetting></ScenarioSetting>
                  </Route>
                  <Route path="/classes" exact>
                    <Classes></Classes>
                  </Route>
                  <Route path="/classes/create" exact>
                    <NewClass></NewClass>
                  </Route>
                  <Route path="/classes/:id/edit" exact>
                    <EditClass></EditClass>
                  </Route>
                  <Route path="/classes/:id/invite" exact>
                    <SendClassInvitation></SendClassInvitation>
                  </Route>
                  <Route path="/classes/:id" exact>
                    <ClassInstructor></ClassInstructor>
                  </Route>
                  <Route path="/classes/:classid/players/:playerid" exact>
                    <PlayerPlays></PlayerPlays>
                  </Route>
                  <Route path="/account" exact>
                    <Account></Account>
                  </Route>
                  <Redirect to="/scenarios" />
                </Switch>
              </PanelLayout>
            </Route>

            {globalRoutes}

            <Route>
              <Redirect to="/scenarios" />
            </Route>
          </Switch>
        </React.Fragment>
      );
    }

    // @ts-ignore
    if (role == "admin") {
      routes = (
        <React.Fragment>
          <Switch>
            <Route
              path={[
                "/scenarios",
                "/scenarios/create",
                "/scenarios/:scenarioId/slides/create",
                "/slides/:slideId/edit",
                "/scenarios/:scenarioId",
                "/scenarios/:scenarioId/setting",
                "/scenarios/:scenarioId/combos/edit",
                "/users",
                "/users/invite",
                "/account",
                "/scenarios",
              ]}
              exact
            >
              {/* pannel layout */}
              <PanelLayout role="admin" avatar={userImage}>
                <Switch>
                  <Route path="/scenarios" exact>
                    <Scenarios></Scenarios>
                  </Route>
                  <Route path="/scenarios/create" exact>
                    <NewScenario></NewScenario>
                  </Route>
                  <Route path="/scenarios/:scenarioId/slides/create" exact>
                    <NewSlide></NewSlide>
                  </Route>
                  <Route path="/slides/:slideId/edit" exact>
                    <UpdateSlide></UpdateSlide>
                  </Route>
                  <Route path="/scenarios/:scenarioId" exact>
                    <EditScenario></EditScenario>
                  </Route>
                  <Route path="/scenarios/:scenarioId/setting" exact>
                    <UpdateScenario></UpdateScenario>
                  </Route>
                  <Route path="/scenarios/:scenarioId/combos/edit" exact>
                    <EditCombos></EditCombos>
                  </Route>
                  <Route path="/users" exact>
                    <Users></Users>
                  </Route>
                  {/* <Route path="/users/invite" exact>
                    <InviteUser role={role}></InviteUser>
                  </Route> */}
                  <Route path="/account" exact>
                    <Account></Account>
                  </Route>
                  <Redirect to="/scenarios" />
                </Switch>
              </PanelLayout>
            </Route>

            {globalRoutes}

            <Route>
              <Redirect to="/scenarios" />
            </Route>
          </Switch>
        </React.Fragment>
      );
    }

    // @ts-ignore
    if (role == "player") {
      routes = (
        <React.Fragment>
          <Switch>
            <Route path={["/dashboard", "/invite/:token", "/account"]} exact>
              {/* pannel layout */}
              <PanelLayout role="player" avatar={userImage}>
                <Switch>
                  <Route>
                    <Route path="/dashboard" exact>
                      <ScenariosPlayer></ScenariosPlayer>
                    </Route>
                    <Route path="/invite/:token">
                      <Redirect to="/dashboard" />
                    </Route>
                    <Route path="/account" exact>
                      <Account></Account>
                    </Route>
                  </Route>
                </Switch>
              </PanelLayout>
            </Route>

            {globalRoutes}

            {/* play layout */}
            <Route
              path={[
                "/scenarios/:id/:classid?/play",
                "/scenarios/:id/:classid?/feedback",
              ]}
              exact
            >
              <Switch>
                <Route>
                  <Route path="/scenarios/:id/:classid?/play" exact>
                    <CoreLoop></CoreLoop>
                  </Route>
                  <Route path="/scenarios/:scenarioid/:classid?/feedback" exact>
                    <MainNavigation />
                    <FeedbackDetailed></FeedbackDetailed>
                  </Route>
                </Route>
              </Switch>
            </Route>

            <Route>
              <Redirect to="/dashboard" />
            </Route>
          </Switch>
        </React.Fragment>
      );
    }
  } else {
    routes = (
      <React.Fragment>
        <Switch>
          <Route
            path={[
              "/auth/:authtype?",
              "/forgot-password",
              "/reset-password/:token",
              "/invite/:token",
              "/auth",
            ]}
            exact
          >
            <Switch>
              <Route path="/auth/:authtype?" exact>
                {/* <MainNavigation /> */}
                <Auth />
              </Route>
              <Route path="/forgot-password" exact>
                <ForgotPassword />
              </Route>
              <Route path="/reset-password/:token" exact>
                <ResetPassword />
              </Route>
              <Route path="/invite/:token" exact>
                <AuthInvite></AuthInvite>
              </Route>
              <Redirect to="/auth" />
            </Switch>
          </Route>

          {globalRoutes}

          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </React.Fragment>
    );
  }

  return (
    <>
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          // @ts-ignore
          token: token,
          userId: userId,
          login: login,
          logout: logout,
        }}
      >
        <Router>
          <main>{routes}</main>
        </Router>
      </AuthContext.Provider>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
