import React, { useEffect, useContext, useRef, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import { useSelector } from "react-redux";
import { AuthContext } from "../shared/context/auth-context";
import { NavLink, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  UserOutlined,
  SaveOutlined,
  LogoutOutlined,
  DribbbleOutlined,
  DownOutlined
} from "@ant-design/icons";
import { Menu, Dropdown, Button } from "antd";
import { saveSlides } from "./saveSlides";
import { staticContent, language } from "../shared/data/dictionary";
import { Translate } from "../shared/util/translator";
function Header(props) {
  const auth = useContext(AuthContext);
  const items = useSelector((state) => state.scenario.slides);
  const scenarioId = useParams().id;
  const classId = useParams().classid;
  const saveBtnRef = useRef();
  const [locale, setLocale] = useState("en");

  useEffect(() => {
    setLocale(localStorage.getItem("locale"));
  }, []);

  useEffect(() => {
    localStorage.setItem("locale", locale);
  }, [locale]);

  const saveHandler = async (event) => {
    event.preventDefault();
    saveBtnRef.current.blur();
    await saveSlides(items, scenarioId, classId, auth, (localStorage = true));
  };

  const handleDeckSort = function () {
    props.onResortDeckClick("theme");
  };

  const handleMenuLangClick = (e) => {
    setLocale(e.key);
    location.reload();
  };

  const menuLang = (
    <Menu onClick={handleMenuLangClick} selectable>
      <Menu.Item key="en">English</Menu.Item>
      <Menu.Item key="fr">French</Menu.Item>
    </Menu>
  );

  const menuAccount = (
    <Menu selectable>
      <Menu.Item key="dashboard">
        <NavLink to="/dashboard">{staticContent[language].dashboard}</NavLink>
      </Menu.Item>
      <Menu.Item key="logout" onClick={auth.logout}>
        {staticContent[language].logout}
      </Menu.Item>
    </Menu>
  );

  const menu = (
    <Menu getPopupContainer={(triggerNode) => triggerNode.parentNode}>
      <Menu.Item key="0">
        <Link to="/dashboard">{staticContent[language].dashboard}</Link>
      </Menu.Item>
      <Menu.Item key="1" onClick={auth.logout}>
       {staticContent[language].logout}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Navbar variant="dark" expand="lg" fixed="top">
        <div className="left-wrapper">
          <Navbar.Brand href="#home" tabIndex={-1}>
            {Translate(props.scenarioTitle, "title")}
          </Navbar.Brand>

          <Navbar.Collapse className="justify-content-end">
            {/* <Navbar.Text>
              Signed in as: <a href="#login">Ali Naman</a>
            </Navbar.Text> */}

            {props.save && (
              <Navbar.Text>
                <Button
                  onClick={saveHandler}
                  className="btn-dark btn-icon"
                  icon={<SaveOutlined />}
                  ref={saveBtnRef}
                  tabIndex={2}
                >
                  {staticContent[language]?.save}
                </Button>
              </Navbar.Text>
            )}
            {/* {auth.isLoggedIn && (
              <Navbar.Text>
                <Link to="/dashboard">
                  <Button
                    className="btn-dark btn-icon"
                    icon={<UserOutlined />}
                    tabIndex={2}
                  >
                    {staticContent[language].profile}
                  </Button>
                </Link>
              </Navbar.Text>
            )}
            {auth.isLoggedIn && (
              <Navbar.Text>
                <Button
                  className="btn-dark btn-icon"
                  onClick={auth.logout}
                  icon={<LogoutOutlined />}
                  tabIndex={2}
                >
                  {staticContent[language].logout}
                </Button>
              </Navbar.Text>
            )} */}
          </Navbar.Collapse>
        </div>

        <div className="right-wrapper">
          {/* <div className="deck-sort-wrapper"> */}
          {/* <DeckSortSelect></DeckSortSelect>  */}
          {/* <Button className="btn-icon btn-darky" icon={<SortAscendingOutlined />} onClick={handleDeckSort}>Resort themes</Button> */}
          {/* </div> */}
          <span>DataLiteracyGame</span>
          {/* <img className="help" src="/assets/imgs/icons/help.png" onClick={openGuideDialog} /> */}
          {/* language */}
          <div className="header-right-buttons">
          <Dropdown.Button
            className="dropDown dark"
            overlay={menuLang}
            placement="bottomRight"
            icon={<DribbbleOutlined />}
          >
            {/* {locale === "en" ? "English" : "French"} */}
          </Dropdown.Button>
          <Navbar.Toggle />
          {/* end of language */}

          {/* account */}
          {auth.isLoggedIn && (
            <>
              {/* <Dropdown.Button
                className="dropDown dark"
                overlay={menuAccount}
                placement="bottomRight"
                icon={<UserOutlined />}
              >
                {staticContent[language].account}
              </Dropdown.Button>
              <Navbar.Toggle /> */}
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                <a
                  className="btn-dark ant-dropdown-link dropDown dark account-dropdown"
                  onClick={(e) => e.preventDefault()}
                  href=""
                >
                  Account
                </a>
              </Dropdown>
            </>
          )}
          {/* end of account */}

          </div>
        </div>
      </Navbar>
    </>
  );
}

export default Header;
