import React, { useContext } from 'react';
import { AuthContext } from "../../shared/context/auth-context";
// import { useAuth } from "./shared/hooks/auth-hook";

import './ScenariosListInstructor.css';

import ScenarioItemInstructor from './ScenarioItemInstructor';
import Card from '../../shared/components/UIElements/Card';
import scenario from '../../store/scenario';
import { staticContent } from '../../shared/data/dictionary';
import { Translate } from '../../shared/util/translator';

const language = localStorage.getItem("locale");
const ScenariosListInstructor = props => {

  // const { token, login, logout, userId } = useAuth();
  const auth = useContext(AuthContext);

  if (props.items.length === 0) {
    return (
      <div className="center">
        <Card className="pad-60">
          <h4>{staticContent[language]?.notFoundScenarios}</h4>
        </Card>
      </div>
    );
  }

  return (
    <div className="scenarios-list">
      {
      props.items.map(scenario => {
        
        let selected = false
        if(props.selectedScenarios){
          selected = props.selectedScenarios.some( each => each['id'] === scenario.id )
        }
        return (
          <ScenarioItemInstructor
            key={scenario.id}
            scenarioId={scenario.id}
            classId={props.classId}
            title={Translate(scenario, 'title')}
            description={Translate(scenario, 'description')}
            image={scenario.image}
            stats={props.stats}
            selectbtn={props.selectbtn}
            onScenarioSelected={props.onScenarioSelected}
            selected={selected}
            morebtn={props.morebtn}
          />
        )
      }
      )}
    </div>
  );
};

export default ScenariosListInstructor;
