import React, {forwardRef} from 'react';
import classNames from 'classnames';

import styles from './List.module.css';

export interface Props {
  children: React.ReactNode;
  columns?: number;
  style?: React.CSSProperties;
  horizontal?: boolean;
  id?: string;
  fadeIn?: boolean;
  slideZoomActive?: boolean; 
}

export const List = forwardRef<HTMLUListElement, Props>(
  ({children, columns = 1, horizontal, style, id, fadeIn, slideZoomActive}: Props, ref) => {

    const position = id == "selectedContainer" ? (slideZoomActive ? 'absolute': 'fixed') : 'relative';

    return (
      <ul
        ref={ref}
        style={
          {
            // ...style,
            '--columns': columns,
            'position': position
          } as React.CSSProperties
        }
        className={classNames(styles.List, horizontal && styles.horizontal, fadeIn && 'fadeIn')}
        id={id}
      >
        {children}
      </ul>
    );
  }
);
