import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { staticContent } from '../shared/data/dictionary';

const language = localStorage.getItem("locale");
function ScoreModal(props) {
  const deckErrors = useSelector((state) => state.scenario.deckErrors);
  const scenarioId = useParams().id;
  const classId = useParams().classid;
  const scoreRange = props.scoreRange;
  const scoreRangeMessage = props.scoreRangeMessage;

  const submissionExceeded = props.detail_feedback_count >= props.submit_max;
  
  const handleClose = () => {
    props.onModalClose();
  };
  

  return (
    props.show ? <Modal show={props.show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="h5">
          {deckErrors.length === 0 ? staticContent[language].submitDeckHeader : staticContent[language].errors}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container style={{marginBottom: '20px'}}>
          <div className="text-left feedback-dialog-message" style={{marginBottom: '20px'}}>
            { staticContent[language][scoreRangeMessage] }
          </div>
          <div className="scoreRange">
            <div className={scoreRange && (scoreRange === 'Failing') ? 'selected' : ''} style={{background: '#ff6d28'}}>
              {staticContent[language].failing}
            </div>
            <div className={scoreRange && (scoreRange === 'Okay') ? 'selected' : ''} style={{background: '#fcbc2f'}}>
              {staticContent[language].okay}
            </div>
            <div className={scoreRange && (scoreRange === 'Good') ? 'selected' : ''} style={{background: '#daead3'}}>
              {staticContent[language].good}
            </div>
            <div className={scoreRange && (scoreRange === 'Very good') ? 'selected' : ''} style={{background: '#c2e4f0'}}>
              {staticContent[language].veryGood}
            </div>
            <div className={scoreRange && (scoreRange === 'Excellent') ? 'selected' : ''} style={{background: '#98cae1'}}>
              {staticContent[language].excellent}
            </div>
          </div> 
        </Container>
      </Modal.Body>

      {deckErrors.length === 0 && (
        <Modal.Footer>
          <div className="each-modal-feedback">
            <div className="text-center">
              { !submissionExceeded && 
              <Button variant="primary" onClick={props.onModalSubmit}>
                {staticContent[language].submitTheDeck}
              </Button>
              }
              { submissionExceeded &&
               <Link to={`/scenarios/${scenarioId}/${classId}/feedback`}>
                <Button variant="primary">
                    {staticContent[language].view_last_attempt_feedback}
                </Button>
              </Link>
              }
              <Button variant="secondary" style={{marginLeft: '10px'}} onClick={props.onModalClose}>{staticContent[language]?.cancel}</Button>
            </div>
          </div>
        </Modal.Footer>
      )}
    </Modal> : <></>
  );
}

export default ScoreModal;
