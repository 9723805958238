import React from 'react';

// import Card from '../../shared/components/UIElements/Card';
import SlideItem from './SlideItem';
import './SlidesList.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Slide from "../../components/Slide";


const SlidesList = props => {
  if (props.slides.length === 0) {
    return (
      <div className="center">
          <h4>No Slides found.</h4>
      </div>
    );
  }

  const selectHandler = (slide) => {
    console.log("SlideList - slide selected with id: ", slide);
    props.onSlideSelect(slide)
  }

  return (
    <div className="slides-list">
      <Row>
      {props.slides.map(slide => (
        <Col lg="4">
          <div
            className={`item-wrapper ${slide.type}`}
            style={{
              width: "320px",
              height: "460px",
              boxSizing: "border-box",
              display: "flex",
            }}
          >
            <div
              className={`Item ${slide.type}-item`}
              style={{ borderStyle: "solid", borderWidth: "2px" }}
            >
              <Slide
                key={slide.title}
                id={slide.id}
                image={slide.image}
                title={slide.title}
                text={slide.text}
                type={slide.type}
                feedback={slide.feedback}
                score={slide.score}
                table={slide.table}
                footer={true}
                slideEditBtn={props.slideEditBtn}
                slideDeleteBtn={props.slideEditBtn}
                deleteSlideHandler={props.deleteSlideHandler}
                imageDescriptionBtn={props.imageDescriptionBtn}
              ></Slide>
            </div>
          </div>
          {/* <SlideItem
            key={slide._id}
            id={slide.id}
            type={slide.type}
            title={slide.title}
            text={slide.text}
            image={slide.image}
            slideSelectBtn={props.slideSelectBtn}
            slideEditBtn={props.slideEditBtn}
            onSelect={selectHandler}
            footer={props.footer}
            score={props.score}
          /> */}
        </Col>
      ))}
      </Row>
    </div>
  );
};

export default SlidesList;
