import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import ScenarioItem from "../components/ScenarioItem";
import SlidesList from "../components/SlidesList";
import Container from "react-bootstrap/Container";
import SectionHeader from '../../panel/components/SectionHeader';
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from '../../shared/context/auth-context';
import { toast } from "react-toastify";

const EditScenario = () => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedScenario, setLoadedScenario] = useState();
  const scenarioId = useParams().scenarioId;
  const baseUrl = process.env.REACT_APP_API_URL;
  const deleteHttp = useHttpClient();
  
  useEffect(() => {
    const fetchScenario = async () => {
      try {
        const responseData = await sendRequest(
          `${baseUrl}/scenarios/${scenarioId}`, 'GET', null, {
            Authorization: 'Bearer ' + auth.token
          }
        );
        setLoadedScenario(responseData);
      } catch (err) {}
    };
    fetchScenario();
  }, [sendRequest]);

  const deleteSlideHandler = (slideId) => {
    const fetchDeleteCombo = async () => {
      try {
        await deleteHttp.sendRequest(
          `${baseUrl}/scenarios/slides/${slideId}`,
          "DELETE",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        toast('Slide is deleted successfully');
        // location.reload();
      } catch (err) {}
    };
    fetchDeleteCombo();
  }


  const sectionHeaderBtns = [
    { text: 'Go back', link: '/scenarios' }
  ]

  return (
    <Container>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      <SectionHeader title="Edit Scenario" btns={sectionHeaderBtns}/>
      {!isLoading && loadedScenario && (
        <React.Fragment>
          <ScenarioItem
            key={loadedScenario.id}
            id={loadedScenario.id}
            title={loadedScenario.title}
            description={loadedScenario.description}
            image={loadedScenario.image}
            isAdmin={true}
            settingScenarioBtn={true}
            addSlideBtn={true}
            addCombinationScore={true}
            deleteBtn={true}
          />

          <h3>Slides</h3>
          <SlidesList
            scenario={loadedScenario.scenario}
            slides={loadedScenario.slides}
            slideEditBtn={true}
            slideDeleteBtn={true}
            footer={true}
            score={false}
            deleteSlideHandler={deleteSlideHandler}
            imageDescriptionBtn={false}
          />
        </React.Fragment>
      )}
    </Container>
  );
};

export default EditScenario;
