import React, { useEffect, useState, useContext } from "react";

import UsersList from "../components/UsersList";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { Table, Button, Tag, Space, Modal, Row, Col, Avatar } from "antd";
import SectionHeader from "../../panel/components/SectionHeader";
import { DeleteOutlined, UserOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

const Users = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const statusHttp = useHttpClient();
  const [loadedUsers, setLoadedUsers] = useState();
  const baseUrl = process.env.REACT_APP_API_URL;
  const uploadUrl = process.env.REACT_APP_UPLOADS_URL;
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const responseData = await sendRequest(
          `${baseUrl}/users`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setLoadedUsers(responseData);
      } catch (err) {}
    };
    fetchUsers();
  }, [sendRequest]);

  // update user status
  const updateStatus = async (userId, status) => {
    try {
      const responseData = await statusHttp.sendRequest(
        `${baseUrl}/users/status`,
        "PATCH",
        JSON.stringify({
          userId: userId,
          status: status,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      if (responseData.success) {
        toast("Status updated successfully");
        setLoadedUsers((prev) => {
          // var foundIndex = prev.findIndex(user => user._id == userId);
          // prev[foundIndex].status = status;
          return prev.map((user) => {
            if (user._id == userId) {
              user.status = status;
            }
            return user;
          });
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const usersColumns = [
    {
      title: "",
      dataIndex: "image",
      key: "image",
      width: "60px",
      render: (src) => {
        // if(src){
        // return <img src={uploadUrl+src} className="avatar"/>
        // }else{
        return (
          <Avatar
            src={src ? uploadUrl + src : false}
            size="large"
            icon={<UserOutlined />}
          />
        );
        // }
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // width: "10%",
      render: (text) => text,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      // width: "10%",
      render: (text) => text,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      // width: "30%",
      render: (text) => text,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // width: "30%",
      render: (text) => {
        const color = text == "active" ? "green" : "orange";
        return (
          <Tag color={color} key={text}>
            {text.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (text, record) => (
        <Space size="middle">
          {/* <Button type="text" icon={<DeleteOutlined />} onClick={() => { deleteCombo(record.key) } }></Button> */}
          <Button
            type="default"
            size="small"
            onClick={() => {
              updateStatus(
                record._id,
                record.status == "active" ? "inactive" : "active"
              );
            }}
          >
            {record.status == "active" ? "Deactivate" : "Activate"}
          </Button>
        </Space>
      ),
    },
  ];

  const combosData = [
    {
      key: "1",
      code1: "JC",
      code2: "BE",
      score: 12,
    },
  ];

  return (
    <React.Fragment>
      <SectionHeader title="Users" backBtn={true} />
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && loadedUsers && (
        <Table
          columns={usersColumns}
          dataSource={loadedUsers}
          className="border-gray"
          pagination={false}
        />
      )}
    </React.Fragment>
  );
};

export default Users;
