import React, { useContext } from 'react';

import ScenarioItem from './ScenarioItem';
// import Card from '../../shared/components/UIElements/Card';
import './ScenariosList.css';
import { AuthContext } from "../../shared/context/auth-context";
// import { useAuth } from "./shared/hooks/auth-hook";

const ScenariosList = props => {

  // const { token, login, logout, userId } = useAuth();
  const auth = useContext(AuthContext);

  // const isAdmin = auth.userId == 2 ? true : false

  const isAdmin = true

  if (props.items.length === 0) {
    return (
      <div className="center">
        <Card>
          <h2>No Scenarios found.</h2>
        </Card>
      </div>
    );
  }

  

  return (
    <div className="scenarios-list">
      {props.items.map(scenario => (
        <ScenarioItem
          key={scenario.id}
          id={scenario.id}
          title={scenario.title}
          description={scenario.description}
          image={scenario.image}
          isAdmin={isAdmin}
          editScenarioBtn={true}
          addSlideBtn={false}
          // playBtn={ !!scenario.slides.length }
          deleteBtn={props.deleteBtn}
        />
      ))}
    </div>
  );
};

export default ScenariosList;
