import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Button } from "antd";
import { Card } from "antd";
import { Row, Col } from "antd";
import SlideItem from "./SlideItem";
import Slide from "../../components/Slide";
import { InfoCircleOutlined } from "@ant-design/icons";
import "./FeedbackItemPlayer.css";
import { staticContent } from "../../shared/data/dictionary";
import { Translate } from "../../shared/util/translator";

const language = localStorage.getItem("locale");
const IndividualFeedback = (props) => {
  const slide = props.slide;

  return (
    <div className="feedback-item">
      <Row>
        <Col span={12}>
          <div
            className={`item-wrapper ${slide.type}`}
            style={{
              width: "320px",
              height: "460px",
              boxSizing: "border-box",
              display: "flex",
            }}
          >
            <div
              className={`Item ${slide.type}-item`}
              style={{ borderStyle: "solid", borderWidth: "2px" }}
            >
              <Slide
                key={slide.title}
                id={slide.id}
                image={Translate(slide, "image")}
                table={Translate(slide, "table")}
                title={Translate(slide, "title")}
                text={Translate(slide, "text")}
                type={slide.type}
                score={slide.score}
                footer={false}
              ></Slide>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="feedback-text">
            <div className="title-wrapper">
              {/* <InfoCircleOutlined /> */}
              <span className="title">
                {staticContent[language]?.feedback}
              </span>
            </div>
            <p className="text">{Translate(slide, "feedback")}</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default IndividualFeedback;
