import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import SectionHeader from '../../panel/components/SectionHeader';

import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
  VALIDATOR_MAXLENGTH,
} from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
import "../../shared/components/FormElements/Form.css";
import { staticContent, language } from "../../shared/data/dictionary";


const getIntervals = (interval, split) => {
  
  const each_interval_length = (interval[1] - interval[0]) / split

  let start = interval[0]
  let intervals = []
  for(const i of Array(split).keys()){
    intervals.push([start, start + each_interval_length])
    start = start + each_interval_length
  }

  return intervals;
};

const intervalToPercentage = (interval, max) => {

  for(const each of interval){
    console.log(each)
  }

  const intervalPercentage = interval.map((score) => {
    return Math.round(score/max * 100).toString() + '%'
  })

  return intervalPercentage
}



const ScenarioSetting = () => {
  const http = useHttpClient();
  const formHttp = useHttpClient();
  const [scenarioSetting, setScenarioSetting] = useState();
  const auth = useContext(AuthContext);
  const scenarioId = useParams().scenarioid;
  const classId = useParams().classid;
  const [formState, inputHandler, setFormData] = useForm();
  const baseUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchScenarioDashboard = async () => {
      try {
        const url = `${baseUrl}/scenarios/${scenarioId}/${classId}/setting`;
        const res = await http.sendRequest(url, "GET", null, {
          Authorization: "Bearer " + auth.token,
        });
        setScenarioSetting(res);
      } catch (err) {
        console.log("fetchScenarioDashboard err", err);
      }
    };
    fetchScenarioDashboard();
  }, [http.sendRequest, setFormData]);

  const submitHandler = async (event) => {
    event.preventDefault();
    console.log("hello");
    try {
      const response = await formHttp.sendRequest(
        `${baseUrl}/scenarios/${scenarioId}/${classId}/setting`,
        "PATCH",
        JSON.stringify({
          full_score: formState.inputs.full_score.value,
          min_passing: formState.inputs.min_passing.value,
          due: formState.inputs.due.value,
          late_penalty: formState.inputs.late_penalty.value,
          submit_max: formState.inputs.submit_max.value,
          resubmit_penalty: formState.inputs.resubmit_penalty.value,
          quick_feedback_max: formState.inputs.quick_feedback_max.value,
          quick_feedback_penalty: formState.inputs.quick_feedback_penalty.value,
          score_feedback_max: formState.inputs.score_feedback_max.value,
          score_feedback_penalty: formState.inputs.score_feedback_penalty.value,
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        }
      );
      // history.push('/' + auth.userId + '/places');
    } catch (err) {
      console.log(err);
    }
  };

  let intervals = [];
  let percentageIntervals = []

  if (!http.isLoading && scenarioSetting) {
    
    const max = scenarioSetting.full_score
    const min = scenarioSetting.min_passing
    console.log("min passing", min)

    intervals = getIntervals([min, max], 5)

    for(const interval of intervals) {
      percentageIntervals.push(intervalToPercentage(interval, max))
    }

    // let table = []
    // for(const i in Array(intervals.length).keys()){
    //   table.push(
    //       <tr>
    //             <th>{intervals[i][0]} - {intervals[i][1]}</th>
    //             <th>{percentageIntervals[i][0]} - {percentageIntervals[i][1]}</th>
    //       </tr>
    //   )
    // }

  }

  const convertToHTMLdate = (dateStr) => {
    const date = new Date(dateStr);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const htmlDate = date.getFullYear()+"-"+(month)+"-"+(day);
    return htmlDate;
  }

  return (
    <React.Fragment>
      <SectionHeader title="Update slide" backBtn={true}/>

      <ErrorModal error={http.error} onClear={http.clearError} />
      {http.isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}

      {!http.isLoading && scenarioSetting && (
        <form className="form" onSubmit={submitHandler}>
          <h4 className="form-title">Edit scenario setting</h4>

          <div className="input-wrapper">
            <label>Title</label>
            <p>{scenarioSetting.scenario.title}</p>
          </div>

          <div className="input-wrapper">
            <label>Assigned to class</label>
            <p>{scenarioSetting.class.title}</p>
          </div>

          <div className="input-wrapper">
            <label>Maximum possible score</label>
            <p>{scenarioSetting.max_score}</p>
          </div>

          <Input
            id="full_score"
            element="input"
            label="Full mark score"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
            errorText={staticContent[language]?.valid_number}
            onInput={inputHandler}
            initialValue={scenarioSetting.full_score}
            initialValid={true}
          />

          <Input
            id="min_passing"
            element="input"
            label="Minimum passing score"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
            errorText={staticContent[language]?.valid_number}
            onInput={inputHandler}
            initialValue={scenarioSetting.min_passing}
            initialValid={true}
          />

          <Input
            id="due"
            element="input"
            type="date"
            label="Due date"
            validators={[VALIDATOR_REQUIRE()]}
            errorText={staticContent[language]?.dueDateError}
            onInput={inputHandler}
            initialValue={convertToHTMLdate(scenarioSetting.due)}
            initialValid={true}
          />
          <Input
            id="late_penalty"
            element="input"
            label="Late penalty"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
            errorText={staticContent[language]?.valid_number}
            onInput={inputHandler}
            initialValue={scenarioSetting.late_penalty}
            initialValid={true}
          />
          <Input
            id="submit_max"
            element="input"
            label="Maximum number of attempts"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(2)]}
            errorText={staticContent[language]?.valid_number}
            onInput={inputHandler}
            initialValue={scenarioSetting.submit_max}
            initialValid={true}
          />
          <Input
            id="resubmit_penalty"
            element="input"
            label="Attempts penalty"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
            errorText={staticContent[language]?.valid_number}
            onInput={inputHandler}
            initialValue={scenarioSetting.resubmit_penalty}
            initialValid={true}
          />
          <Input
            id="score_feedback_max"
            element="input"
            label="Maximum number of score range feedback"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(2)]}
            errorText={staticContent[language]?.valid_number}
            onInput={inputHandler}
            initialValue={scenarioSetting.score_feedback_max}
            initialValid={true}
          />
          <Input
            id="score_feedback_penalty"
            element="input"
            label="Score range feedback penalty"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
            errorText={staticContent[language]?.valid_number}
            onInput={inputHandler}
            initialValue={scenarioSetting.score_feedback_penalty}
            initialValid={true}
          />
          <Input
            id="quick_feedback_max"
            element="input"
            label="Maximum number of quick feedback"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(2)]}
            errorText={staticContent[language]?.valid_number}
            onInput={inputHandler}
            initialValue={scenarioSetting.quick_feedback_max}
            initialValid={true}
          />
          <Input
            id="quick_feedback_penalty"
            element="input"
            label="Quick feedback penalty"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(3)]}
            errorText={staticContent[language]?.valid_number}
            onInput={inputHandler}
            initialValue={scenarioSetting.quick_feedback_penalty}
            initialValid={true}
          />

          <div className="score-range-table">
            <h5>Score Range Guide</h5>
            <table className="simple-table">
              <tr>
                <th>Score Ranges</th>
                <th>Score Percentages</th>
              </tr>
              {intervals.map((interval, index) => (
                <tr>
                  <td>{interval[0]} - {interval[1]}</td>
                  <td>{percentageIntervals[index][0]} - {percentageIntervals[index][1]}</td>
                </tr>
              ))}
              
            </table>

            
            {/* { intervals.map( interval => (<tr>hello<tr/>) ) 
            
            } */}
          </div>

          <Button
            type="submit"
            disabled={!formState.isValid}
            fullWidth={true}
            marginClass="mt-30"
          >
            Update setting
          </Button>
        </form>
      )}
    </React.Fragment>
  );
};

export default ScenarioSetting;
