import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import { staticContent } from "../shared/data/dictionary";
import { FileOutlined, DiffOutlined } from "@ant-design/icons";

const language = localStorage.getItem("locale");
export function GuideDialog({ open, handleClose }) {
  return (
    <Modal show={open} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{staticContent[language].play_guide_header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {language === "en" && (
          <Container style={{ marginBottom: "20px" }}>
            <h5>Goal:</h5>
            <p>Create a convincing slide deck to answer the scenario prompt.</p>

            <h5>How to play:</h5>
            <p>
              - Read the background slides already in the deck to understand the
              scenario.
              <br />
              - Read through the slide bank found at the bottom of the page.
              <br />
              - Drag relevant data and insight slides from the Slide Bank into
              the Slide deck to build an argument.
              <br />
              - Submit your attempt when ready.
              <br />
            </p>

            <h5>Rules:</h5>
            <p>
              - The ordering of slides in the deck doesn’t matter.
              <br />- You have a limited number of data and insight slots in
              your deck. Use them wisely!
              <br />- Background slides are true and accurate.
              <br />- All data in the slides is true, but it isn’t always well
              presented.
              <br />- Not all insights in the slides are accurate or supported
              by data.
            </p>

            <h5>Hints:</h5>
            <p>
              <FileOutlined /> Get a hint about a random slide in your deck.
              <br />
              <DiffOutlined /> Get a hint about how a random pair of slides in
              your deck go together. The more hints you ask for, the less
              specific they become.
            </p>

            <h5>Scoring:</h5>
            <p>Your total score depends on:</p>
            <ul>
              <li>The quality of each slide</li>
              <li>How well your slides support each other</li>
            </ul>
          </Container>
        )}

        {language === "fr" && (
          <Container style={{ marginBottom: "20px" }}>
            <h5>Objectif:</h5>
            <p>
              Obtenir un paquet de diapositives qui répond de manière
              convaincante aux exigences du scénario..
            </p>

            <h5>Comment jouer:</h5>
            <p>
              - L'écran est divisé en deux parties. À gauche, la banque contient
              toutes les diapositives disponibles pour ce scénario. À droite, il
              s'agit du paquet que vous soumettrez en réponse au scénario.
              <br />
              - Votre paquet contient déjà plusieurs diapositives de contexte
              qui vous aident à comprendre le scénario et votre objectif.
              Lisez-les attentivement!
              <br />
              - Consultez les diapositives en banque et glissez les plus
              pertinentes ou convaincantes dans votre paquet, pour qu'elles
              fassent partie de la solution.
              <br />
              - Lorsque vous avez fini, soumettez votre tentative pour obtenir
              votre score et des commentaires.
              <br />
              - Chaque diapositive en banque contient des données ou une note.
              Les diapositives de données appuient les notes, de sorte que votre
              paquet devrait comprendre ces deux types.
              <br />
              - Chaque diapositive a également un thème. Les diapositives de
              données et de notes qui sont reliées affichent le même thème.
              <br />
              Pour chaque diapositive, vous pouvez: <br />
              - Zoomer pour la voir en détail.
              <br />
              - La sélectionner ou la glisser pour l'ajouter à votre paquet.
              <br />
              - La rejeter pour qu'elle arrive au bas de la page, afin de vous
              concentrer sur les autres diapositives.
              <br />
            </p>

            <h5>Règles:</h5>
            <p>
              - Votre paquet a un nombre limité de places disponibles pour les
              diapositives de données et de notes. Utilisez-les à bon escient!
              <br />
              - L'ordre des diapositives de votre paquet n'a aucune importance.
              <br />
              - Vous pouvez tenir pour acquis que les diapositives de contexte
              sont justes et véridiques.
              <br />
              - Toutes les données des diapositives sont justes, mais pas
              toujours bien présentées.
              <br />
              - Les notes présentées ne sont pas forcément véridiques ou étayées
              par des données. Rejetez les moins bonnes!
              <br />
            </p>

            <h5>Indices:</h5>
            <p>
              - <FileOutlined /> Obtenez un indice sur une diapositive prise au
              hasard dans votre paquet.
              <br />
              - <DiffOutlined /> Obtenez un indice qui montre comment sont
              reliées deux cartes prises au hasard dans votre paquet.
              <br />
              - Attention! Plus vous demandez d'indices, plus ils deviennent
              flous.
              <br />
            </p>

            <h5>Le calcul du score:</h5>
            <p>Le score d'une tentative dépend:</p>
            <ol>
              <li>De la qualité de chaque diapositive</li>
              <li>
                De la manière dont vos diapositives se complètent mutuellement
              </li>
            </ol>
          </Container>
        )}
      </Modal.Body>
    </Modal>
  );
}
