import React, { useContext } from "react";
import { useLocation } from 'react-router-dom';
import { Layout, Breadcrumb } from "antd";
import MainNavigation from "../../shared/components/Navigation/MainNavigation";
import SideNavigation from "../../shared/components/Navigation/SideNavigation";
import { staticContent, language } from '../../shared/data/dictionary'

const { Content, Footer } = Layout;

import "./PanelLayout.css";

const PanelLayout = (props) => {
  
  return (
    <React.Fragment>
      <section style={{ minHeight: "100vh" }}>
        <MainNavigation role={props.role} avatar={props.avatar}/>
        <Layout className="site-layout">
          { props.role != 'player' && props.sideNavigation != false && <SideNavigation role={props.role}></SideNavigation>}
          <Layout>
            <Content style={{ paddingBottom: "35px", margin: props.noMargin ? '0 0' : '0 26px' }} >
              <Breadcrumb style={{ margin: "16px 0" }}>
                {/* <Breadcrumb.Item>{staticContent[language].panel}</Breadcrumb.Item> */}
                {/* <Breadcrumb.Item>{staticContent[language][useLocation().pathname.split('/')?[1]]}</Breadcrumb.Item> */}
              </Breadcrumb>
              
              {props.children}

            </Content>
            <Footer style={{ textAlign: "center" }}>
              Data Literacy Game
            </Footer>
          </Layout>
        </Layout>
      </section>
    </React.Fragment>
  );
};

export default PanelLayout;
