import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Button } from "antd";
import { Card } from "antd";
import { Row, Col } from "antd";
import SlideItem from "./SlideItem";
import Slide from "../../components/Slide";
import "./FeedbackItemPlayer.css";
import { staticContent } from "../../shared/data/dictionary";
import { Translate } from "../../shared/util/translator";

const language = localStorage.getItem("locale");
const ComboFeedback = (props) => {
  const comboFeedback = props.comboFeedback;

  const slide1 = comboFeedback.slide1;
  const slide2 = comboFeedback.slide2;
  const score = comboFeedback.score;

  return (
    <div className="feedback-item">
      <Row justify="center" gutter={32}>
          <Col span={14}>
            <Row>
            <Col span={12} className="d-flex justify-content-center">
              <div
                className={`item-wrapper ${slide1.type}`}
                style={{
                  width: "320px",
                  height: "460px",
                  boxSizing: "border-box",
                  display: "flex",
                }}
              >
                <div
                  className={`Item ${slide1.type}-item`}
                  style={{ borderStyle: "solid", borderWidth: "2px" }}
                >
                  <Slide
                    key={slide1.title}
                    id={slide1.id}
                    image={Translate(slide1, "image")}
                    table={Translate(slide1, "table")}
                    title={Translate(slide1, "title")}
                    text={Translate(slide1, "text")}
                    type={slide1.type}
                    feedback={Translate(slide1, "feedback")}
                    footer={false}
                  ></Slide>
                </div>
              </div>
            </Col>
            <Col span={12} className="d-flex justify-content-center">
              <div
                className={`item-wrapper ${slide2.type}`}
                style={{
                  width: "320px",
                  height: "460px",
                  boxSizing: "border-box",
                  display: "flex",
                }}
              >
                <div
                  className={`Item ${slide2.type}-item`}
                  style={{ borderStyle: "solid", borderWidth: "2px" }}
                >
                  <Slide
                    key={slide2.title}
                    id={slide2.id}
                    image={Translate(slide2, "image")}
                    table={Translate(slide2, "table")}
                    title={Translate(slide2, "title")}
                    text={Translate(slide2, "text")}
                    type={slide2.type}
                    feedback={Translate(slide2, "feedback")}
                    footer={false}
                  ></Slide>
                </div>
              </div>
            </Col>
            
            </Row>
            <Row>
              <Col span={24}>
              <div
                className={`feedback-score ${
                  score >= 0 ? "positive-score" : "negative-score"
                } margin-top-25`}
              >
                <span>
                  {score > 0 && "+"}
                  {score} points
                </span>
              </div>
            </Col>
            </Row>
          </Col>   
          <Col span={10}>
            <div className="feedback-text margin-top-25">
              <div className="title-wrapper">
                <span className="title">
                  {staticContent[language]?.feedback}
                </span>
              </div>
              <p className="text">{Translate(comboFeedback, "feedback")}</p>
            </div>
          </Col>
      </Row>
    </div>
  );
};

export default ComboFeedback;
