import React, { useContext } from 'react';
import { Link } from "react-router-dom";

import ClassItemInstructor from './ClassItemInstructor';
import { Table, Button, Space } from 'antd';

import './PlayersList.css';
import { staticContent } from '../../shared/data/dictionary';

const language = localStorage.getItem("locale");
const PlayersList = props => {

  if (props.items.length === 0) {
    return (
      <div className="center">
        <div className="">
          <h2>{staticContent[language]?.notFoundPlayers}</h2>
        </div>
      </div>
    );
  }

  const columns = [
    {
      title: staticContent[language]?.name,
      dataIndex: 'name',
      key: 'name',
      render: text => text,
    },
    {
      title: staticContent[language]?.email,
      dataIndex: 'email',
      key: 'email',
      render: text => <a>{text}</a>,
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary">
            <Link to={`/classes/${props.classId}/players/${record.id}`}>{staticContent[language]?.moreInfo}</Link>
          </Button>
        </Space>
      ),
      width: '10%',
    },
  ];

  const data = props.items.map(item=>{
    return { ...item, key: item.id }
  })

  return (
    <div className="players-list">
      <Table columns={columns} dataSource={data} />
    </div>
  );
};

export default PlayersList;
