const getSlides = (items) => {


  // parse slides as json
  let bankSlides = items.bankContainer.map((item) => {
    return JSON.parse(item);
  });
  let deckSlides = items.selectedContainer.map((item) => {
    return JSON.parse(item);
  });

  // check if slide is in deck or bank
  bankSlides = bankSlides.map((slide) => {
    return { ...slide, inDeck: false };
  });

  deckSlides = deckSlides.map((slide) => {
    return { ...slide, inDeck: true };
  });

  // remove separator slides
  const separators_ids = [
    "background-separator",
    "data-separator",
    "insight-separator",
  ];

  // concat bank and deck slides
  let slides = [];
  bankSlides.forEach((slide, index) => {
    const cleanSlide = { ...slide, slide_id: slide.id };
    slides.push(cleanSlide);
  });
  deckSlides.forEach((slide, index) => {
    const cleanSlide = { ...slide, slide_id: slide.id };
    if (!separators_ids.includes(cleanSlide.slide_id)) {
      slides.push(cleanSlide);
    }
  });

  // only select required properties
  const requireProperties = [
    "slide_id",
    "rejected",
    "color",
    "inDeck",
    "color",
    "rejected",
  ];
  for (const index in slides) {
    for (const property in slides[index]) {
      if (!requireProperties.includes(property)) {
        delete slides[index][property];
      }
    }
  }

  return slides;
};

export const saveSlides = async (items, scenarioId, classId, auth, localStorage=false) => {
  const baseUrl = process.env.REACT_APP_API_URL;
  const slides = await getSlides(items);

  try {
    const response = await fetch(
      `${baseUrl}/scenarios/${scenarioId}/${classId}/play/save`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({ slides }),
      }
    );

    const responseData = await response.json();
    return responseData;

  } catch (error) {
    console.log(error);
    return error;
  }
};


export const getScoreRange = async (items, scenarioId, classId, auth) => {
  const baseUrl = process.env.REACT_APP_API_URL;
  const slides = getSlides(items);

  try {
    const response = await fetch(
      `${baseUrl}/scenarios/${scenarioId}/${classId}/scorerange`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({ slides }),
      }
    );

    const responseData = await response.json();
    return responseData;

  } catch (error) {
    console.log(error);
    return error;
  }
};


export const getFeedbackHints = async (items, scenarioId, classId, auth, feedbackType = null) => {
  let slides = getSlides(items);
  const baseUrl = process.env.REACT_APP_API_URL;
  const url = `${baseUrl}/scenarios/${scenarioId}/${classId}/feedback/${feedbackType}`;

  try {
    const response = await fetch(
      url,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({ slides }),
      }
    );


    const responseData = await response.json();

    return responseData;

  } catch (error) {
    console.log(error);
    return error;
  }
};
