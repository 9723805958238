import React from "react";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../shared/context/auth-context";
import { useHttpClient } from "../shared/hooks/http-hook";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";
import Slide from "./Slide";
import { staticContent } from "../shared/data/dictionary";
import { Translate } from "../shared/util/translator";
import { getFeedbackHints, saveSlides } from './saveSlides';

const language = localStorage.getItem("locale");
export function FeedbackDialog({
  open,
  feedbackType,
  scenarioId,
  classId,
  handleClose
}) {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const baseUrl = process.env.REACT_APP_API_URL;
  const [modalData, setModalData] = useState(null);
  const items = useSelector((state) => state.scenario.slides);


  useEffect(() => {

    if (open) {
      const sendFeedbackRequest = async () => {
        const response = await getFeedbackHints(items, scenarioId, classId, auth, feedbackType=feedbackType);
        setModalData(response);
      }
      sendFeedbackRequest();

    } else {
      setModalData(null);
    }
  }, [open]);

  let slides = [];
  for (let k = 0; k < modalData?.slides?.length; k++) {
    slides.push(
      <Col sm={5}>
        <div
          className={`item-wrapper ${modalData?.slides[k]?.type}`}
          style={{ width: "320px", height: "460px", boxSizing: "border-box", display: "flex"}}
        >
          <div className={`Item ${modalData?.slides[k]?.type}-item`} style={{ borderStyle: "solid", borderWidth: "2px"}}>
            <Slide
              key={modalData?.slides[k]?.id}
              image={Translate(modalData?.slides[k], "image")}
              table={Translate(modalData?.slides[k], "table")}
              title={Translate(modalData?.slides[k], "title")}
              text={
                Translate(modalData?.slides[k], "text")
                  ? Translate(modalData?.slides[k], "text")
                  : ""
              }
              type={
                modalData?.slides[k]?.type ? modalData?.slides[k]?.type : ""
              }
              rejected={modalData?.slides[k]?.rejected}
              theme={Translate(modalData?.slides[k], "theme")}
              id={modalData?.slides[k]?.id}
              tags={false}
            ></Slide>
          </div>
        </div>
      </Col>
    );
  }
  return (
    modalData && (
      <Modal show={open} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            { feedbackType == 'individual' ? staticContent[language].individualFeedback : staticContent[language].comboFeedback }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='feedback-dialog-message'>
            <Row><Col>{Translate(modalData, "message")}</Col></Row>
          </div>
          <div>
            <Row justify="center" className="justify-center">{slides}</Row>
          </div>
        </Modal.Body>
      </Modal>
    )
  );
}
